import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { catchError, filter, map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth/auth.service';
import { Router } from "@angular/router";
import { LoginDialogComponent } from './login-dialog/login-dialog.component';

@Injectable()
export class CommonService {

  // userRoleList: any;
  // defaultRole: any;
  projectName: string = '';
  ProjectLSData: any = {};

  private currentRoleSource = new BehaviorSubject('');
  currentRole = this.currentRoleSource.asObservable();

  private viewerSource = new BehaviorSubject(0);
  viewerInfocus = this.viewerSource.asObservable();

  private closeViewerArrSource = new BehaviorSubject([]);
  closeViewerArr = this.closeViewerArrSource.asObservable();

  private addThreeJsAnnotationSrc = new BehaviorSubject({});
  addThreeJsAnnotation = this.addThreeJsAnnotationSrc.asObservable();

  private currentPlaceSource = new BehaviorSubject('');
  currentPlace = this.currentPlaceSource.asObservable();

  private ViewerOpenSource = new BehaviorSubject('');
  ViewerOpen = this.ViewerOpenSource.asObservable();

  private uploadListSource = new BehaviorSubject([]);
  uploadList = this.uploadListSource.asObservable();

  private uploadProgressSource = new BehaviorSubject([]);
  uploadProgress = this.uploadProgressSource.asObservable();

  private uploadListProgressSource = new BehaviorSubject(0);
  uploadListProgress = this.uploadListProgressSource.asObservable();

  private synchronizeImageSource = new BehaviorSubject(false);
  synchronizeImage = this.synchronizeImageSource.asObservable();

  private annotationObjSource = new BehaviorSubject({});
  annotationObj = this.annotationObjSource.asObservable();

  viewerOpenedFrom: any;
  uploadImages = [];
  bytesPerChunk = 2097152;
  start = 0;
  end = this.bytesPerChunk;
  chunkNo = 1;
  file = '';
  NumOfChunks = 0;
  currentFile: any;
  currentFileCount = 0;
  uniqueId = '';
  totalFilesCount = 0;
  messageDuration = 3000;

  files = [];
  filesCount = 0;
  selectedFileCount = 0;
  uploadedFileCount = 0;
  uploadFileObservable: any;
  sessionPingTimer: any;
  isLicensingModuleEnabled: boolean = false;
  pingInterval: number = 0;
  isLoginPopupOpen: boolean = false;

  private config: any;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private configService: AppConfigService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.config = this.configService.config;

    this.projectName = this.config?.projectName;
    this.authService.ProjectLSData = this.ProjectLSData;
    this.fetchProjectLSData();
    /* this.userRoleList = this.ProjectLSData.userRoleList;
    this.defaultRole = this.userRoleList ? this.userRoleList.length ? this.userRoleList[0] : '' : '';
    this.changeRoleId(this.defaultRole.role); */
    this.authService.setProjectLSData = this.setProjectLSData;
  }

  fetchProjectLSData() {
    if (localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
  }

  changeOfRoutes() {
    if (location.pathname.indexOf('WebViewer') < 0) {
      if (this.ProjectLSData.currentRoute) {
        this.router.navigate([this.ProjectLSData.currentRoute]);
      } else {
        this.ProjectLSData.currentRoute = location.pathname.replace(this.config.replacePathName, "");
        this.setProjectLSData();
      }
    }
  }

  getSessionConfigData() {
    return this.http.get(`${this.config.apiUrl}user/session/config`);
  }

  checkSessionActive(timeInterval) {
    this.sessionCheckScheduler();
    this.sessionPingTimer = setInterval(() => {
      this.sessionCheckScheduler();
    }, (timeInterval * 1000));
  }

  sessionCheckScheduler() {
    if (this.authService.getAccessToken()) {
      this.checkSessionActiveStatus();
    }
  }

  checkSessionActiveStatus() {
    this.http.post(`${this.config.apiUrl}user/session/ping`, { sessionId: this.authService.getSessionId() }).subscribe((res: any) => {
      if (!res && !this.isLoginPopupOpen) {
        this.isLoginPopupOpen = true;
        this.stopSessionActiveStatus();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "20%";
        dialogConfig.data = {
          headerTitle: "Login",
          confirmMsg: "Session expired. Login again to continue",
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes"
        };
        const dialogref = this.dialog.open(LoginDialogComponent, dialogConfig);
        return dialogref.afterClosed().subscribe((result: any) => {
          this.isLoginPopupOpen = false;
          if (result) {
            if (this.isLicensingModuleEnabled && this.ProjectLSData.chooseSession) {
              this.authService.activateSession().subscribe((res: any) => {
                this.checkSessionActive(this.pingInterval);
              });
            }
          } else {
            this.authService.doLogoutUser();
            let meetingParam = sessionStorage.getItem('meetingLoginParam');
            if (meetingParam && meetingParam != "null") {
              this.router.navigate(['/login'], { queryParams: { id: meetingParam } });
            } else {
              this.router.navigate(['/login']);
            }
          }
        });
      }
    }, (err: any) => {

    })
  }

  stopSessionActiveStatus() {
    clearInterval(this.sessionPingTimer);
  }

  changeRoleId(role: any) {
    this.ProjectLSData.userActiveRole = role;
    this.setProjectLSData();
    this.currentRoleSource.next(role);
  }

  focusChanged(index) {
    this.viewerSource.next(index);
  }

  closeViewerArrChanged(arr) {
    this.closeViewerArrSource.next(arr);
  }

  threeJsNewAnnotationAdded(value) {
    this.addThreeJsAnnotationSrc.next(value);
  }

  changePlace(place) {
    this.ProjectLSData.userActivePlace = place;
    this.setProjectLSData();
    this.currentPlaceSource.next(place);
  }

  setViewerOpenedFrom(planceName) {
    this.ProjectLSData.viewerOpenedFrom = planceName;
    this.setProjectLSData();
    this.ViewerOpenSource.next(planceName);
  }

  getViewerOpenedFrom() {
    return this.ProjectLSData.viewerOpenedFrom;
  }

  resetGalleryFilters() {
    let galleryFilter = {
      "selectedOpt": "study",
      "studyName": "",
      "isTCRStudy": false,
      "viewByG": "5",
      "viewByS": "folderLocation",
      "viewByU": "week",
      "stains": [],
      "organ": [],
      "animalNo": [],
      "control": [],
      "viewType": "list",
      "readReviewFlag": '',
      "quickFilter": "-2",
      "selectedAccordion": ''
    }
    this.ProjectLSData.galleryFilters = galleryFilter;
    this.setProjectLSData();
  }

  /* setSelectedGroupSlides(slides) {
    this.ProjectLSData.selectedGroupSlides = slides;
    this.setProjectLSData();
  } */

  /* getSelectedGroupSlides() {
    return this.ProjectLSData.selectedGroupSlides;
  } */

  setUploadList(data: any) {
    this.uploadListSource.next(data);
  }

  setUploadProgress(value: any) {
    this.uploadProgressSource.next(value);
  }

  setUploadListProgress(value: any) {
    this.uploadListProgressSource.next(value);
  }

  setSynchronization(isSync: boolean) {
    this.synchronizeImageSource.next(isSync);
  }

  setAnnoObj(annoObj: any) {
    this.annotationObjSource.next(annoObj);
  }

  uploadImage(selectedFiles, recFlag) {
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        this.uploadImages.push(selectedFiles[i]);
      }
    }
    this.chunkNo = 1;
    // console.log(this.uploadFiles);
    this.totalFilesCount = this.uploadImages.length;
    //this.uploadFiles.each(element => {
    this.uploadImages[this.currentFileCount].status = "In-Progress";
    this.setUploadList(this.uploadImages);
    this.currentFile = this.uploadImages[this.currentFileCount];
    this.NumOfChunks = Math.max(Math.ceil(this.currentFile.size / this.bytesPerChunk), 1);

    let data = {
      'imageResourceInfo': {
        'filePath': this.currentFile.name,
        'totalChunks': this.NumOfChunks,
        'chunkSize': this.bytesPerChunk,
        'studySymbolicName': 'study_1571751853192',
        'cassetteCode': 'A'
      }
    }
    //this.http.post(api + 'batch_dashboard/initiateUpload', data).subscribe((response:any) =>{
    // console.log(response);
    //this.uniqueId = response.imageResourceInfo.uniqueId;
    this.uniqueId = '1';
    this.uploadImageChunk(this.chunkNo);
    //});
    //})
  }

  uploadImageChunk(chunkNo) {
    /* console.log(chunkNo); */
    let fd = new FormData();
    let chunk = this.currentFile.slice(this.start, this.end);
    fd.append('filename', chunk);
    // let header_data = {
    //     //transformRequest: angular.identity,
    //     headers: {'Content-Type': undefined}
    // }
    //this.http.post(api +'batch_dashboard/startUpload?chunkId='+chunkNo+'&uniqueId='+this.uniqueId+'&chunkSize='+this.bytesPerChunk+'&totalChunks='+this.NumOfChunks, fd).subscribe(response =>{
    if (chunkNo == this.NumOfChunks) {
      this.uploadImages[this.currentFileCount].status = "Completed"
      this.setUploadList(this.uploadImages);
      this.currentFileCount++;
      if (this.currentFileCount < this.totalFilesCount)
        this.uploadImage([], true);
      /* else
        this.currentFileCount = 0; */
    } else {
      this.chunkNo++;
      this.start = this.end;
      this.end = this.start + this.bytesPerChunk;
      this.uploadImageChunk(this.chunkNo);
    }
    //});
  }

  showToastMsg(message, duration = 3000) {
    this.snackBar.open(message, '', {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar'
    });
    /* setTimeout((function(){
      let a = 0;
      $('.snackbar').css('margin-left','-'+($('.snackbar').width()/2)+'px !important');
    }).bind(this), 50); */
  }

  showAlertMsg(message) {
    this.snackBar.open(message, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar'
    });
  }

  vwTOpx(value) {
    value = value.replace('vw', '');
    let w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    let result = (x * value) / 100;
    return result;
  }

  sortByMultiKey(array, key) {
    return array.sort(function (a, b) {
      let x, y;
      for (let n = 0; n < key.length; n++) {
        x += a[key[n]] ? a[key[n]] : '-1';
        y += b[key[n]] ? b[key[n]] : '-1';
      }
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  uploadImageNewApi(uploadData) {
    return this.http.post(`${this.config.apiUrl}slide-image/upload`, uploadData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  checkIfUploadedFileAlreadyExists(fileName) {
    return this.http.get(`${this.config.apiUrl}slide-image/uploadChecks?names=${fileName}`);
  }

  uploadFiles(files) {
    if (files.length > 0) {
      files.forEach(file => {
        this.files.push(file);
      });
      this.filesCount = this.files.length;
      this.setUploadListProgress(this.uploadedFileCount * 100 / this.filesCount);
    }
    //this.uploadFiles.each(element => {
    if (this.selectedFileCount < this.filesCount) {
      if (this.files[this.selectedFileCount].status == "Queued") {
        this.files[this.selectedFileCount].status = "In-Progress";
        this.setUploadList(this.files);
        this.setUploadProgress(0);
        /* files.forEach(file => {
          this.uploadFile(file);
        }); */
        this.uploadFile(this.files[this.selectedFileCount]);
      } else if (this.files[this.selectedFileCount].status == "Cancelled") {
        this.selectedFileCount++;
        this.uploadedFileCount++;
        this.setUploadListProgress(this.uploadedFileCount * 100 / this.filesCount);
        this.uploadFiles([]);
      }
    }
  }

  uploadFile(file) {
    this.checkIfUploadedFileAlreadyExists(file.data.name).subscribe((res: any) => {
      if (!res[file.data.name]) {
        const formData = new FormData();
        formData.append('file', file.data);
        file.inProgress = true;
        this.uploadFileObservable = this.uploadImageNewApi(formData).pipe(
          map(event => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round(event.loaded * 100 / event.total);
                this.setUploadProgress(file.progress);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            file.inProgress = false;
            this.files[this.selectedFileCount].status = "Failed";
            return of(`${file.data.name} upload failed.`);
          })).subscribe((event: any) => {
            if (event) {
              if (event.body && event.body.success) {
                this.files[this.selectedFileCount].status = "Completed";
              } else {
                this.files[this.selectedFileCount].status = "Failed";
                this.showToastMsg(event);
              }
              this.setUploadList(this.files);
              this.selectedFileCount++;
              this.uploadedFileCount++;
              this.setUploadListProgress(this.uploadedFileCount * 100 / this.filesCount);
              this.uploadFiles([]);
              //this.uploadFileObservable = null;
            }
          });
      } else {
        this.files[this.selectedFileCount].status = "Duplicate";
        this.setUploadList(this.files);
        this.selectedFileCount++;
        this.uploadedFileCount++;
        this.setUploadListProgress(this.uploadedFileCount * 100 / this.filesCount);
        this.uploadFiles([]);
      }
    });
  }

  unSubScribeUpload() {
    if (this.uploadFileObservable) {
      this.uploadFileObservable.unsubscribe();
    }
    this.files = [];
    this.setUploadList(this.files);
    this.setUploadListProgress(0);
    this.selectedFileCount = 0;
    this.uploadedFileCount = 0;
  }

  checkFileProgress(callback) {
    let progress = false;
    this.files.forEach(file => {
      if (file.status == 'In-Progress' || file.status == 'Queued') {
        progress = true;
        return false;
      }
    });
    /* let msg = '';
  
    if (progress) {
      msg = this.translate.instant("GENERIC_MSG.COMMON.LOGOUT_DURING_UPLOADING_FILE");
    } else {
      msg = this.translate.instant("GENERIC_MSG.COMMON.LOGOUT_CONFIRMATION");
    } */
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Logout",
      confirmMsg: this.translate.instant(progress ? "GENERIC_MSG.COMMON.LOGOUT_DURING_UPLOADING_FILE" : "GENERIC_MSG.COMMON.LOGOUT_CONFIRMATION"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && (result != 'btnClose')) {
        this.unSubScribeUpload();
        if (callback) callback();
      }
    });
  }

  cancelFileUpload(uploadData, index) {
    if (this.files[index].status == 'In-Progress') {
      if (this.uploadFileObservable) {
        this.uploadFileObservable.unsubscribe();
      }
      this.files[index].status = "Cancelled"
      this.setUploadList(this.files);
      this.selectedFileCount++;
      this.uploadedFileCount++;
      this.setUploadListProgress(this.uploadedFileCount * 100 / this.filesCount);
      this.uploadFiles([]);
    } else {
      this.files[index].status = "Cancelled"
      this.setUploadList(this.files);
    }
  }

  uploadMappingExcel(file: any) {
    return this.http.post(`${this.config.apiUrl}gallery/mapping/excel`, file);
  }

  camelCase(str: String) {
    let newStr = str.replace(/[^A-Z0-9]+/gi, " ");
    let splitStr = newStr.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  localToServerTime(now) {
    if (now) {
      return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())
    } else {
      return now;
    }
  }

  ServerToLocalTime(d) {
    if (d) {
      return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()));
    } else {
      return d;
    }
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  getLicensingModuleFlag() {
    return this.isLicensingModuleEnabled;
  }

  setLicensingModuleFlag(flagVal) {
    this.isLicensingModuleEnabled = flagVal;
  }

  getPingInterval() {
    return this.pingInterval;
  }

  setPingInterval(intervalVal) {
    this.pingInterval = intervalVal;
  }

  checkEmailPattern(control: FormControl) {
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(control.value?.toLowerCase())) {
      return { email: true };
    }
    return null;
  }

  setProjectLSData() {
    localStorage[this.projectName] = JSON.stringify(this.ProjectLSData);
    if (this.authService) {
      this.authService.ProjectLSData = this.ProjectLSData;
    }
  }

  checkBrowserZoom() {
    let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    let isBrowserZoomed: boolean = false;
    if ((zoom < 95) || (zoom > 105)) {
      isBrowserZoomed = true;
    }
    return isBrowserZoomed;
  }
}