<div class="dialog-header modal-header" *ngIf="data && data.showHeader">
  <div class="headerTitle">
    <div class="dialog-title">Gallery</div>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="page-content">
  <app-galleryheader [activeMode]="activeMode" [dialogData]="data"></app-galleryheader>
  <div [ngClass]="{'row' : preview}" id="preview_show">
    <app-gallerylist [activeMode]="activeMode" [dialogData]="data" *ngIf="gridList == 'list'" id="gallerylist"
      [ngClass]="{'col-sm-9' : preview}"></app-gallerylist>
    <app-gallerygrid [activeMode]="activeMode" [dialogData]="data" *ngIf="gridList == 'grid'" id="gallerygrid"
      [ngClass]="{'col-sm-9' : preview}"></app-gallerygrid>
    <app-gallerypreview [hidden]="!preview" [activeMode]="activeMode" [dialogData]="data" id="gallerypreview"
      class="col-sm-3" style="padding-left: 0px !important"></app-gallerypreview>
  </div>
</div>
