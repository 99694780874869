import { AppConfigService } from './../app-config.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { group } from 'console';

@Injectable()
export class GalleryService {
  groupBy: any = 0;
  gridListP: string = 'list';
  previewP: boolean = false;
  showLoaderP: boolean = true;

  private gridListSource = new BehaviorSubject(this.gridListP);
  gridList = this.gridListSource.asObservable();

  private previewSource = new BehaviorSubject(this.previewP);
  preview = this.previewSource.asObservable();

  private galleryListSource = new BehaviorSubject([]);
  galleryList = this.galleryListSource.asObservable();

  private selectgroupSlideKeySource = new BehaviorSubject('0_0');
  selectgroupSlideKey = this.selectgroupSlideKeySource.asObservable();

  private studyListSTSource = new BehaviorSubject([]);
  studyListST = this.studyListSTSource.asObservable();

  /* private selecteSlidesListSource = new BehaviorSubject({});
  selecteSlidesList = this.selecteSlidesListSource.asObservable(); */

  /* private selecteSlidesListThumbnailSource = new BehaviorSubject({});
  selecteSlidesListForThumbnail = this.selecteSlidesListThumbnailSource.asObservable(); */

  /* private showLoaderSource = new BehaviorSubject(this.showLoaderP);
  showLoader = this.showLoaderSource.asObservable(); */

  showLoader: boolean = false;
  isCalledFromViewer: boolean = false;
  private refreshSlideListSource = new BehaviorSubject(false);
  refreshSlideList = this.refreshSlideListSource.asObservable();

  private viewerSearchSource = new BehaviorSubject("");
  viewerSearch = this.viewerSearchSource.asObservable();

  private unMapFlagSource = new BehaviorSubject("");
  unMapFlag = this.unMapFlagSource.asObservable();

  private updateslideDataSource = new BehaviorSubject("");
  updateslideData = this.updateslideDataSource.asObservable();

  private loadeDataArrLengthSource = new BehaviorSubject(0);
  loadeDataArrLength = this.loadeDataArrLengthSource.asObservable();

  private studyOrganObjSource = new BehaviorSubject({});
  studyOrganObj = this.studyOrganObjSource.asObservable();

  addImageObj = {};
  activeAccordian: any = 0;
  showOnlyMappedSlides: number = 1;
  paginationSearchObj = {};
  selectedSlidesArr: any = [];
  selectedSlidesForThumbnailArr: any = [];
  isStudyRandomised: boolean = false;
  galleryViewByObj: any = {
    0: "Cassette",
    1: "Dosage",
    2: "Sex",
    3: "Tissue",
    4: "",
    5: "Subject Id",
    6: "Target Antigen"
  };
  randomisedStudies: any = [];
  allRandomisedResp: any = [];
  randomisedSelection: any = [];
  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
    this.showOnlyMappedSlides = this.config?.showOnlyMappedSlides !== undefined ? this.config?.showOnlyMappedSlides : 1;
  }

  switchGridList(param: any) {
    this.gridListSource.next(param);
  }

  changePreview(param: any) {
    this.previewSource.next(param);
  }

  /* changeshowLoader(param: any) {
    this.showLoaderSource.next(param);
  } */

  getMyStudiesP() {
    return this.http.get(`${this.config.apiUrl}study/all?role=PATHOLOGIST`);
  }

  getMyStudiesT(activeMode: any) {
    if (activeMode == 'uploaded') {
      return this.http.get(`${this.config.apiUrl}study/uploadStudylist?role=TECHNICIAN&source=IMAGE_UPLOAD`);
    } else {
      return this.http.get(`${this.config.apiUrl}study/all?role=TECHNICIAN`);
    }
  }

  getPeerStudies() {
    return this.http.get(`${this.config.apiUrl}study/all?role=PEER_REVIEWER`);
  }

  getMyProjects() {
    return this.http.get(`${this.config.apiUrl}study/?query=peerreviewer`);
  }

  getSlidesList(selectedStudyProj) {
    return this.http.get(`${this.config.apiUrl}study/studySlideGallery/list/no-group-filter?study=${selectedStudyProj}`);
  }

  getSlideAccordionData(selectedStudyProj, orderby, searchData) {
    return this.http.post(`${this.config.apiUrl}study/page/v2/gallery/metadata?study=${selectedStudyProj}&groupBy=${orderby}&desc=false`, searchData);
  }

  getFilteredSlideData(selectedStudyProj, searchData, orderby, pageNo) {
    return this.http.post(`${this.config.apiUrl}study/page/v2/gallery/paginate-list?study=${selectedStudyProj}&groupBy=${orderby}&desc=false&pageNo=${pageNo}`, searchData);
  }

  getSlidesIdentifierInfo(selectedStudyProj, orderBy, searchData) {
    return this.http.post(`${this.config.apiUrl}study/page/v2/gallery/slides-identifier-info?study=${selectedStudyProj}&groupBy=${orderBy}`, searchData);
  }

  getSelectedSlideDataForPreview(slidePk) {
    return this.http.get(`${this.config.apiUrl}study/page/v2/gallery/slide-detail?slideId=${slidePk}`);
  }

  getSlidesListST(group) {
    return this.http.get(`${this.config.apiUrl}slide-image/get/SCANNED?groupBy=` + group);
  }

  getSlidesListSTU() {
    return this.http.get(`${this.config.apiUrl}slide-image/uploaded`);
  }

  setSlidesList(data) {
    this.galleryListSource.next(data);
  }

  changePreviewSlide(param: any) {
    this.selectgroupSlideKeySource.next(param);
  }

  setStudyListST(param: any) {
    this.studyListSTSource.next(param);
  }

  setunMapFLag(param: any) {
    this.unMapFlagSource.next(param);
  }

  setupdateslideData(param: any) {
    this.updateslideDataSource.next(param);
  }

  getCassetteData(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}/cassettes`);
  }

  getUnmappedAnimalData(studyGuid, stainid) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}/cassette-stain/${stainid}/animals/unmapped`);
  }

  /* setSelecteSlidesList(param: any) {
    this.selecteSlidesListSource.next(param);
  } */

  /* setSelectedSlidesListForThumbnail(param: any) {
    this.selecteSlidesListThumbnailSource.next(param);
  } */

  mapImageToStudy(mapObj) {
    return this.http.post(`${this.config.apiUrl}gallery/mapping/map`, mapObj);
  }

  getFilterWiseListing() {
    this.refreshSlideListSource.next(true);
  }

  setViewerSearch(val) {
    this.viewerSearchSource.next(val);
  }

  getStudyStainList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/stains`);
  }

  getstudyOrganList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/organs`);
  }

  getstudyAnimalList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/animals/mapped`);
  }

  getstudyDossageList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/dosages`);
  }

  getstudySexList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/sex`);
  }

  getstudyCassetteList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/cassettes`);
  }

  discardImage(discardObj) {
    return this.http.post(`${this.config.apiUrl}slide-image/bulk/discard`, discardObj);
  }

  getSlideReview(organ, slideId, role) {
    return this.http.get(`${this.config.apiUrl}slide-image/${slideId}/organ-review/get?organ=${organ}&role=${role}`);
  }

  unMapImage(unMapArr) {
    return this.http.post(`${this.config.apiUrl}gallery/mapping/unmap`, unMapArr);
  }

  bulkUnmapImages(unMapArr) {
    return this.http.post(`${this.config.apiUrl}gallery/mapping/bulk/unmap`, unMapArr);
  }

  getRecentlyMappedImages() {
    return this.http.get(`${this.config.apiUrl}gallery/mapping/recent`);
  }

  setSelectedAddImage(obj) {
    this.addImageObj = Object.assign({}, obj);
  }

  getSelectedAddImage() {
    return this.addImageObj;
  }

  changeReadStatus(dataObj) {
    return this.http.post(`${this.config.apiUrl}slide-image/markAndreview/readStatus`, dataObj);
  }

  changeReviewStatus(dataObj) {
    return this.http.post(`${this.config.apiUrl}slide-image/markAndreview/reviewStatus`, dataObj);
  }

  changeOpenStatus(slideId, dataObj) {
    return this.http.put(`${this.config.apiUrl}slide-image/update/${slideId}`, dataObj);
  }

  getStudyDosageList(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/` + studyGuid + `/dosages`);
  }

  getFolderLocation() {
    return this.http.get(`${this.config.apiUrl}slide-image/accessibleImageFolders`);
  }

  getSlidesListFolderLocation(id) {
    return this.http.get(`${this.config.apiUrl}slide-image/accessibleImageFolders/${id}`);
  }

  checkUnmapFlag(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/slide-image/canPerformUnnmap?study=${studyGuid}`);
  }

  generateThumbnail(slideObj) {
    return this.http.post(`${this.config.apiUrl}tiles/saveMetaImages`, slideObj);
    //return this.http.post(`http://172.28.42.132:9070/airadhi/tiles/saveMetaImages`, slideObj);
  }

  getAllIntensities() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/intensity`);
  }

  getAllFrequencies() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/frequency`);
  }

  getSlidesPhysicalLocation(slidesArr) {
    return this.http.post(`${this.config.apiUrl}slide-image/physical-location`, slidesArr);
  }

  getListOfAllSlideIdsInAccordion(studyGuid, groupBy, searchBy) {
    return this.http.post(`${this.config.apiUrl}study/page/v2/gallery/select-by?study=${studyGuid}&groupBy=${groupBy}`, searchBy);
  }

  updateSlideReview(slideReviewData: any, organReviewPk: number, slideId) {
    return this.http.put(`${this.config.apiUrl}slide-image/${slideId}/organ-review/${organReviewPk}`, slideReviewData)
  }

  saveSlideReview(slideReviewData: any, slideId, reviewSharable) {
    return this.http.post(`${this.config.apiUrl}slide-image/${slideId}/organ-review`, slideReviewData)
  }

  getSlideDetails(slideId: any) {
    return this.http.get(`${this.config.apiUrl}gallery/slide-image/` + slideId);
  }

  getSlideDetailsArr(slideIds: any) {
    let slideObj = { "slides": slideIds };
    return this.http.post(`${this.config.apiUrl}gallery/slide-image`, slideObj);
  }

  setRandomizedScore(payload: any) {
    return this.http.post(`${this.config.apiUrl}slide-image/randomisation`, payload);
  }

  deleteRanomizationScore(studyId: any) {
    return this.http.delete(`${this.config.apiUrl}slide-image/randomisation?studyId=${studyId}`);
  }

  getGroupByString(groupBy) {
    let orderby;
    switch (groupBy) {
      case '0':
        orderby = 'cassette';
        break;
      case '1':
        orderby = 'dosage';
        break;
      case '2':
        orderby = 'sex';
        break;
      case '3':
        orderby = 'tissue';
        break;
      case '5':
        orderby = 'subjectId';
        break;
      case '6':
        orderby = 'targetAntigen';
        break;
    }
    return orderby;
  }

  getReviewFilterString(reviewData) {
    let reviewFilter;
    switch (reviewData) {
      case '':
        reviewFilter = 'SHOW_ALL';
        break;
      case 'READ':
        reviewFilter = 'READ';
        break;
      case 'UNREAD':
        reviewFilter = 'UNREAD';
        break;
      case 'LATER':
        reviewFilter = 'LATER';
        break;
      case 'DONE':
        reviewFilter = 'DONE';
        break;
    }
    return reviewFilter;
  }

  setGalleryDataArrlength(value) {
    this.loadeDataArrLengthSource.next(value);
  }

  setSearchData(searchObj) {
    this.paginationSearchObj = searchObj;
  }

  getSearchData() {
    return this.paginationSearchObj;
  }

  setStudyOrganObj(value) {
    this.studyOrganObjSource.next(value)
  }

  assignGroupCount(slidesArr: any = [], activeMode: any = 'gallery', galleryFilter: any = {}, galleryView: any = {}, removeItem: boolean = false) {
    // if (activeMode == 'gallery') {
    if (this.isStudyRandomised) {
      return;
    }
    for (let a = 0; a < slidesArr.length; a++) {
      let indvSelectedSlide = slidesArr[a];
      let groupInfo = [];
      if (galleryFilter.viewByG == '0') {
        groupInfo = [indvSelectedSlide.cassetteCode]
      } else if (galleryFilter.viewByG == '1') {
        groupInfo = [indvSelectedSlide.dosageLevel]
      } else if (galleryFilter.viewByG == '2') {
        groupInfo = [indvSelectedSlide.sex]
      } else if (galleryFilter.viewByG == '3') {
        groupInfo = indvSelectedSlide.organ
      } else if (galleryFilter.viewByG == '5') {
        groupInfo = [indvSelectedSlide.animalNo]
      } else if (galleryFilter.viewByG == '6') {
        groupInfo = [indvSelectedSlide.targetAntigen]
      }
      for (let b = 0; b < groupInfo.length; b++) {
        if (!galleryView[groupInfo[b]]?.selectedSlidesIds) {
          galleryView[groupInfo[b]]['selectedSlidesIds'] = [];
        }
        let index = galleryView[groupInfo[b]].selectedSlidesIds.indexOf(indvSelectedSlide.slidePk);
        if (removeItem) {
          if (index >= 0) {
            galleryView[groupInfo[b]].selectedSlidesIds.splice(index, 1);
          }
        } else {
          if (index < 0) {
            galleryView[groupInfo[b]].selectedSlidesIds.push(indvSelectedSlide.slidePk);
          }
        }
      }
    }
    // }
  }

  isItemSelected(animal: any) {
    let filteredSlideArr: any = this.selectedSlidesArr.filter(e => e.slidePk == animal.slidePk);
    return (filteredSlideArr.length > 0);
  }

}