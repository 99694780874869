import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordService } from '../change-password/change-password.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '../../../node_modules/@angular/router';
import { CommonService } from '../common.service';
import { MustMatch, LengthValidator, CharacterValidator } from '../Input.Validators'
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  oHide: boolean = true;
  nHide: boolean = true;
  cnHide: boolean = true;
  passChngSucs: boolean = false;
  msg: any = '';
  beError: any = '';
  isFirstLogin: boolean = false;
  showChangepasswordLoader: boolean = false;

  changePwdform: FormGroup;

  constructor(@Optional() private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public appConfig: AppConfigService,
    fb: FormBuilder, public translate: TranslateService, private changePasswordService: ChangePasswordService,
    private commonService: CommonService,
    private authService: AuthService, private router: Router) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;
    this.changePwdform = fb.group({
      'oldPwd': ['', Validators.required],
      'newPwd': ['', [Validators.required, LengthValidator, CharacterValidator]],
      'confirmPwd': ['', [Validators.required]]
    }, {
      validator: MustMatch('newPwd', 'confirmPwd')
    });
  }

  ngOnInit(): void {
    this.isFirstLogin = this.commonService.ProjectLSData?.isfirstLogin;
  }
  closeDialog() {
    if (this.isFirstLogin) {
      this.authService.logout().subscribe((response) => {
        this.router.navigate(['/login']);
      });
    } else {
      this.dialogRef.close();
    }
  }
  get formControls() { return this.changePwdform.controls; }

  // lengthValidator(control: FormControl) {
  //   const lengthSeven = (control.value).trim().length < 7;
  //   const isValid = !lengthSeven;
  //   return isValid ? null : { 'lengthSeven': true };
  // }

  // characterValidator(control: FormControl) {
  //   let hasNumber = /\d/.test(control.value);
  //   let hasUpper = /[A-Z]/.test(control.value);
  //   //let hasLower = /[a-z]/.test(control.value);
  //   let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);
  //   // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
  //   const isValid = hasNumber && hasUpper /* && hasLower */ && hasSpecialCharacter;
  //   return isValid ? null : { 'character': true };
  // }

  // mustMatch(controlName: string, matchingControlName: string) {
  //   return (formGroup: FormGroup) => {
  //     const control = formGroup.controls[controlName];
  //     const matchingControl = formGroup.controls[matchingControlName];

  //     if (matchingControl.errors && !matchingControl.errors.mustMatch) {
  //       // return if another validator has already found an error on the matchingControl
  //       return;
  //     }

  //     // set error on matchingControl if validation fails
  //     if (control.value !== matchingControl.value) {
  //       matchingControl.setErrors({ mustMatch: true });
  //     } else {
  //       matchingControl.setErrors(null);
  //     }
  //   }
  // }

  changePaswrd() {
    this.msg = '';
    this.beError = '';
    if (this.changePwdform.valid) {
      this.showChangepasswordLoader = true;
      let data = {
        "oldPassword": this.changePwdform.value.oldPwd.trim(),
        "newPassword": this.changePwdform.value.newPwd.trim()
      }
      this.changePasswordService.changePassword(data).subscribe((response: any) => {
        if (response.success) {
          this.authService.logoutAPICall().subscribe((response) => {
            this.authService.clearLocalStorage();
            this.authService.removeTokens();
            this.passChngSucs = true;
            this.msg = 'Password changed successfully. Please login again';
          });
        } else {
          this.passChngSucs = false;
          this.showChangepasswordLoader = false
          //this.beError = response.message;
          this.commonService.showToastMsg(response.message);
        }
      }, (err: any) => {
        this.showChangepasswordLoader = false;
      });
    } else {
      //  handled error through formbulider
    }
  }

  redirectToLogin() {
    this.closeDialog();
    this.router.navigate(['/login']);
  }
}
