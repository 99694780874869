<div *ngIf="noOfGroups" class="gridscroll">
    <!-- <div class="row togglepreivew_icon">
        <div class="labelicon labeltoggle" (click)="slideLabelView = !slideLabelView">
            <img *ngIf="!slideLabelView"
                [src]="'../../../' + config.appBaseName + 'assets/images/viewer_icon/Slide Label Icon-Active.svg'"
                title="Label View" class="labelimg" />
            <img *ngIf="slideLabelView"
                [src]="'../../../' + config.appBaseName + 'assets/images/viewer_icon/Image Preview Icon-Active.svg'"
                title="Slide View" class="reviewimg" />
        </div>
        <div class="closeicon" (click)="closePreview()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </div>
    <div class="previewslider">
        <div class="slide_img" (click)="openViewer(selectedSlideForPreview.slidePk)">
            <img *ngIf="slideLabelView && selectedSlideForPreview.identifier && !labelPreview"
                [ngStyle]="{'transform': 'rotate('+config.labelViewRotateDegree+'deg)'}"
                [src]="thumbnailUrl+selectedSlideForPreview.identifier+'_label.jpeg'" style="border: 0;"
                class="img-responsive img_large" aria-label="tissuename">
            <img *ngIf="slideLabelView && selectedSlideForPreview.identifier && labelPreview"
                [src]="'../../../' + config.appBaseName + 'assets/images/defaultLable_qr.png'" style="border: 0;"
                class="img-responsive img_large" aria-label="tissuename">
            <img *ngIf="!slideLabelView && selectedSlideForPreview.identifier"
                [src]="thumbnailUrl+selectedSlideForPreview.identifier+'_macro.jpeg'" style="border: 0;"
                class="img-responsive img_large" aria-label="tissuename">
        </div>
    </div> -->

    <div class="slidePreviewTopDiv">
        <div class="labelicon labeltoggle" (click)="slideLabelView = !slideLabelView">
            <img *ngIf="!slideLabelView"
                [src]="'../../../' + config.appBaseName + 'assets/images/viewer_icon/Slide Label Icon-Active.svg'"
                title="Label View" class="labelimg" />
            <img *ngIf="slideLabelView"
                [src]="'../../../' + config.appBaseName + 'assets/images/viewer_icon/Image Preview Icon-Active.svg'"
                title="Slide View" class="reviewimg" />
        </div>
        <div class="previewslider">
            <div class="slide_img" (click)="openViewer(selectedSlideForPreview.slidePk)">
                <!-- [src]="thumbnailUrl+selectedSlideForPreview.identifier+'_label.jpeg'"  -->
                <img *ngIf="slideLabelView && selectedSlideForPreview.identifier && !labelPreview"
                    [defaultImage]="defaultLabelImagePath"
                    [ngStyle]="{'transform':  (!defaultLabelImagePath) ? 'rotate('+config.labelViewRotateDegree+'deg)' : ''}"
                    style="border: 0;" [lazyLoad]="thumbnailUrl+selectedSlideForPreview.identifier+'_label.jpeg'"
                    class="img-responsive img_large" aria-label="tissuename">
                <img *ngIf="slideLabelView && selectedSlideForPreview.identifier && labelPreview"
                    [src]="'../../../' + config.appBaseName + 'assets/images/defaultLable_qr.png'" style="border: 0;"
                    class="img-responsive img_large" aria-label="tissuename">
                <!-- [src]="thumbnailUrl+selectedSlideForPreview.identifier+'_macro.jpeg'" -->
                <img *ngIf="!slideLabelView && selectedSlideForPreview.identifier" style="border: 0;"
                    [defaultImage]="defaultMacroImagePath"
                    [lazyLoad]="thumbnailUrl+selectedSlideForPreview.identifier+'_macro.jpeg'"
                    class="img-responsive img_large" aria-label="tissuename">
            </div>
        </div>
        <div class="closeicon" (click)="closePreview()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </div>
    <!--Buttons-->
    <div class="previewaction_btn clearfix">
        <!-- <div class="leftfloat leftBtn">
            <button type="button" class="btn-default searchbtn" data-dismiss="modal"
                *ngIf="activeMode!='consultation' && activeMode != 'consultation-viewer'"
                [ngClass]="{ 'disablebtn_func' : (dialogData?.selectedImage == selectedSlideForPreview.slidePk && activeMode == 'image-viewer' && (dialogData?.dialogType == 'filter' || dialogData?.dialogType == 'compare'))}"
                (click)="openViewer(selectedSlideForPreview.slidePk)">Open Image</button>
            <button type="button" class="btn-default searchbtn" data-dismiss="modal"
                *ngIf="activeMode=='consultation'|| activeMode == 'consultation-viewer'"
                (click)="openImageDialog()">Open Image</button>
        </div> -->
        <div class="previewimgdet">
            <div class="prev" (click)="prev_slide()" [ngClass]="{'next-prev-dis' : prevBtnStatus}">
                <i class="fa fa-chevron-left"></i>
            </div>
            <div class="prevTitleDiv">
                <span class="previewimg-title"
                    *ngIf="activeMode == 'gallery' || activeMode == 'mapped' || activeMode == 'image-viewer' || activeMode == 'consultation'|| activeMode == 'consultation-viewer'">
                    {{selectedSlideForPreview.animalNo}}
                </span>
                <span class="previewimg-title" *ngIf="activeMode == 'scanned' || activeMode == 'uploaded'">
                    {{selectedSlideForPreview.slidePk}}
                </span>
            </div>
            <div class="next" (click)="next_slide()" [ngClass]="{'next-prev-dis' : NextBtnStatus}">
                <!-- <span class="next" (click)="next_slide()" [ngClass]="{'next-prev-dis' : NextBtnStatus}"> -->
                <i class="fa fa-chevron-right"></i>
                <!-- </span> -->
            </div>
        </div>
        <!-- <div class="rightfloat rightBtn">
            <div class="clearfix">
                <div class="rightfloat"
                    *ngIf="activeMode == 'image-viewer' && dialogData && dialogData.dialogType == 'compare'">
                    <button type="button" class="btn-default searchbtn" (click)="openSplitView()">Compare</button>
                </div>
                <div class="rightfloat"
                    *ngIf="(activeMode == 'consultation' || activeMode == 'consultation-viewer') && !alreadyMarkedImage()">
                    <button type="button" class="btn-default searchbtn" (click)="markToAddImage()">Mark To Add</button>
                </div>
                <div class="rightfloat" *ngIf="activeMode == 'gallery'">
                    <button type="button" class="btn-default searchbtn"
                        style="pointer-events: none;background: #E8E8E8; color: #A2A2A2;visibility: hidden;">Mark for
                        Share</button>
                </div>
                <div class="rightfloat" *ngIf="activeMode == 'scanned' || activeMode == 'uploaded'">
                    <button type="button" class="btn-default searchbtn" *ngIf="!discardingStatusP"
                        (click)="openDiscardDialog()">Discard
                        Image</button>
                </div>
                <div class="rightfloat" *ngIf="activeMode == 'mapped'">
                    <button *ngIf="unmappingStatusP" class="btn-default searchbtn" type="button" disabled>
                        <span class="spinner-border spinner-border-sm spinner-class" role="status"
                            aria-hidden="true"></span>
                        <span>UnMapping Image(s)</span>
                    </button>
                    <button type="button" class="btn-default searchbtn" *ngIf="!unmappingStatusP"
                        [ngClass]="{ 'disablebtn_func' :!selectedSlideForPreview.unTouched || !unmapFlag}"
                        (click)="openUnMapDialog()">Reject Image</button>
                </div>
            </div>
        </div> -->
    </div>
    <div class="previewslide_det">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-pills nav-justified" id="myTab" role="tablist">
            <li class="nav-item"
                *ngIf="activeMode == 'mapped' || activeMode == 'gallery' || activeMode == 'image-viewer' || activeMode == 'consultation' || activeMode == 'consultation-viewer'">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                    aria-selected="true">Slide Details</a>
            </li>
            <li class="nav-item"
                *ngIf="activeMode == 'gallery' || activeMode == 'image-viewer' || activeMode == 'consultation' || activeMode == 'consultation-viewer'">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                    aria-controls="profile" aria-selected="false">Slide Review</a>
            </li>
            <li class="nav-item" *ngIf="activeMode == 'scanned' || activeMode == 'uploaded'">
                <a class="nav-link active" id="map-tab" data-toggle="tab" href="#map" role="tab" aria-controls="map"
                    aria-selected="false">Map Images</a>
            </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab"
                *ngIf="activeMode == 'gallery' || activeMode == 'mapped' || activeMode == 'image-viewer' || activeMode == 'consultation' || activeMode == 'consultation-viewer'">
                <div class="row mbot_Preview padbot_1_5">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Slide ID: </label>
                        <p>{{selectedSlideForPreview.slideLabel}}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Image Name: </label>
                        <p>{{selectedSlideForPreview.slidename}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Subject Id </label>
                        <p>{{selectedSlideForPreview.animalNo}}</p>
                    </div>
                    <!-- <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Tissue: </label>
                        <p *ngIf="selectedSlideForPreview.organ">{{selectedSlideForPreview.organ.join(', ')}}</p>
                    </div> -->
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Stain: </label>
                        <p>{{selectedSlideForPreview.stain? selectedSlideForPreview.stain : "--"}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5">
                    <div *ngIf="!galleryFilter.isTCRStudy" class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Dosage Group: </label>
                        <p>{{selectedSlideForPreview.dosageCode}}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Sex: </label>
                        <p>{{selectedSlideForPreview.sex}}</p>
                    </div>
                </div>

                <div class="row mbot_Preview padbot_1_5" *ngIf="!galleryFilter.isTCRStudy">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Dose: </label>
                        <p>{{selectedSlideForPreview.dose? selectedSlideForPreview.dose : "--"}}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Dose Units: </label>
                        <p>{{selectedSlideForPreview.dosageUnit? selectedSlideForPreview.dosageUnit : "--"}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Created Date: </label>
                        <p>{{selectedSlideForPreview.scanDateTime? (selectedSlideForPreview.scanDateTime  |date: 'medium'): "--" }}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Magnification: </label>
                        <p>{{selectedSlideForPreview.scanMagnification? selectedSlideForPreview.scanMagnification :
                            "--"}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5" *ngIf="galleryFilter.isTCRStudy">
                    <!-- <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Stain: </label>
                        <p>{{selectedSlideForPreview.stain? selectedSlideForPreview.stain : "--"}}</p>
                    </div> -->
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" >
                        <label>Chromogen: </label>
                        <p>{{selectedSlideForPreview.chromogen? selectedSlideForPreview.chromogen : "--"}}</p>
                    </div>
                </div>
                <div class="padbot_1">
                    <div class="">
                        <label>Location: </label>
                        <p>{{selectedSlideForPreview.physicalLoc?
                            selectedSlideForPreview.physicalLoc :
                            "--"}}</p>
                    </div>
                </div>
                <div class="padbot_1">
                    <div class="">
                        <label>Tissue: </label>
                        <p *ngIf="selectedSlideForPreview.organ">{{selectedSlideForPreview.organ.join(', ')}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5" *ngIf="galleryFilter.isTCRStudy">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>IHC Experiment Num: </label>
                        <p>{{selectedSlideForPreview.ihcExperimentNumber? selectedSlideForPreview.ihcExperimentNumber :
                            "--"}}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Target Antigen: </label>
                        <p>{{selectedSlideForPreview.targetAntigen? selectedSlideForPreview.targetAntigen : "--"}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5" *ngIf="galleryFilter.isTCRStudy">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Primary AntiBody Id: </label>
                        <p>{{selectedSlideForPreview.primaryAntibodyID? selectedSlideForPreview.primaryAntibodyID :
                            "--"}}</p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Secondary AntiBody Id: </label>
                        <p>{{selectedSlideForPreview.secondayAntibodyID? selectedSlideForPreview.secondayAntibodyID :
                            "--"}}</p>
                    </div>
                </div>
                <div class="row mbot_Preview padbot_1_5" *ngIf="galleryFilter.isTCRStudy">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Detection Method: </label>
                        <p>{{selectedSlideForPreview.detectionMethod? selectedSlideForPreview.detectionMethod : "--"}}
                        </p>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label>Comments: </label>
                        <p>{{selectedSlideForPreview.slideComments? selectedSlideForPreview.slideComments : "--"}}</p>
                    </div>
                </div>
            </div>
            <!-- Technician Part -->
            <div class="tab-pane active" id="map" role="tabpanel" aria-labelledby="map-tab"
                *ngIf="activeMode == 'scanned' || activeMode == 'uploaded'">
                <form [formGroup]="mapForm" class="mapForm">
                    <div class="row mbot_1 padbot_1_5">
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Study Name<span class="mandatory">*</span></mat-label>
                                <mat-select formControlName="studyGuId"
                                    (selectionChange)="studySelectionChanged($event)">
                                    <mat-option *ngFor="let studyData of studyListArr" [value]="studyData.guid">
                                        {{studyData.studyNo}}</mat-option>
                                </mat-select>
                                <mat-error>{{mapF.studyGuId.invalid | inputError:'mapStudy' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Cassette<span class="mandatory">*</span></mat-label>
                                <mat-select formControlName="cassetteId"
                                    (selectionChange)="cassetteSelectionChanged($event)">
                                    <mat-option *ngFor="let cassetteData of cassetteArr" [value]="cassetteData.id">
                                        {{cassetteData.cassetteId}} ({{cassetteData.organs}})</mat-option>
                                </mat-select>
                                <mat-error>{{mapF.cassetteId.invalid | inputError:'mapCassette' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mbot_1 padbot_1_5">
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Stain<span class="mandatory">*</span></mat-label>
                                <mat-select formControlName="stainId" (selectionChange)="stainSelectionChanged($event)">
                                    <mat-option *ngFor="let stainData of stainArr" [value]="stainData.id">
                                        {{stainData.stain}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{mapF.stainId.invalid | inputError:'mapStain' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Subject Id<span class="mandatory">*</span></mat-label>
                                <mat-select formControlName="animalId">
                                    <mat-option *ngFor="let animalData of animalArr" [value]="animalData.animalId">
                                        {{animalData.animalNo}}</mat-option>
                                </mat-select>
                                <mat-error>{{mapF.animalId.invalid | inputError:'mapAnimal' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Comment</mat-label>
                            <textarea formControlName="comment" matInput placeholder="Comment..."
                                maxlength="250"></textarea>
                        </mat-form-field>
                    </div>
                </form>
                <div class="">
                    <button *ngIf="mappingStatusP" class="btn-default searchbtn" type="button" disabled>
                        <span class="spinner-border spinner-border-sm spinner-class" role="status"
                            aria-hidden="true"></span>
                        <span>Mapping Image(s)</span>
                    </button>
                    <button aria-label="mapbtn" class="btn orangebtn filterBarItems" *ngIf="!mappingStatusP"
                        [ngClass]="{ 'disablebtn_func' : mapForm.invalid}" type="button" (click)="mapImage()">Map
                        Image</button>
                </div>
            </div>
            <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab"
                *ngIf="activeMode == 'gallery' ||activeMode == 'image-viewer' || activeMode == 'consultation'">
                <div class="reviewrow">
                    <!-- <label style="white-space: pre;">Target Tissue: </label>
                    <mat-select (selectionChange)="getSlideReview()" [(ngModel)]="previewOrgan" style="width: 10vw;">
                        <mat-option *ngFor="let organ of selectedSlideForPreview.organ" [value]="organ">
                            {{organ}}
                        </mat-option>
                    </mat-select> -->

                    <mat-form-field class="commonMatFormField" appearance="outline" style="width: 12vw;">
                        <mat-label class="previewLabelbg">Target Tissue</mat-label>
                        <mat-select (selectionChange)="getSlideReview()" [(ngModel)]="previewOrgan">
                            <mat-option *ngFor="let organ of selectedSlideForPreview.organ" [value]="organ">
                                {{organ}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="slideReviewData[0].userFk == null" class="noDataForPreview">
                    No data
                </div>
                <div *ngIf="slideReviewData[0].userFk != null">
                    <div *ngFor="let review of slideReviewData">
                        <h6 class="emailh6">{{review.userFk}}</h6>
                        <div class="nonTcrReview" *ngIf="!galleryFilter.isTCRStudy">
                            <div class="reviewrow">
                                <label>Summary: </label>
                                <p>{{review.summary}}</p>
                            </div> <br />
                            <div class="reviewrow">
                                <label>Observation: </label>
                                <ul class="reviewobv">
                                    <li *ngFor="let obs of review.observations">
                                        <p>{{obs.description}} - <span><b>{{obs.gradingCode}}</b></span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="nonTcrReview" *ngIf="galleryFilter.isTCRStudy">
                            <div class="reviewrow">
                                <label>Intensity: </label>
                                <p>{{review.intensity}} - {{intensityObj[review.intensity]}}</p>
                            </div> <br />
                            <div class="reviewrow">
                                <label>Frequency: </label>
                                <p>{{review.frequency}} - {{frequencyObj[review.frequency]}}</p>
                            </div>
                        </div>
                        <div class="reviewrow">
                            <label>Comments: </label>
                            <p>{{review.comments}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>