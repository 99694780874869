import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { IconService } from './icons-setting/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'AIRADHI';
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.location.reload()
  }

  constructor(
    private iconService: IconService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.iconService.registerIcons()
  }

  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        if (!this.commonService?.ProjectLSData?.currentUser) {
          this.router.navigate(['/login']);
        }
      }
    });
    this.commonService.getSessionConfigData().subscribe((res: any) => {
      this.commonService.setLicensingModuleFlag(res.sessionOn);
      if (res.sessionOn) {
        this.commonService.setPingInterval(res.pingInterval);
        this.commonService.checkSessionActive(res.pingInterval);
      }
    });
  }
}
