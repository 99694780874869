<div class="missingDataHeader">
    <div class="headerText">Missing Metadata</div>
    <mat-icon class="cancelDialog" (click)="cancelDialog()">clear</mat-icon>
</div>
<div class="missingDataInfoText">
    <span>Below metadata(s) are missing in the Data Configuration.</span>
    <span *ngIf="isUserAdmin">You can add the missing data using the configuration table below.</span>
</div>
<div class="missingStainTissueMainDiv">
    <mat-card class="missingDataMatCard" *ngIf="(organArr?.length > 0)">
        <mat-card-header>
            <mat-card-subtitle class="cardSubTitle">
                <div class="headerMainDiv">
                    <div class="headerTitle">Tissues</div>
                    <!-- <button class="btnSaveAll" mat-mini-fab color="primary"><mat-icon>playlist_add</mat-icon></button> -->
                </div>
            </mat-card-subtitle>
            <!-- <mat-card-title-group>
                <mat-card-title>Tissues</mat-card-title>
                <button mat-card-xs-image class="btnSaveAll" mat-mini-fab color="primary"><mat-icon>playlist_add</mat-icon></button>
            </mat-card-title-group> -->
        </mat-card-header>
        <mat-card-content>
            <div class="cardBody">
                <div class="cardContainer organContainer">
                    <div class="dataRow tissueDiv" *ngFor="let organ of organArr">
                        <div class="dataDiv">
                            <div class="missingIndvData">
                                {{organ}}
                            </div>
                        </div>
                        <div class="dataIconDiv plusicon" *ngIf="isUserAdmin">
                            <mat-icon *ngIf="(organAddedArr.indexOf(organ) < 0)"
                                (click)="addMissingMetaData(organ, 'organ')">add</mat-icon>
                            <mat-icon class="missingMetadataAdded"
                                *ngIf="(organAddedArr.indexOf(organ) >= 0)">done</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="missingDataMatCard" *ngIf="(stainArr?.length > 0)">
        <mat-card-header>
            <mat-card-subtitle class="cardSubTitle">
                <div class="headerMainDiv">
                    <div class="headerTitle">Stain</div>
                    <!-- <button class="btnSaveAll" mat-mini-fab color="primary"><mat-icon>playlist_add</mat-icon></button> -->
                </div>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="cardBody">
                <div class="cardContainer stainContainer">
                    <div class="dataRow tissueDiv" *ngFor="let stain of stainArr">
                        <div class="dataDiv">
                            <div class="missingIndvData">
                                {{stain}}
                            </div>
                        </div>
                        <div class="dataIconDiv plusicon" *ngIf="isUserAdmin">
                            <mat-icon *ngIf="(stainAddedArr.indexOf(stain) < 0)"
                                (click)="addMissingMetaData(stain, 'stain')">add</mat-icon>
                            <mat-icon class="missingMetadataAdded"
                                *ngIf="(stainAddedArr.indexOf(stain) >= 0)">done</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="missingDataDisclaimer" *ngIf="isUserAdmin">
    <span class="starMark">*</span>Note: The changes will be added in the Data Configuration panel for the entire system
</div>
<div class="missingDataFooter dn">
    <button mat-flat-button class="btnCancel">Cancel</button>
    <button mat-flat-button color="primary" class="btnConfirm">Confirm</button>
</div>