import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../app-config.service';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { StudyDetailsComponent } from "./../study/study-details/study-details.component";
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { GalleryService } from '../gallery/gallery.service';
import { UsersService } from '../users/users.service';
import { ModalDigitalSignatureComponent } from '../digital-signature/modal-digital-signature/modal-digital-signature.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { AuthService } from '../auth/auth.service';
// import { ImageViewerTopBarService } from '../image-viewer/image-viewer-top-bar/image-viewer-top-bar.service';

@Component({
  selector: 'app-web-viewer-frame',
  templateUrl: './web-viewer-frame.component.html',
  styleUrls: ['./web-viewer-frame.component.css']
})
export class WebViewerFrameComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  id: string = '';
  // viewerSlides: any = [];

  constructor(private sanitizer: DomSanitizer,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
    private router: Router,
    // private topBarService: ImageViewerTopBarService,
    private galleryService: GalleryService,
    private authService: AuthService,
    private usersService: UsersService,
    private commonService: CommonService) {
    const msgEvent = (event: any) => {
      if ((event?.data?.call == 'parentComponent') && event?.data?.value?.fetchSlideInfo) {
        this.sendDataToViewer();
      } else if (event?.data?.call == 'showStudyDetails') {
        this.openStudyDetailDialog(event?.data?.value?.slideGUID);
      } else if (event?.data?.call == 'unmapImage') {
        let unMapObj: any = event?.data?.value?.unMapObj;
        if (unMapObj) {
          this.galleryService.bulkUnmapImages(unMapObj).subscribe((res: any) => {
            this.commonService.showToastMsg(res.message);
            this.moveToGallery(msgEvent);
          });
        }
      } else if (event?.data?.call == 'compareImage') {
        let slideIds: any = event?.data?.value?.slideIds;
        this.commonService.ProjectLSData['ViewerSlides'] = event?.data?.value?.slidesInfo;
        if (!this.commonService.ProjectLSData['primaryFilter']) {
          this.commonService.ProjectLSData['primaryFilter'] = Object.assign({}, this.commonService.ProjectLSData['galleryFilters']);
          this.commonService.ProjectLSData['galleryFilters']['viewByG'] = event?.data?.value?.compareActive ? '1' : this.commonService.ProjectLSData['galleryFilters']['viewByG'];
          this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'] = event?.data?.value?.compareActive ? event?.data?.value?.slidesInfo[0]?.animal?.dosageCode : this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'];
        } else if (this.commonService.ProjectLSData['primaryFilter'] && event?.data?.value?.compareActive) {
          this.commonService.ProjectLSData['galleryFilters']['viewByG'] = '1';
          this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'] = event?.data?.value?.slidesInfo[0]?.animal?.dosageCode;
        } else {
          this.commonService.ProjectLSData['galleryFilters'] = Object.assign({}, this.commonService.ProjectLSData['primaryFilter']);
          delete this.commonService.ProjectLSData['primaryFilter'];
        }
        this.commonService.ProjectLSData['unselectedFromCompare'] = [];
        this.commonService.setProjectLSData();
        this.commonService.changePlace('WebViewer/' + slideIds.toString());
        // this.router.navigate(['WebViewer/' + slideIds.toString()]).then(() => {
        this.router.navigate(['WebViewer']).then(() => {
          this.sendDataToViewer();
        });
      } else if (event?.data?.call == 'updateSlideReview') {
        this.galleryService.updateSlideReview(event?.data?.value?.slideReviewData, event?.data?.value?.organReviewPk, event?.data?.value?.slideId).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewSuccess', value: response.message
          }, this.appConfig.config.webViewerUrl);
        });
      } else if (event?.data?.call == 'saveSlideReview') {
        this.galleryService.saveSlideReview(event?.data?.value?.slideReviewData, event?.data?.value?.slideId, event?.data?.value?.isReviewShareable).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewSuccess', value: response.message
          }, this.appConfig.config.webViewerUrl);
        }, () => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewError'
          }, this.appConfig.config.webViewerUrl);
        });
      } else if (event?.data?.call == 'reLogin') {
        this.reLogin();
      } else if (event?.data?.call == 'fetchDSToken') {
        this.fetchDSToken();
      } else if (event?.data?.call == 'moveToGallery') {
        delete this.commonService.ProjectLSData['ViewerSlides'];
        this.commonService.setProjectLSData();
        this.moveToGallery(msgEvent);
      } else if (event?.data?.call == 'updateGalleryFilter') {
        this.commonService.ProjectLSData['galleryFilters'] = event?.data?.value?.galleryFilter;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'randomizationScoreUpdated') {
        this.commonService.ProjectLSData["randomizationScore"] = event?.data?.value?.randomizationScore;
        this.commonService.ProjectLSData["randomizationScoreObj"] = event?.data?.value?.randomizationScoreObj;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'updateCompareselection') {
        this.commonService.ProjectLSData["unselectedFromCompare"] = event?.data?.value?.unselected;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'emptyUnselectedCompare') {
        delete this.commonService.ProjectLSData["unselectedFromCompare"];
        this.commonService.setProjectLSData();
      }
    }
    window.addEventListener("message", msgEvent, false);
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.config.webViewerUrl);
  }

  fetchDSToken() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ModalDigitalSignatureComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result != 'Cancel Request') {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'fetchedDSToken', value: {
            dsToken: result.data.dsToken,
            dsComment: result.dsComment
          }
        }, this.appConfig.config.webViewerUrl);
      } else {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'cancelledDSToken'
        }, this.appConfig.config.webViewerUrl);
      }
    });
  }

  reLogin() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "50%";
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      isAccessTokenExpired: true
    };
    const dialogref = this.dialog.open(LoginDialogComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        let reLoginObj: any = {
          accessToken: this.commonService.ProjectLSData['accessToken'],
          refreshToken: this.commonService.ProjectLSData['refreshToken'],
          sessionId: this.commonService.ProjectLSData['sessionId']
        };
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'reLoginSuccess', value: reLoginObj
        }, this.appConfig.config.webViewerUrl);
      }
    });
  }

  moveToGallery(msgEvent: any) {
    window.removeEventListener("message", msgEvent, false);
    let openedFrom: any = this.commonService.getViewerOpenedFrom();
    openedFrom = (openedFrom !== 'mapped') ? 'gallery' : openedFrom;
    this.commonService.changePlace(openedFrom);
    this.router.navigate(["/dashboard/" + openedFrom]);
    // localStorage.setItem('samples', '');
  }

  sendDataToViewer() {
    // let viewerSlidesIds = location.pathname.split("/")[2].split(",");
    /* let viewerSlidesIds = location.pathname.slice((location.pathname.lastIndexOf("/") + 1), location.pathname.length).split(",");
    this.viewerSlides = viewerSlidesIds.map((indvId) => {
      return { id: indvId }
    }); */
    let iframe = document.getElementById('webViewerFrame');
    if (iframe == null) return;
    let windowObj = {
      parentProj: 'AIRADHI',
      filledAnnoOpacityLevel: this.appConfig.config.filledAnnoOpacityLevel,
      viewerOpenedFrom: this.commonService.ProjectLSData.viewerOpenedFrom ? this.commonService.ProjectLSData.viewerOpenedFrom : 'gallery',
      activeRole: this.commonService.ProjectLSData.userActiveRole,
      labelViewRotateDegree: this.commonService['config']['labelViewRotateDegree'],
      galleryFilter: this.commonService.ProjectLSData.galleryFilters,
      loginUrl: this.appConfig.config.parentLoginUrl,
      parentInfo: {},
      viewerBaseName: this.appConfig.config.viewerBaseName,
      urlParent: {
        "apiUrl": this.appConfig.config.apiUrl,
        "thumbnailUrl": this.appConfig.config.thumbnailUrl,
        "tileUrl": this.appConfig.config.tileUrl
      },
      // viewerSlides: this.viewerSlides,
      viewerSlides: this.commonService.ProjectLSData['ViewerSlides'],
      lastParentAppUrl: '',
      currentUser: this.commonService.ProjectLSData['currentUser'],
      dsComments: this.usersService.dsComment,
      isDsTokenStored: this.usersService.isDsTokenStored,
      storedDsToken: this.usersService.getDsToken(),
      anonymisedLabel: this.appConfig.config.anonymisedLabel,

      WebViewerOpenType: this.appConfig.config.openViewer,
      accessToken: this.commonService.ProjectLSData['accessToken'],
      refreshToken: this.commonService.ProjectLSData['refreshToken'],
      sessionId: this.commonService.ProjectLSData['sessionId'],
      unselectedFromCompare: this.commonService.ProjectLSData['unselectedFromCompare'],
      randomisationInfo: {
        isRandomisation: this.commonService.ProjectLSData.isRandomisationStarted,
        randomisedSlideIdsArr: this.commonService.ProjectLSData.randomisedSlideIds,
        randomizationScore: this.commonService.ProjectLSData.randomizationScore,
        randomizationScoreObj: this.commonService.ProjectLSData.randomizationScoreObj
      }
    }
    iframe['contentWindow'].postMessage({
      call: 'webViewerFrame', value: windowObj
    }, this.appConfig.config.webViewerUrl);
  }

  openStudyDetailDialog(slideGUID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = "90%";
    dialogConfig.data = {
      showHeader: true,
      studyGuid: slideGUID
    };
    this.dialog.open(StudyDetailsComponent, dialogConfig);
  }
}
