import { CommonService } from '../common.service';
import { SidebarService } from './sidebar.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  places: any[] = [];
  selectedRole: any;
  currentModule: any;
  viewerOpenPlace: any;
  constructor(public sidebarService: SidebarService, private commonService: CommonService, public router: Router) { }

  ngOnInit() {
    this.commonService.currentRole.subscribe(role => {
      if (role !== this.selectedRole) {
        this.selectedRole = role;
        this.getRolePlaces(this.selectedRole);
      }
    });
    this.commonService.currentPlace.subscribe(module => {
      this.currentModule = module;
    });
    this.commonService.ViewerOpen.subscribe(module => {
      this.viewerOpenPlace = module;
    });
    this.viewerOpenPlace = this.commonService.getViewerOpenedFrom();
  }


  getRolePlaces(role: any) {
    this.places = [];
    this.sidebarService.getRolePlaces(role).subscribe((response: any) => {
      response.data.places.forEach(place => {
        if (place == 'Scanned') {
          this.places.push({
            'displayName': 'Image Repository',
            'moduleName': place
          });
        } else if (place == 'Consultation') {
          this.places.push({
            'displayName': 'Meeting',
            'moduleName': place
          });
        } else if (place == "DigitalSignature") {
          this.places.push({
            displayName: "Digital Signature",
            moduleName: "DigitalSignature",
          });
        } else {
          this.places.push({
            'displayName': place,
            'moduleName': place
          });
        }
      });
      let activePlace = this.commonService.ProjectLSData.userActivePlace;
      if (activePlace) {
        this.changePlace(activePlace);
      } else {
        this.changePlace(this.places[0].moduleName);
      }
    });
  }

  changePlace(place: any) {
    let role = this.commonService.ProjectLSData.userActiveRole;
    var p = place.includes('/') ? place : (place.toLowerCase());
    this.commonService.changePlace(p);
    if (p == 'study') {
      this.router.navigate(['dashboard/' + p + '/' + role]);
    } else {
      this.router.navigate(['dashboard/' + p]);
    }
  }
}
