import { StudyService } from './../study.service';
import { CommonService } from '../../common.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ConfirmDialogComponent } from "./../../common/confirm-dialog/confirm-dialog.component";
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
// declare const printThis:any;

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.css']
})
export class StudyDetailsComponent implements OnInit {
  datepipe = new DatePipe('en-US');
  isDownloadBtnClicked: boolean = false;
  isTCRStudy: boolean = false;

  studyGuid: any;
  studyDetailData = {
    studyNo: '',
    studyTitle: '',
    projectNo: '',
    accessionNo: '',
    startDate: '',
    endDate: '',
    treatment: '',
    studyDomain: '',
    studyType: '',
    sacrifice: '',
    species: '',
    strain: '',
    route: '',
    cassettes: [],
    dosages: [],
    studyDirector: '',
    pathologist: '',
    reAssignedpathologistArr: [],
    peerReviewer: '',
    reAssignedPeerReviewerArr: [],
    technician: '',
    reAssignedTechnicianArr: [],
    primaryInvestigator: '',
    reAssignedPrimaryInvestigatorArr: [],
    slideCount: 0,
    cro: '',
    testItem: '',
    imageDetailsModel: [],
    isTCRStudy: false,
    additionalPathologist: [],
    reAssignedAdditionalPathologistArr: [],
    reviewSharable: false
  };
  activeRole: any;
  showSlideDataLoader: boolean = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<StudyDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private studyDetailsService: StudyService,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.route.paramMap.subscribe((paramsData: any) => {
      this.studyGuid = paramsData.params['studyGuId'];
    });

    if (!this.studyGuid && data) {
      this.studyGuid = data.studyGuid;
    }
  }

  ngOnInit() {
    this.isTCRStudy = this.commonService.ProjectLSData.isTCRStudy;
    this.commonService.setProjectLSData()
    this.activeRole = this.commonService.ProjectLSData.userActiveRole;
    this.studyDetailsService.getStudyDetails(this.studyGuid).subscribe((response: any) => {
      this.studyDetailData = response.data;
      let additionalPath = [], reassignedPathologist = [], reassignedTechnician = [],
        reassignedPeerReviewer = [], reassignedPrimaryInvestigator = [], reassignedAdditionalPathologist = [];
      if (this.studyDetailData['studyUsers'].length) {
        for (let i = 0; i < this.studyDetailData['studyUsers'].length; i++) {
          if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "director") {
            this.studyDetailData['studyDirector'] = this.studyDetailData['studyUsers'][i].displayName;
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "pathologist") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPathologist.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['pathologist'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "peer reviewer") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPeerReviewer.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['peerReviewer'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "technician") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedTechnician.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['technician'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "primary investigator") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPrimaryInvestigator.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['primaryInvestigator'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              //this.studyDetailData['reAssignedAdditionalPathologistArr'].push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              additionalPath.push(this.studyDetailData['studyUsers'][i].displayName);
            }
          }
        }
        this.studyDetailData['additionalPathologist'] = additionalPath;
        this.studyDetailData['reAssignedpathologistArr'] = reassignedPathologist;
        this.studyDetailData['reAssignedPeerReviewerArr'] = reassignedPeerReviewer;
        this.studyDetailData['reAssignedTechnicianArr'] = reassignedTechnician;
        this.studyDetailData['reAssignedPrimaryInvestigatorArr'] = reassignedPrimaryInvestigator;
        this.studyDetailData['reAssignedAdditionalPathologistArr'] = reassignedAdditionalPathologist;

      }

      /*Cassette Table*/
      $('#p_cassettedet').DataTable({
        "bDestroy": true,
        data: this.studyDetailData.cassettes,
        responsive: true,
        "order": [
          [0, "asc"]
        ],
        columns: [
          { title: 'Cassette Id', data: 'cassetteId', sWidth: '20%' },
          {
            title: 'Stain', data: 'stains', sWidth: '15%',
            render: function (data) {
              return '<div class="cassetteStain" class="textellipsis_td" data-toggle="tooltip" data-placement="left" title="' + data.join(', ') + '">' + data.join(', ') + '</div>'
            }
          },
          {
            title: 'Tissue', data: 'organs', sWidth: '65%',
            render: function (data) {
              return '<div class="cassetteOrgan" class="textellipsis_td" data-toggle="tooltip" data-placement="left" title="' + data.join(', ') + '">' + data.join(', ') + '</div>'
            }
          }

        ],
        /* columnDefs: [{
          targets: [0, 1, 2],
          className: 'mdl-data-table__cell--non-numeric'
        }], */
        "autoWidth": false,
        "scrollY": "45vh",
        "scrollCollapse": true,
        "paging": false,
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });

      /*Dosage Table*/
      $('#p_dosagedet').DataTable({
        "bDestroy": true,
        data: this.studyDetailData.dosages,
        responsive: true,
        columns: [
          { title: 'Dosage Code', data: 'dosageCode', sWidth: '10%' },
          { title: 'Dosage Name', data: 'dosageName', sWidth: '10%' },
          { title: 'Definition Type', data: 'animalSequenceType', sWidth: '10%' },
          {
            title: 'Male Values', data: 'animals', sWidth: '15%',
            render: function (data, type, row, meta) {
              if (row.animalSequenceType == "RANGE") {
                if (data.maleStartIndex && data.maleEndIndex) {
                  return data.maleStartIndex + "-" + data.maleEndIndex;
                } else {
                  return '';
                }
              } else {
                if (data.maleList) {
                  return '<div class="textellipsis_td" data-toggle="tooltip" data-placement="left" title="' + data.maleList.join(', ') + '">' + data.maleList.join(', ') + '</div>';
                } else {
                  return '';
                }
              }
            }
          },
          {
            title: 'Female Values', data: 'animals', sWidth: '15%',
            render: function (data, type, row, meta) {
              if (row.animalSequenceType == "RANGE") {
                if (data.femaleStartIndex && data.femaleEndIndex) {
                  return data.femaleStartIndex + "-" + data.femaleEndIndex;
                } else {
                  return '';
                }
              } else {
                if (data.femaleList) {
                  return '<div class="textellipsis_td" data-toggle="tooltip" data-placement="left" title="' + data.femaleList.join(', ') + '">' + data.femaleList.join(', ') + '</div>';
                } else {
                  return '';
                }
              }
            }
          },
          {
            title: 'Not Known', data: 'animals', sWidth: '15%',
            render: function (data, type, row, meta) {
              if (row.animalSequenceType == "CUSTOM") {
                return '<div class="textellipsis_td" data-toggle="tooltip" data-placement="left" title="' + data.notKnownList.join(', ') + '">' + data.notKnownList.join(', ') + '</div>';
              } else {
                return ''
              }
            }
          },
          {
            title: 'Dose', data: 'dose', sWidth: '5%'
          },
          {
            title: 'Dose Unit', data: 'dosageUnit', sWidth: '10%'
          },
          {
            title: 'Control Group', data: 'controlGroup', sWidth: '10%',
            render: function (data) {
              if (data) {
                return "Yes"
              } else {
                return "No"
              }
            }
          }
        ],
        "order": [
          [0, "asc"]
        ],
        /* columnDefs: [{
          targets: [0, 1, 2],
          className: 'mdl-data-table__cell--non-numeric'
        }], */
        "scrollY": "45vh",
        "scrollCollapse": true,
        "paging": false,
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });

      $('[data-toggle="tooltip"]').tooltip();

      /*Slide Table */
      //this.slidesArr = this.studyDetailData["imageDetailsModel"];
      if (this.studyDetailData.slideCount > 0) {
        this.showSlideDataLoader = true;
        this.studyDetailsService.getStudySlideDetailsPagination(this.studyGuid).subscribe((response: any) => {
          this.studyDetailData["imageDetailsModel"] = response.data.imageDetailsModel;
          this.createSlideDataStructure();
          this.showSlideDataLoader = false;
        }, (err) => {
          this.showSlideDataLoader = false;
        });
      } else {
        this.createSlideDataStructure();
      }

    });
  }

  createSlideDataStructure() {
    var dt = $('#p_slidedet').DataTable({
      "bDestroy": true,
      data: this.studyDetailData["imageDetailsModel"],
      responsive: true,
      rowId: 'imageModel.slideLabel',
      columns: [
        {
          "orderable": false,
          "title": '#',
          "class": "index-row",
          "data": null, sWidth: '0.5vw',
          "defaultContent": '<div class="indexRow"></div>'
        },
        {
          "class": "details-control dt-body-center",
          "orderable": false, sWidth: '0.5vw',
          "data": null,
          "defaultContent": '<div class="rightArrow"></div>'
        },
        { title: 'Slide Id', data: 'imageModel.slideLabel', sWidth: '5vw' },
        { title: 'Image Name', data: 'imageModel.slidename', sWidth: '15vw' },
        /* { title: 'Subject Id', data: 'slideInfo.animalNo' },
        { title: 'Cassette Id', data: 'slideInfo.cassetteCode' },
        { title: 'Stain', data: 'slideInfo.stain' },
        {
          title: 'Stain Date', data: 'imageModel.stainDate', render: (data, type, row, meta) => {
            return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
          }
        }, */
        {
          title: 'Tissue Section', data: 'imageModel.tissueSectionCutting',
          render: function (data) {
            if (data) {
              return "<div>" + data + "</div>"
            } else {
              return "--"
            }
          }
        },
        {
          title: 'Slide Control', data: 'imageModel.slideControl',
          render: function (data) {
            if (data) {
              return "<div>" + data + "</div>"
            } else {
              return "--"
            }
          }
        },
        {
          title: 'Scan Date', data: 'imageModel.scanDateTime', render: (data, type, row, meta) => {
            return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
          }
        },
        {
          title: 'Magnification', data: 'imageModel.scanner.scanMagnification', width: '10vw',
          render: function (data) {
            if (data) {
              return "<div>" + data + "</div>"
            } else {
              return "--"
            }
          }
        },
        {
          title: 'Instrument', data: 'imageModel.scanner.instrument', width: '10vw',
          render: function (data) {
            if (data) {
              return "<div>" + data + "</div>"
            } else {
              return "--"
            }
          }
        },
        // { title: 'Comments', data: 'imageModel.slideComments', width: '10vw' },
      ],
      "order": [
        [2, "asc"]
      ],
      "scrollY": "45vh",
      "scrollCollapse": true,
      "paging": false,
      "bPaginate": false,
      // "bFilter": false,
      "bInfo": false
    });

    $('#p_slidedet tbody').on('click', 'tr td.details-control', ((e) => {
      var tr = $(e.currentTarget).closest('tr');
      var row = dt.row(tr);
      // var idx = $.inArray(tr.attr('id'), this.studyDetailData["imageDetailsModel"]);
      var idx = this.getSlideIndex(tr.attr('id'), this.studyDetailData["imageDetailsModel"]);

      if (row.child.isShown()) {
        tr.removeClass('details');
        row.child.hide();

        // Remove from the 'open' array
        this.studyDetailData["imageDetailsModel"].splice(idx, 1);
      }
      else {
        tr.addClass('details');
        let rowData = row.data();
        row.child(this.rowDetailsTemplate('loading')).show();
        this.studyDetailsService.getIndividualSlideDetails(this.studyGuid, rowData.imageModel.slidePk).subscribe((resp: any) => {
          row.child(this.rowDetailsTemplate(resp.data)).show();

          // Add to the 'open' array
          if (idx === -1) {
            this.studyDetailData["imageDetailsModel"].push(tr.attr('id'));
          }
        });
      }
    }).bind(this));

    dt.on('order.dt search.dt', function () {
      dt.column(0, { search: 'applied', order: 'applied' }).nodes().each(function (cell: any, i: any) {
        cell.innerHTML = ('' + (i + 1)).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\"/g, '&quot;').replace(/\'/g, '&#39;').replace(/\//g, '&#x2F;');
      });
    }).draw();

    dt.on('draw', function () {
      if (this.studyDetailData && this.studyDetailData["imageDetailsModel"]) {
        $.each(this.studyDetailData["imageDetailsModel"], function (i, id) {
          $('#' + id + ' td.details-control').trigger('click');
        });
      }
    });
  }

  rowDetailsTemplate(rowInfo) {
    if (rowInfo == 'loading') {
      return "<div class='rowAdditionalInfo'>Loading...</div>";
    } else {
      var rowMainData = "<div class='rowAdditionalInfo'>";
      var templateData = '';

      /* if ((rowInfo.imageModel.scanner && (rowInfo.imageModel.scanner.instrument || rowInfo.imageModel.scanner.scanMagnification)) || rowInfo.imageModel.scanDateTime) {
        // {
        //   title: 'Scan Date', data: 'imageModel.scanDateTime', render: (data, type, row, meta) => {
        //     return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
        //   }
        // },
        let scanDateData = (rowInfo.imageModel.scanDateTime) ? this.datepipe.transform(rowInfo.imageModel.scanDateTime, 'dd-MM-yyyy') : "";
        let scanDateTemp = (scanDateData ? "<div class='inlineBlock'><span class='keyTag'>Scan Date: </span><span class='sText'>" + scanDateData + "</span></div>" : "");
        let instrumentTemp = (rowInfo.imageModel.scanner && rowInfo.imageModel.scanner.instrument ? "<div class='inlineBlock'><span class='keyTag'>Instrument: </span><span class='sText'>" + rowInfo.imageModel.scanner.instrument + "</span></div>" : "");
        let scanMagnificationTemp = (rowInfo.imageModel.scanner && rowInfo.imageModel.scanner.scanMagnification ? "<div class='inlineBlock'><span class='keyTag'>Scan Magnification: </span><span class='sText'>" + rowInfo.imageModel.scanner.scanMagnification + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Scanner: </div>" + instrumentTemp + scanMagnificationTemp + scanDateTemp + "</div>";
      } */
      if (rowInfo.slideInfo) {
        // {
        //   title: 'Scan Date', data: 'imageModel.scanDateTime', render: (data, type, row, meta) => {
        //     return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
        //   }
        // },
        let cassetteId = '';
        if (!this.studyDetailData.isTCRStudy) {
          cassetteId = (rowInfo.slideInfo.cassetteCode ? "<div class='inlineBlock'><span class='keyTag'>Cassette Id: </span><span class='sText'>" + rowInfo.slideInfo.cassetteCode + "</span></div>" : "");
        }
        let animalNo = (rowInfo.slideInfo.animalNo ? "<div class='inlineBlock'><span class='keyTag'>Subject Id: </span><span class='sText'>" + rowInfo.slideInfo.animalNo + "</span></div>" : "");
        let stain = (rowInfo.slideInfo.stain ? "<div class='inlineBlock'><span class='keyTag'>Stain: </span><span class='sText'>" + rowInfo.slideInfo.stain + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Additional Metadata: </div>" + cassetteId + animalNo + stain + "</div>";
      }

      if (rowInfo.ihcStainModel) {
        let chromogenTemp = (rowInfo.ihcStainModel.chromogen ? "<div class='inlineBlock'><span class='keyTag'>Chromogen: </span><span class='sText'>" + rowInfo.ihcStainModel.chromogen + "</span></div>" : "");
        let detectionMethodTemp = (rowInfo.ihcStainModel.detectionMethod ? "<div class='inlineBlock'><span class='keyTag'>Detection Method: </span><span class='sText'>" + rowInfo.ihcStainModel.detectionMethod + "</span></div>" : "");
        let ihcExperimentNumberTemp = (rowInfo.ihcStainModel.ihcExperimentNumber ? "<div class='inlineBlock'><span class='keyTag'>IHC Experiment Number: </span><span class='sText'>" + rowInfo.ihcStainModel.ihcExperimentNumber + "</span></div>" : "");
        let primaryAntibodyConcentrationTemp = (rowInfo.ihcStainModel.primaryAntibodyConcentration ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody Conc: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyConcentration + "</span></div>" : "");
        let primaryAntibodyConcentrationUnitTemp = (rowInfo.ihcStainModel.primaryAntibodyConcentrationUnit ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody Conc Unit: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyConcentrationUnit + "</span></div>" : "");
        let primaryAntibodyIDTemp = (rowInfo.ihcStainModel.primaryAntibodyID ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody ID: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyID + "</span></div>" : "");
        let secondayAntibodyIDTemp = (rowInfo.ihcStainModel.secondayAntibodyID ? "<div class='inlineBlock'><span class='keyTag'>Seconday Antibody ID: </span><span class='sText'>" + rowInfo.ihcStainModel.secondayAntibodyID + "</span></div>" : "");
        let targetAntigenTemp = (rowInfo.ihcStainModel.targetAntigen ? "<div class='inlineBlock'><span class='keyTag'>Target Antigen: </span><span class='sText'>" + rowInfo.ihcStainModel.targetAntigen + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'>" + chromogenTemp + detectionMethodTemp + ihcExperimentNumberTemp +
          primaryAntibodyConcentrationTemp + primaryAntibodyConcentrationUnitTemp + primaryAntibodyIDTemp + secondayAntibodyIDTemp + targetAntigenTemp + "</div>";
      }

      if (rowInfo.imageModel && rowInfo.imageModel.slideComments) {
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Comments: </div><span class='sText'>" + rowInfo.imageModel.slideComments + "</span></div>";
      }

      if (!templateData) {
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'> No additional data to display</div></div>";
      }
      rowMainData += templateData + "</div>";
      return rowMainData;
    }
  }

  getSlideIndex(id, arr) {
    let index = -1;
    for (let x = 0; x < arr.length; x++) {
      if (arr[x]["imageModel"] && id == arr[x]["imageModel"].slideLabel) {
        index = x;
        break;
      }
    }

    return index;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  downloadReport() {
    this.isDownloadBtnClicked = true;
    $("#downloadReportDiv").append($(".p_studydetail").clone().css("height", "auto").find(".dataTables_scrollBody").css({ "max-height": "none" }).closest(".p_studydetail"));
    let data = $("#downloadReportDiv");

    var HTML_Width = $(data).width();
    var HTML_Height = $(data).height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas($(data)[0], { allowTaint: true }).then(((canvas) => {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(i.toString(), 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }

      pdf.save("Report.pdf");

      $("#downloadReportDiv").html('');
      this.isDownloadBtnClicked = false;
    }).bind(this), (() => {
      this.isDownloadBtnClicked = false;
    }).bind(this));
    return;

    $(".StudyDetailsMain").clone().css("height", "auto").find(".dataTable thead th").each(function (i, item) {
      if ($(item).text() == "Cassette Code") {
        $(item).css("width", "40%");
        $(item).closest("table").css("width", "100%");
        $(item).closest(".dataTables_scrollHeadInner").css("width", "100%");
      } else if (($(item).text() == "Tissue") || ($(item).text() == "Stain")) {
        $(item).css("width", "30%");

      } else if ($(item).text() == "Dosage Code") {
        $(item).css("width", "16%");
        $(item).closest("table").css("width", "100%");
        $(item).closest(".dataTables_scrollHeadInner").css("width", "100%");
      } else if ($(item).text() == "Dosage Name") {
        $(item).css("width", "17%");
      } else if ($(item).text() == "Definition Type") {
        $(item).css("width", "18.5%");
      } else if ($(item).text() == "Male Values") {
        $(item).css("width", "14%");
      } else if ($(item).text() == "Female Values") {
        $(item).css("width", "17.5%");
      } else if ($(item).text() == "Control Group") {
        $(item).css("width", "17%");
      }
    }).end().printThis({
      debug: true,               // show the iframe for debugging
      importCSS: true,            // import parent page css
      importStyle: true,         // import style tags
      printContainer: true,       // print outer container/$.selector
      loadCSS: ["/app/study/study-details/study-details.component.css"],                // path to additional css file - use an array [] for multiple
      pageTitle: "",              // add title to print page
      removeInline: false,        // remove inline styles from print elements
      removeInlineSelector: "*",  // custom selectors to filter inline styles. removeInline must be true
      printDelay: 2000,            // variable print delay
      header: null,               // prefix to html
      footer: null,               // postfix to html
      base: false,                // preserve the BASE tag or accept a string for the URL
      formValues: true,           // preserve input/form values
      canvas: true,              // copy canvas content
      doctypeString: '...',       // enter a different doctype for older markup
      removeScripts: false,       // remove script tags from print content
      copyTagClasses: true,      // copy classes from the html & body tag
      beforePrintEvent: null,     // function for printEvent in iframe
      beforePrint: function () {
        $("iframe[name='printIframe']").css('visibility', 'hidden');
      },          // function called before iframe is filled
      afterPrint: function () {
        $("iframe[name='printIframe']").remove();
      }
    });
  }

  navigateBackToStudyListing() {
    this.commonService.changePlace("study/" + this.activeRole);
    this.router.navigate(["/dashboard/study/" + this.activeRole]);
  }

  deleteStudy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Study",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_STUDY"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.studyDetailsService.deleteStudy(this.studyGuid).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.navigateBackToStudyListing();
        });
      }
    });
  }

  editStudy() {
    this.commonService.changePlace("study/" + this.activeRole + "/edit/" + this.studyGuid);
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/edit/" + this.studyGuid]);
  }

  openGallery() {
    this.commonService.ProjectLSData.galleryFilters.studyName = this.studyGuid;
    this.commonService.ProjectLSData.galleryFilters.isTCRStudy = this.studyDetailData.isTCRStudy;
    this.commonService.setProjectLSData();
    this.commonService.changePlace("gallery");
    this.router.navigate(["/dashboard/gallery"]);
  }

}