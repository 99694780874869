<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div *ngIf="!passChngSucs" class="dialog-title">Change Password</div>
    <div *ngIf="passChngSucs" class="dialog-title">Changed Password</div>
  </div>
  <div class="headerIcon float-right" *ngIf="!passChngSucs">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div *ngIf="!passChngSucs" class="container dialog-container modal-body changepwd_div">
  <div class="float-left">
    <form [formGroup]="changePwdform">
      <div class="padbot_1 paddingbottom">
        <mat-form-field class="form-element gselect newwidth mbot_1">
          <input matInput placeholder="Old Password" [type]="oHide ? 'password' : 'text'" formControlName="oldPwd"
            (keydown.space)="$event.preventDefault();">
          <mat-icon matSuffix (click)="oHide = !oHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="oHide">
            {{oHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>{{formControls.oldPwd.errors | inputError:'oldPwd' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="padbot_1 paddingbottom">
        <mat-form-field class="form-element gselect newwidth mbot_1">
          <input matInput placeholder="New Password" [type]="nHide ? 'password' : 'text'" formControlName="newPwd"
            (keydown.space)="$event.preventDefault();">
          <mat-icon matSuffix (click)="nHide = !nHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="nHide">
            {{nHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>{{formControls.newPwd.errors | inputError:'newPwd' | translate}}</mat-error>
        </mat-form-field>

      </div>
      <div>
        <mat-form-field class="form-element gselect newwidth mbot_1">
          <input matInput placeholder="Confirm New Password" [type]="cnHide ? 'password' : 'text'"
            formControlName="confirmPwd" (keydown.space)="$event.preventDefault();">
          <mat-icon matSuffix (click)="cnHide = !cnHide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="cnHide">
            {{cnHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>{{formControls.confirmPwd.errors | inputError:'confirmPwd' | translate}}</mat-error>
        </mat-form-field>
        <div class="pwdnote">
          <span>Note:</span> Password should be longer than 7 characters including numbers, symbols,
          and both uppercase and lowercase characters.
        </div>
      </div>
      <div *ngIf="!passChngSucs" class="actions dialog-actions modal-footer changePasswordButtonDiv">
        <button *ngIf="!showChangepasswordLoader" class="btn-default btn-block loginbtn changePasswordButton"
          [ngClass]="{ 'disableFunction' : changePwdform.invalid } " (click)="changePaswrd()">Change Password</button>
        <button *ngIf="showChangepasswordLoader" class="btn-default btn-block loginbtn">
          <span class="myspinner spinner-border spinner-border-sm" role="status" aria-hidden="true"
            style="margin-right: 0.5vw; width: 1vw; height: 1vw;"></span>
          Changing Password</button>
      </div>
    </form>
  </div>
  <!-- <span>{{beError}}</span> -->
  <!-- <div class="float-right infoDiv">
    <div class="infoHeading">Make sure your password:</div>
    <ol>
      <li>Is longer than 7 characters</li>

    </ol>
  </div> -->
</div>
<div *ngIf="passChngSucs" class="pwdsuccess">
  <div class="paddingbottom">{{msg}}</div>
  <div class="paddingbottom">
    <img [src]="'../' + appConfig.config.appBaseName + 'assets/images/viewer_icon/tickIcon.svg'" />
  </div>
  <button (click)="redirectToLogin()" class="btn-default searchbtn createstudybtn">Login</button>
</div>