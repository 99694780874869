<!--Page Title-->
<div class="pageheader clearfix"
  *ngIf="activeMode == 'gallery' || activeMode == 'scanned' || activeMode == 'uploaded' || activeMode == 'mapped'">
  <div class="pageheadtitle">
    <h4 *ngIf="activeMode == 'gallery'">Gallery</h4>
    <h4 *ngIf="activeMode == 'scanned'">Image Repository</h4>
    <h4 *ngIf="activeMode == 'uploaded'">Uploaded Images</h4>
    <h4 *ngIf="activeMode == 'mapped'">Mapped Images</h4>
  </div>
  <div class="float-right" *ngIf="activeMode == 'scanned' || activeMode == 'uploaded' || activeMode == 'mapped'">
    <img *ngIf="activeMode == 'scanned'" (click)="refreshListData()"
      [src]="'../../../' + appConfig.config.appBaseName + 'assets/images/viewer_icon/Sync-Active.svg'"
      alt="Refresh List" aria-label="Refresh List" class="uploadicon" />
    <!-- <div class="recently_mapped_upload"> -->
    <!-- <button class="btn-default searchbtn float-left" type="button" *ngIf="activeMode == 'scanned'"
      (click)="mappingExcelUpload.click()">Upload Mapping File</button> -->
    <input type="file" #fileUpload accept=".svs,.scn,.ndpi,.mrxs,.czi,.svslide,.tif,.tiff,.bif" multiple="multiple"
      style="display:none;" (change)="onUploadClick()" />
    <input type="file" accept=".xls,.xlsx" style="display: none" #mappingExcelUpload
      (change)="uploadMappingSheet($event)">
    <div class="float-left dropdown technidropdown" *ngIf="activeMode == 'uploaded'">
      <button type="button" class="btn-default technidropdown_list dropdown-toggle" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        Upload
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item scannedImage" (click)="fileUpload.click()">Upload Image</a>
        <a class="dropdown-item uploadedImage" (click)="mappingExcelUpload.click()">Upload Mapping file</a>
      </div>
    </div>
  </div>
</div>

<!--Filter bar-->
<div class="galleryHeaderMainDiv">
  <div class="galleryHeaderLeftDiv">
    <mat-form-field class="commonMatFormField myStudyDropDown" appearance="outline"
      *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer') || (activeMode == 'consultation') || (activeMode == 'consultation-viewer'))">
      <mat-select [(ngModel)]="selectedStudyProj" (selectionChange)="changeStudyProject('change')"
        panelClass="myPanelClass"
        [disabled]="activeMode == 'image-viewer'&& dialogData && (dialogData.dialogType == 'compare' || dialogData.dialogType == 'filter')">
        <mat-option *ngFor="let studyProj of studyProjectList" [value]="studyProj.guid">
          {{studyProj.studyNo}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!galleryService.isStudyRandomised">
      <!-- Button trigger modal -->
      <button type="button" class="btn filterBarItems infoicon" data-toggle="modal" aria-label="studydetail"
        (click)="openStudyProjectDetailDialog()"
        *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer') || (activeMode == 'consultation') || (activeMode == 'consultation-viewer'))"
        style="padding: 0;">
        <!--  <i class="fa fa-info infoicon"></i> -->i
      </button>
      <div class="tabordion"
        *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer' && quickFilterView == 'viewerCustom') || (activeMode == 'consultation')|| (activeMode == 'consultation-viewer'))">
        <section id="section1">
          <input type="radio" name="sections" id="option1" checked>
          <label for="option1 boxoutline" (click)="toggleFilter()" [matMenuTriggerFor]="galleryFilterPopOver"><i
              class="fa fa-filter filtericon" aria-hidden="true"></i> &nbsp;
            &nbsp;<i class="fa fa-caret-down arrowicon" aria-hidden="true"></i></label>
          <mat-menu #galleryFilterPopOver="matMenu" class="galleryFilterPopOver">


            <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
              <mat-form-field class="commonMatFormField" appearance="outline">
                <mat-label class="labelbg">Subject Id</mat-label>
                <mat-select multiple [(ngModel)]="animalsF" (click)="$event.stopPropagation();">
                  <mat-option *ngFor="let animal of studyAnimalList" [title]="animal.animalNo"
                    [value]="animal.animalNo">
                    {{ animal.animalNo }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
              <mat-form-field class="commonMatFormField" appearance="outline">
                <mat-label class="labelbg">Tissue</mat-label>
                <mat-select multiple [(ngModel)]="organsF" (click)="$event.stopPropagation();">
                  <mat-option *ngFor="let organ of studyOrganList" [title]="organ.organ" [value]="organ.organ">{{
                    organ.organ }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
              <mat-form-field class="commonMatFormField" appearance="outline">
                <mat-label class="labelbg">Dosage</mat-label>
                <mat-select multiple [(ngModel)]="dosageF" (click)="$event.stopPropagation();">
                  <mat-option *ngFor="let animal of studyDosageList" [title]="animal.dosageName"
                    [value]="animal.dosageCode">
                    {{ animal.dosageName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
              <mat-form-field class="commonMatFormField" appearance="outline">
                <mat-label class="labelbg">Stain</mat-label>
                <mat-select multiple [(ngModel)]="stainsF" (click)="$event.stopPropagation();">
                  <mat-option *ngFor="let stain of studyStainList" [title]="stain.stain" [value]="stain.stain">{{
                    stain.stain }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="headerFilterPopOverRowDiv" *ngIf="galleryFilter?.isTCRStudy"
              (click)="$event.stopPropagation();">
              <mat-form-field class="commonMatFormField" appearance="outline">
                <mat-label class="labelbg">Type</mat-label>
                <mat-select multiple [(ngModel)]="controlF" (click)="$event.stopPropagation();">
                  <mat-option *ngFor="let control of slideControl" [title]="control.optionId"
                    [value]="control.optionId">
                    {{ control.optionName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="row mtop_1">
            <div class="col-md-4">
              <label>Stain</label>
            </div>
            <div class="col-md-8">
              <mat-select multiple class="ghead_filter" [(ngModel)]="stainsF" (click)="$event.stopPropagation();">
                <mat-option *ngFor="let stain of studyStainList" [title]="stain.stain" [value]="stain.stain">{{
                  stain.stain }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row mtop_1">
            <div class="col-md-4">
              <label>Tissue</label>
            </div>
            <div class="col-md-8">
              <mat-select multiple class="ghead_filter" [(ngModel)]="organsF" (click)="$event.stopPropagation();">
                <mat-option *ngFor="let organ of studyOrganList" [title]="organ.organId" [value]="organ.organ">{{
                  organ.organ }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row mtop_1">
            <div class="col-md-4">
              <label>Subject Id</label>
            </div>
            <div class="col-md-8">
              <mat-select multiple class="ghead_filter" [(ngModel)]="animalsF" (click)="$event.stopPropagation();">
                <mat-option *ngFor="let animal of studyAnimalList" [title]="animal.animalNo" [value]="animal.animalNo">
                  {{ animal.animalNo }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row mtop_1" *ngIf="galleryFilter?.isTCRStudy">
            <div class="col-md-4">
              <label>Type</label>
            </div>
            <div class="col-md-8">
              <mat-select multiple class="ghead_filter" [(ngModel)]="controlF" (click)="$event.stopPropagation();">
                <mat-option *ngFor="let control of slideControl" [title]="control.optionId" [value]="control.optionId">
                  {{ control.optionName }}
                </mat-option>
              </mat-select>
            </div>
          </div> -->
            <div class="row filterbtn">
              <div class="col-md-3"></div>
              <div class="col-md-9 filterbtn">
                <button type="button" class="btn-default searchbtn" aria-label="applybtn"
                  (click)="getAccordionData(true)">Apply</button>
                <button type="button" class="btn-default" aria-label="cancelbtn" (click)="toggleFilter()">Close</button>
                <button type="button" class="btn-default" aria-label="resetbtn" (click)="resetFilter()">Reset</button>
              </div>
            </div>
            <!-- </article> -->
          </mat-menu>
        </section>
      </div>

      <label *ngIf="activeMode == 'image-viewer'" class="viewby filterBarItems">View: </label>
      <!-- image viewer quick filter view-->
      <mat-form-field *ngIf="activeMode == 'image-viewer'&& dialogData && dialogData.dialogType == 'filter'"
        class="commonMatFormField myStudyDropDown" appearance="outline">
        <mat-select [(ngModel)]="quickFilterView" (selectionChange)="getAccordionData(true)">
          <mat-option value="-2">All Tissues</mat-option>
          <mat-option value="-1">All Subject Ids</mat-option>
          <mat-option value="viewerCustom">Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- image viewer compare view-->
      <mat-form-field *ngIf="activeMode == 'image-viewer'&& dialogData && dialogData.dialogType == 'compare'"
        class="gselect">
        <mat-select *ngIf="!dialogData.studyTypeTCR" [(ngModel)]="quickFilterView"
          (selectionChange)="getAccordionData(true)">
          <mat-option value="CD">Control Group</mat-option>
          <mat-option *ngFor="let dosage of dosageArr" [value]="dosage.dosageCode">{{dosage.dosageCode}}</mat-option>
          <mat-option value="viewerCustom">Custom</mat-option>
        </mat-select>
        <mat-select *ngIf="dialogData.studyTypeTCR" [(ngModel)]="quickFilterView"
          (selectionChange)="getAccordionData(true)">
          <mat-option *ngFor="let control of slideControl"
            [value]="control.optionId">{{control.optionName}}</mat-option>
          <mat-option value="viewerCustom">Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="viewByDiv">
        <!-- <label
          *ngIf="((activeMode != 'image-viewer' && activeMode != 'scanned') || (activeMode == 'image-viewer' && quickFilterView == 'viewerCustom'))"
          class="viewby filterBarItems">View by: </label> -->
        <!-- gallery and mapped view by -->
        <mat-form-field
          *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer' && quickFilterView == 'viewerCustom') || (activeMode == 'consultation')|| (activeMode == 'consultation-viewer'))"
          class="commonMatFormField myStudyDropDown" appearance="outline">
          <mat-label>View by</mat-label>
          <mat-select [(ngModel)]="groupBy" (selectionChange)="getAccordionData(true)">
            <mat-option value="5">Subject Id</mat-option>
            <mat-option value="3">Tissue</mat-option>
            <mat-option *ngIf="!galleryFilter?.isTCRStudy" value="1">Dosage</mat-option>
            <mat-option *ngIf="!galleryFilter?.isTCRStudy" value="0">Cassette</mat-option>
            <mat-option value="2">Sex</mat-option>
            <mat-option *ngIf="galleryFilter?.isTCRStudy" value="6">Target Antigen</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="activeMode == 'gallery'" class="commonMatFormField myStudyDropDown" appearance="outline">
        <mat-label>Severity Grade</mat-label>
        <mat-select [(ngModel)]="filterBySeverityGrading" (selectionChange)="getAccordionData(true)">
          <mat-option value="Show All">Show All</mat-option>
          <mat-option *ngFor="let indvGradingGrade of currentStudyGradingGrade" [value]="indvGradingGrade.severityCode">
            {{indvGradingGrade.severityCode + ' - ' +indvGradingGrade.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Uploaded viewby start -->
      <mat-form-field *ngIf="activeMode == 'uploaded'" class="commonMatFormField myStudyDropDown" appearance="outline">
        <mat-select [(ngModel)]="groupBy" (selectionChange)="getOrganisedData('')">
          <mat-option value="week">Weekly</mat-option>
          <mat-option value="month">Monthly</mat-option>
          <mat-option value="quarter">Quarterly</mat-option>
          <mat-option value="year">Yearly</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Uploaded viewby end -->

      <!-- read review filter start-->
      <mat-form-field *ngIf="activeMode == 'gallery'" class="commonMatFormField myStudyDropDown" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="readReviewFlag" (selectionChange)="getAccordionData(true)">
          <mat-option value="">Show All</mat-option>
          <mat-option value="READ">Read</mat-option>
          <mat-option value="UNREAD">Unread</mat-option>
          <mat-option value="LATER">Review Later</mat-option>
          <mat-option value="DONE">Review Done</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- read review filter end -->
    </ng-container>
  </div>
  <div class="galleryHeaderRightDiv">
    <!-- Refresh Icon Place it Here -->
    <img *ngIf="activeMode == 'uploaded'" (click)="refreshListData()"
      [src]="'../../../' + appConfig.config.appBaseName + 'assets/images/viewer_icon/Sync-Active.svg'"
      alt="Refresh List" aria-label="Refresh List" class="uploadicon" />
    <img *ngIf="activeMode == 'mapped'" (click)="getSlidesData()"
      [src]="'../../../' + appConfig.config.appBaseName + 'assets/images/viewer_icon/Sync-Active.svg'"
      alt="Refresh List" aria-label="Refresh List" class="uploadicon" />

    <!-- <i class="fa fa-eye righticon" (click)="changePreview()" value="Show/Hide" title="Show/Hide Preview"
      *ngIf="(activeMode != 'image-viewer' && activeMode != 'consultation' && slidesData != 0)"></i> -->
    <i class="fa fa-th righticon listviewicon linktr" aria-hidden="true" (click)="switchGridList()"
      *ngIf="activeMode == 'gallery' && slidesData != 0 && galleryFilter?.viewType == 'list'" title="Grid View"></i>
    <i class="fa fa-th-list righticon listviewicon linktr" aria-hidden="true" (click)="switchGridList()"
      *ngIf="activeMode == 'gallery' && slidesData != 0 && galleryFilter?.viewType == 'grid' && !galleryService.isStudyRandomised"
      title="List View"></i>

    <div class="dropdown float-right sharebtndrop"
      *ngIf="(activeMode == 'gallery' && galleryService.selectedSlidesArr.length > 0 ) && !galleryService.isStudyRandomised">
      <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink1" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" style="padding: .3vw 0.5vw;">
        <mat-icon class="iconflip">reply</mat-icon>
      </a>
      <div class="dropdown-menu reviewreadmenu" aria-labelledby="dropdownMenuLink1">
        <a class="dropdown-item" (click)="changeReadStatus('READ')" href="javascript:void(0)">Mark as Read</a>
        <a class="dropdown-item" (click)="changeReadStatus('UNREAD')" href="javascript:void(0)">Mark as Unread</a>
        <a class="dropdown-item" (click)="changeReviewStatus('LATER')" href="javascript:void(0)">Review Later</a>
        <a class="dropdown-item" (click)="changeReviewStatus('DONE')" href="javascript:void(0)">Review Done</a>
      </div>
    </div>
    <button type="button" class="btn-default searchbtn filterBarItems " aria-label="splitbtn "
      *ngIf="activeMode=='consultation' || activeMode == 'consultation-viewer'" (click)="addImages()">Add
      Images</button>

    <button type="button" class="btn-default searchbtn filterBarItems" aria-label="splitbtn" (click)="openSplitView()"
      *ngIf="(activeMode == 'gallery' || (activeMode == 'image-viewer' && dialogData.dialogType == 'compare')) && !galleryService.isStudyRandomised"
      [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length < 2)}">Compare</button>

    <button type="button" class="btn-default searchbtn filterBarItems" aria-label="splitbtn"
      (click)="changeStudyStatus(this.selectedStudyProj,'COMPLETED')"
      *ngIf="studyProjectList.length && activeMode == 'gallery' && !galleryService.isStudyRandomised">Complete</button>

    <ng-container *ngIf="appConfig.config?.isRandomizationEnabled">
      <button type="button" class="btn-default searchbtn filterBarItems" (click)="startRandomization()"
        *ngIf="(activeMode == 'gallery') && !galleryService.isStudyRandomised"
        [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length == 0)}">Start Randomisation
        ({{galleryService.selectedSlidesArr.length}})</button>


      <button type="button" class="btn-default searchbtn filterBarItems" (click)="openRandomizedImaeDetailPopup()"
        *ngIf="(activeMode == 'gallery') && galleryService.isStudyRandomised">Verify</button>

      <button type="button" class="btn-default searchbtn filterBarItems" (click)="stopRandomization()"
        *ngIf="(activeMode == 'gallery') && galleryService.isStudyRandomised">Stop
        Randomisation</button>
    </ng-container>

    <!-- scanned module buttons -->
    <!-- Map Image -->
    <button *ngIf="mappingStatus" class="btn-default searchbtn filterBarItems" type="button" disabled>
      <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
      <span>Mapping Image(s)</span>
    </button>
    <button type=" button " class="btn-default searchbtn filterBarItems " aria-label="sharebtn "
      *ngIf="activeMode=='uploaded' && !mappingStatus" (click)="openMapDialog() "
      [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length!=1 ), 'noVisibility' : previewP} "> Map
      Image</button>
    <!-- Discard Image -->
    <button *ngIf="discardingStatus" class="btn-default searchbtn filterBarItems" type="button" disabled>
      <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
      <span>Discarding Image(s)</span>
    </button>
    <button type="button " class="btn-default searchbtn filterBarItems " aria-label="splitbtn "
      *ngIf="activeMode=='uploaded' && !discardingStatus" (click)="openDiscardDialog() "
      [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length==0 ), 'noVisibility' : previewP} ">Discard
      Image</button>
    <!-- Reject Image -->
    <button *ngIf="unmappingStatus" class="btn-default searchbtn filterBarItems" type="button" disabled>
      <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
      <span>UnMapping Image(s)</span>
    </button>
    <button type="button " class="btn-default searchbtn filterBarItems " aria-label="splitbtn "
      *ngIf="activeMode=='mapped' && !unmappingStatus" (click)="openBulkUnMapDialog() "
      [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length==0 || !unmapFlag )} ">Reject
      Image</button>
    <!-- Thumbnail Generation-->
    <button *ngIf="thumbnailGenStatus" class="btn-default searchbtn filterBarItems" type="button" disabled>
      <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
      <span>Generating Thumbnail...</span>
    </button>
    <button type="button " class="btn-default searchbtn filterBarItems " aria-label="splitbtn "
      *ngIf="activeMode=='mapped' && !thumbnailGenStatus" (click)="generateNewThumbnails() "
      [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length == 0 )} ">
      Generate Thumbnail</button>
  </div>
</div>