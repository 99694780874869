import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { Router } from '@angular/router';
import { CommonService } from '../../common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-gallerylist',
  templateUrl: './gallerylist.component.html',
  styleUrls: ['./gallerylist.component.css']
})
export class GallerylistComponent implements OnInit, OnDestroy {
  gridList
  preview
  galleryList: any = {};
  galleryList1: any = [];
  // selectedSlides: any = [];
  selectgroupSlideKey: String = '';
  selectedGroup: String;
  @Input() activeMode: string;
  @Input() dialogData: any;
  showLoaderScannedList: boolean = true;
  openCollapseIndex = -1;
  galleryFilter: any;
  fetchingNewData: boolean = false;
  studyOrganObj = {};
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public galleryService: GalleryService, private router: Router, private commonService: CommonService, public appConfig: AppConfigService, private dialog: MatDialog) { }

  ngOnInit() {
    this.galleryService.gridList.pipe(takeUntil(this.destroy$)).subscribe((gridList) => {
      this.gridList = gridList;
    });
    this.galleryService.preview.pipe(takeUntil(this.destroy$)).subscribe((preview) => {
      this.preview = preview;
    });
    /* this.galleryService.selecteSlidesList.pipe(takeUntil(this.destroy$)).subscribe((slides) => {
      this.selectedSlides = slides;
    }); */
    this.galleryService.studyOrganObj.pipe(takeUntil(this.destroy$)).subscribe((organObj) => {
      this.studyOrganObj = organObj;
    });
    this.galleryService.galleryList.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.galleryList = value;
      this.galleryList1 = value;
      this.galleryFilter = this.commonService.ProjectLSData?.galleryFilters;
      if (!$.isEmptyObject(value)) {
        if (this.galleryFilter.selectedAccordion) {
          this.openCollapseIndex = Object.keys(value).indexOf(this.galleryFilter.selectedAccordion);
        } else {
          this.openCollapseIndex = ((this.galleryService.activeAccordian > (Object.keys(value).length - 1)) || this.galleryService.activeAccordian == -1) ? 0 : this.galleryService.activeAccordian;
        }

        if (this.openCollapseIndex == -1) {
          this.openCollapseIndex = 0;
        }
        this.galleryService.selectgroupSlideKey.pipe(takeUntil(this.destroy$)).subscribe((key) => {
          this.selectgroupSlideKey = key;
          setTimeout(() => {
            $("tr.active")[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 100);
        });
        if (this.activeMode == 'scanned') {
          this.openCollapseIndex = 0;
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].alreadyrender = true;
          this.setScannedSlideData(this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].id, this.openCollapseIndex);
        } else {
          if (this.openCollapseIndex < 0) {
            return;
          }
          this.galleryService.assignGroupCount(this.galleryService.selectedSlidesArr, this.activeMode, this.galleryFilter, this.galleryList);

          if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].alreadyrender) {
            this.getPageWiseData(Object.keys(this.galleryList)[this.openCollapseIndex], 1);
          } else {
            if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].selectedSlidesIds) {
              this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].selectedSlidesIds = [];
            }
            if (this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr) {
              this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr.forEach(element => {
                element.state = false;
                element.isDisabled = false;
              });
            }
          }
        }

        setTimeout(() => {
          if ($("#heading_" + this.openCollapseIndex).length > 0) {
            $(".gridscroll").animate({
              scrollTop: ($("#heading_" + this.openCollapseIndex).offset().top - $(".gridscroll").offset().top)
            }, 200);
          }
        }, 100);
      } else {
        // console.log("no data error handling in galleery list");
      }
    });
    if (this.activeMode == 'image-viewer') {
      this.galleryService.viewerSearch.pipe(takeUntil(this.destroy$)).subscribe(val => {
        this.galleryList = Object.assign({}, this.galleryList1);
        if (val) {
          for (const key in this.galleryList) {
            this.galleryList[key] = Object.assign({}, this.filterSearchData(val, this.galleryList[key]));
            if (this.galleryList[key].slidesArr.length == 0) {
              delete this.galleryList[key];
            }
          }
          this.galleryService.activeAccordian = 0;
          this.openCollapseIndex = 0;
          this.galleryList[Object.keys(this.galleryList)[0]].alreadyrender = true;
        }
      });
    }
    this.galleryService.updateslideData.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        for (const key in this.galleryList) {
          this.galleryList[key].slidesArr.forEach(element => {
            let selectedSlideIds: any = this.galleryService.selectedSlidesArr.map((indvItem) => { return indvItem.slidePk });
            if (selectedSlideIds.indexOf(element.slidePk) != -1) {
              if (value == 'READ' || value == 'UNREAD') {
                element.readStatus = value;
                element.state = false;
              } else if (value == 'LATER' || value == 'DONE') {
                element.reviewStatus = value;
                element.state = false;
              }
            }
          });
        }
        // this.galleryService.setSelecteSlidesList([]);
        this.galleryService.setupdateslideData('');
      }
    });
  }

  filterSearchData(filterVal, arrayElem) {
    let a = Object.assign({}, arrayElem);
    a.slidesArr = arrayElem.slidesArr.filter(elem => {
      return (elem.animalNo.toLowerCase().includes(filterVal.toLowerCase()) ||
        elem.dosageLevel.toLowerCase().includes(filterVal.toLowerCase()) ||
        elem.sex.toLowerCase().includes(filterVal.toLowerCase()) ||
        elem.organ.toString().toLowerCase().includes(filterVal.toLowerCase()))
    });
    return a;
  }

  openViewer(slideInfo: any, groupIn, selectedAccordionText) {
    this.galleryService.getSlideDetailsArr([slideInfo.slidePk]).subscribe((successData: any) => {
      let slideIds: any = [];
      for (let x = 0; x < successData.data.length; x++) {
        successData.data[x].id = successData?.data[x].slide.slidePk;
        successData.data[x].identifier = successData?.data[x].slide.identifier;
        successData.data[x].slidename = successData?.data[x]?.slide.slidename;
        slideIds.push(successData.data[x].id);

        this.galleryService.changeOpenStatus(successData.data[x].id, { "opened": true }).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        });
      }
      this.commonService.setViewerOpenedFrom(this.activeMode);
      this.commonService.ProjectLSData['ViewerSlides'] = successData.data;

      // this.galleryFilter.selectedAccordion = selectedAccordionText;
      this.galleryService.isCalledFromViewer = true;
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
      // this.router.navigate(['WebViewer/' + slideIds.join()]);
      this.router.navigate(['WebViewer']);

      // this.galleryService.setSelecteSlidesList([]);
    });
  }

  // openViewer1(slideInfo: any, groupIn, selectedAccordionText) {
  //   if (this.activeMode == 'image-viewer') {
  //     this.dialog.closeAll();
  //   } else {
  //     this.commonService.setViewerOpenedFrom(this.activeMode);
  //   }

  //   slideInfo.id = slideInfo.slidePk;
  //   this.commonService.ProjectLSData['ViewerSlides'] = [slideInfo];

  //   this.galleryFilter.selectedAccordion = selectedAccordionText;
  //   this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
  //   this.commonService.setProjectLSData();

  //   /* var slides = this.galleryList[Object.keys(this.galleryList)[groupIn]].slidesArr.map((value) => {
  //     return {
  //       'slidePk': value.slidePk,
  //       'identifier': value.identifier,
  //     }
  //   }); */
  //   // let slides: any = this.galleryList[Object.keys(this.galleryList)[groupIn]].slidesArr;
  //   // this.commonService.setSelectedGroupSlides(JSON.stringify(slides));

  //   this.galleryService.changeOpenStatus(slideInfo.slidePk, { "opened": true }).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
  //   });

  //   if (this.dialogData && this.dialogData.dialogType == 'compare') {
  //     let temp_arr = [];
  //     let stored_samples = this.commonService.ProjectLSData.samples;
  //     if (stored_samples) {
  //       for (var i in stored_samples) {
  //         temp_arr.push(stored_samples[i]);
  //       }
  //     }
  //     // for(var i in this.selectedSlides) {
  //     temp_arr.push(slideInfo.slidePk);
  //     // }
  //     let uniqueArray = temp_arr.filter(function (item, pos) {
  //       return temp_arr.indexOf(item) == pos;
  //     });
  //     // this.router.navigate(['WebViewer/' + uniqueArray.join()]);
  //     this.router.navigate(['WebViewer']);
  //   } else {
  //     // this.router.navigate(['WebViewer/' + slideInfo.slidePk]);
  //     this.router.navigate(['WebViewer']);
  //   }
  //   this.galleryService.setSelecteSlidesList([]);
  //   this.dialog.closeAll()
  // }

  setSelectedSlideList(slideD, checkedValue, childIndex = -1, key = '') {
    let selectedSlideIds: any = this.galleryService.selectedSlidesArr.map((indvItem) => { return indvItem.slidePk });
    if (checkedValue) {
      if (!selectedSlideIds.includes(slideD.slidePk)) {
        /* if (childIndex != -1) {
          this.galleryList[key].slidesArr[childIndex].state = true;
        } */
        this.galleryService.selectedSlidesArr.push(slideD);
        this.galleryService.assignGroupCount([slideD], this.activeMode, this.galleryFilter, this.galleryList);
      }
    } else {
      /* this.selectedSlides = this.selectedSlides.filter(e => e !== slideD.slidePk)
      if (childIndex != -1) {
        this.galleryList[key].slidesArr[childIndex].state = false;
      } */
      this.galleryService.selectedSlidesArr = this.galleryService.selectedSlidesArr.filter(e => (e.slidePk !== slideD.slidePk));
      this.galleryService.assignGroupCount([slideD], this.activeMode, this.galleryFilter, this.galleryList, true);
    }
    // this.galleryService.setSelecteSlidesList(this.selectedSlides);
  }

  isSelectedSample(slide) {
    let storedSamples: any = this.commonService.ProjectLSData.samples;
    if (storedSamples) {
      let sample_data = storedSamples;
      if (sample_data.length > 0) {
        if (sample_data.includes(slide.toString())) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setTitleDivWidth() {
    $(".prevTitleDiv").width($(".previewaction_btn").width() - ($(".previewaction_btn .leftBtn").width() + $(".previewaction_btn .rightBtn").width() + this.commonService.vwTOpx('2.2vw') + (2 * $(".previewimgdet .prev").width())));
    $("tr.active")[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  groupChange(group, slideHeaderText) {
    if (this.activeMode == 'scanned') {
      let currentfolder = this.galleryList[Object.keys(this.galleryList)[group]];
      if (!currentfolder.alreadyrender) {
        this.setScannedSlideData(currentfolder.id, group);
      }
    }
    if (this.galleryService.activeAccordian == group) {
      this.galleryService.activeAccordian = -1;
      this.openCollapseIndex = -1;
    } else {
      this.galleryService.activeAccordian = group;
      this.openCollapseIndex = group;
      if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].alreadyrender) {
        this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].alreadyrender = true;
        if (this.activeMode != 'scanned') {
          this.getPageWiseData(Object.keys(this.galleryList)[this.openCollapseIndex], 1)
        }
      }
    }

    this.galleryFilter.selectedAccordion = slideHeaderText;
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
  }

  getPageWiseData(searchby, pageNo) {
    this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
    let orderby, stain = [], tissue = [], subjectid = [], dosage = [], controlDosage = false, typeControl = [];
    if (this.activeMode == 'image-viewer' && ((this.galleryFilter.quickFilter == -1) || (this.galleryFilter.quickFilter == "-2"))) {
      if (this.galleryFilter.quickFilter == -1) {
        orderby = 'tissue';
        tissue = this.dialogData.organArr;
      } else {
        orderby = 'subjectId';
        subjectid = [this.dialogData.animalId]
      }
    } else if (this.activeMode == 'image-viewer' && this.galleryFilter.quickFilter == "CD") {
      orderby = 'dosage';
      controlDosage = true;
      searchby = '';
    } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && !this.commonService.ProjectLSData.isTCRStudy && this.galleryFilter.quickFilter != 'viewerCustom') {
      orderby = 'dosage';
    } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && this.commonService.ProjectLSData.isTCRStudy && this.galleryFilter.quickFilter != 'viewerCustom') {
      orderby = 'subjectId';
      typeControl = [this.galleryFilter.quickFilter];
      searchby = '';
    } else {
      orderby = this.galleryService.getGroupByString(this.galleryFilter.viewByG);
      tissue = this.galleryFilter.organ;
      stain = this.galleryFilter.stains;
      subjectid = this.galleryFilter.animalNo;
      typeControl = this.galleryFilter.control;
      dosage = this.galleryFilter.dosageCode;
      /* if (this.galleryFilter.viewByG == 3) {   //added to send tissue id in filter
        searchby = Object.keys(this.studyOrganObj).find(key => this.studyOrganObj[key] === searchby);
      } */
    }
    let searchData = {
      "mainFilter": searchby ? [searchby] : [],
      "subfilters": {
        "tissue": tissue,
        "cassetteStain": stain,
        "subjectId": subjectid,
        "tcrSlideControl": typeControl,
        "dosage": dosage
      },
      "dosageControlGroup": controlDosage,
      "slidesMapped": this.galleryService.showOnlyMappedSlides,
      "viewByMore": this.galleryService.getReviewFilterString(this.galleryFilter.readReviewFlag),
      "severityGrading":this.galleryFilter.filterBySeverityGrading
    }
    this.galleryService.setSearchData(searchData);
    this.fetchingNewData = true;
    this.galleryService.getFilteredSlideData(this.galleryFilter.studyName, searchData, orderby, pageNo).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]]['alreadyrender'] = true;
      this.fetchingNewData = false;
      res.data.content.forEach(element => {
        /* element.state = $('.selectAll_' + this.openCollapseIndex).is(":checked");
        element.isDisabled = $('.selectAll_' + this.openCollapseIndex).is(":checked"); */
        element.organName = [];
        element.organ.forEach(organElem => {
          element.organName.push(this.studyOrganObj[organElem]);
        });
        if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr) {
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr = [];
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr.push(element);
        } else {
          if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr.some(person => person.slidePk === element.slidePk)) {
            this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr.push(element);
          }
        }

        if (!this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].pageInfo) {
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].pageInfo = {};
        }
        this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].pageInfo = res.data.pageInfo;
      });
      if (this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr) {
        this.galleryService.setGalleryDataArrlength(this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].slidesArr.length);
      }
    });
  }

  setScannedSlideData(id, group) {
    this.showLoaderScannedList = true;
    this.galleryService.getSlidesListFolderLocation(id).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.showLoaderScannedList = false;
      if (response.success) {
        this.galleryList[Object.keys(this.galleryList)[group]].slidesArr = response.data[0].slides;
      } else {
        this.galleryList[Object.keys(this.galleryList)[group]].slidesArr = [];
      }
    });
  }

  returnZero() {
    return 0;
  }

  openPreview(groupSlideKey) {
    this.galleryService.changePreviewSlide(groupSlideKey);
    this.galleryService.changePreview(true);
    setTimeout(this.setTitleDivWidth.bind(this), 200);
  }

  checkAll(ev, groupArr, searchKey) {
    // groupArr.forEach((x, index) => {
    //   x.state = ev.target.checked;
    //   x.isDisabled = ev.target.checked;
    //   //this.setSelectedSlideList(index, x, ev.target.checked);
    // });
    //if (ev.target.checked) {
    this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;

    let findData = this.galleryService.getSearchData();
    findData['mainFilter'] = findData['mainFilter'] ? [searchKey] : [];
    /* this.galleryService.getListOfAllSlideIdsInAccordion(this.galleryFilter.studyName, this.galleryService.getGroupByString(this.galleryFilter.viewByG), findData)
      .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        res.forEach(element => {
          let resObj = {
            slidePk: element
          }
          this.setSelectedSlideList(resObj, ev.target.checked);
        });
        if (ev.target.checked) {
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].selectedSlidesCount = res.length;
        } else {
          this.galleryList[Object.keys(this.galleryList)[this.openCollapseIndex]].selectedSlidesCount = 0;
        }
      }) */
    groupArr.forEach((indvItem, index) => {
      // indvItem.state = ev.target.checked;
      // indvItem.isDisabled = ev.target.checked;
      this.setSelectedSlideList(indvItem, ev.target.checked);
    })
    /* } else {
      this.selectedSlides = [];
      this.galleryService.setSelecteSlidesList(this.selectedSlides);
    } */
  }

  isAllChecked(groupArr) {
    if (groupArr && groupArr.length > 0) {
      return groupArr.every(_ => _.state);
    }
  }

  onTableScroll(e, collapsekey) {
    const tableViewHeight = e.target.offsetHeight // viewport
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && !this.fetchingNewData && this.galleryList[collapsekey].pageInfo.more) {
      this.getPageWiseData(collapsekey, this.galleryList[collapsekey].pageInfo.pageNo + 1);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
