import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loaderMsgText:string = 'Fetching data from server...';
  constructor(@Optional() private dialogRef: MatDialogRef<LoaderComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data?.Msg){
      this.loaderMsgText = this.data?.Msg;
    }
  }

  ngOnInit(): void {
  }

}
