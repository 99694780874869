<!--Create Study Details-->
<div class="pageheader">
    <div class="pageheadtitle">
        <h4> Study
        </h4>
    </div>
    <!-- <div class="float-right">
        <button *ngIf="!isStudyEdit" type="button" class="btn-default searchbtn"
            (click)="autoCreateDialog()">Auto-Create Study</button>
    </div> -->
</div>
<div class="cont">
    <div class="progress" id="step">
        <div class="progress-bar arrow_box first border-change activeTab " role="progressbar"
            [style.width]="(studyCreateType != 'TCR' ? (autoCreateStudy || showSlideTab?'25%':'33.33%') : (autoCreateStudy || showSlideTab) ? '50%' : '100%')"
            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Study</div>
        <!-- <div class="progress-bar second" *ngIf="studyCreateType != 'TCR' || ( studyCreateType == 'TCR' && (!autoCreateStudy && !isStudyEdit))" role="progressbar"
            [style.width]="autoCreateStudy || showSlideTab?'25%':'33.33%'" aria-valuenow="30" aria-valuemin="0"
            aria-valuemax="100">Cassettes</div>
        <div class="progress-bar third" *ngIf="studyCreateType != 'TCR' || ( studyCreateType == 'TCR' && (!autoCreateStudy && !isStudyEdit))" role="progressbar"
            [style.width]="autoCreateStudy || showSlideTab?'25%':'33.33%'" aria-valuenow="20" aria-valuemin="0"
            aria-valuemax="100">Dosages</div> -->
        <div class="progress-bar second" *ngIf="studyCreateType != 'TCR'" role="progressbar"
            [style.width]="autoCreateStudy || showSlideTab?'25%':'33.33%'" aria-valuenow="30" aria-valuemin="0"
            aria-valuemax="100">Cassettes</div>
        <div class="progress-bar third" *ngIf="studyCreateType != 'TCR'" role="progressbar"
            [style.width]="autoCreateStudy || showSlideTab?'25%':'33.33%'" aria-valuenow="20" aria-valuemin="0"
            aria-valuemax="100">Dosages</div>
        <div class="progress-bar forth" *ngIf="autoCreateStudy || showSlideTab" role="progressbar"
            [style.width]="(studyCreateType != 'TCR' ? (autoCreateStudy || showSlideTab?'25%':'33.33%') : (autoCreateStudy || showSlideTab) ? '50%' : '0')"
            aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">Slides</div>
    </div>
    <div class="tab_data">
        <!-- <mat-spinner style="margin:0 auto;" *ngIf="!isStudyDataReady"></mat-spinner> -->
        <form [formGroup]="studyFrom" *ngIf="isStudyDataReady">
            <div id="first">
                <div class="studyTabView">
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Study No.<span class="mandatory">*</span></mat-label>
                            <input matInput formControlName="studyNo" [readonly]="isStudyEdit" maxlength="15" />
                            <mat-error>{{studyF.studyNo.invalid | inputError:'studyNo' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Study title</mat-label>
                            <input matInput formControlName="studyTitle" maxlength="50" />
                            <!-- <mat-error>{{studyF.studyTitle.invalid | inputError:'studyTitle' | translate}}</mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isProjectChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Project No.</mat-label>
                            <input matInput formControlName="projectNo" maxlength="15" />
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isAccessionChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Accession No.</mat-label>
                            <input matInput formControlName="accessionNo" maxlength="15" />
                        </mat-form-field>
                    </div>
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Pathologist<span class="mandatory">*</span></mat-label>
                            <mat-select class="pathoSelect" formControlName="pathologist">
                                <mat-option *ngFor="let patho of pathologists" [value]="patho.userName"
                                    [disabled]="(studyFrm.peerReviewer.value == patho.userName || studyFrm.additionalPathologist.value.includes(patho.userName))">
                                    {{ patho.displayName }}
                                </mat-option>
                            </mat-select>
                            <mat-error>{{studyF.pathologist.invalid | inputError:'pathologists' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Technician<span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="technician">
                                <mat-option *ngFor="let tech of technicians" [value]="tech.userName">
                                    {{ tech.displayName }}
                                </mat-option>
                            </mat-select>
                            <mat-error>{{studyF.technician.invalid | inputError:'technician' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isSelectPeerReviewerChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Peer Reviewer</mat-label>
                            <mat-select class="peerSelect" formControlName="peerReviewer">
                                <mat-option *ngFor="let peerRev of peerReviewers" [value]="peerRev.userName"
                                    [disabled]="(studyFrm.pathologist.value == peerRev.userName || studyFrm.additionalPathologist.value.includes(peerRev.userName))">
                                    {{ peerRev.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isSelectPrimaryInvestigatorChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Primary Investigator</mat-label>
                            <mat-select formControlName="primaryInvestigator">
                                <mat-option *ngFor="let patho of pathologists" [value]="patho.userName">
                                    {{ patho.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isStudyDomainChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Study Domain</mat-label>
                            <mat-select formControlName="studyDomain">
                                <mat-option *ngFor="let sd of studyDomains" [value]="sd.studyDomain">
                                    {{ sd.studyDomain }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isTreatmentChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Treatment</mat-label>
                            <mat-select formControlName="treatment">
                                <mat-option *ngFor="let tmts of treatments" [value]="tmts.treatment">
                                    {{ tmts.treatment }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isStudyTypeChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Study Type</mat-label>
                            <mat-select formControlName="studyType">
                                <mat-option *ngFor="let st of studyTypes" [value]="st.studyType">{{ st.studyType }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isDurationChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Duration</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="From" formControlName="fromDate">
                                <input matEndDate placeholder="To" formControlName="toDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isSacrificeChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Sacrifice</mat-label>
                            <input matInput formControlName="sacrifice" maxlength="25" />
                        </mat-form-field>
                    </div>
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Species <span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="species">
                                <!-- (selectionChange)="getStrains($event.value)" -->
                                <mat-option *ngFor="let sp of species" [value]="sp.species">{{ sp.species }}
                                </mat-option>
                            </mat-select>
                            <mat-error>{{studyF.species.invalid | inputError:'species' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Strain</mat-label>
                            <input matInput formControlName="strain" maxlength="25" />
                            <!-- <mat-error>{{studyF.strain.invalid | inputError:'strain' | translate}}</mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isRouteChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Route</mat-label>
                            <mat-select formControlName="route">
                                <mat-option *ngFor="let r of routes" [value]="r.route">{{ r.route }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isCROChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">CRO</mat-label>
                            <input matInput formControlName="cro" maxlength="25" />
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isTestItemChecked">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Test Item</mat-label>
                            <input matInput formControlName="testItem" maxlength="25" />
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isAdditionalPathologist">
                        <mat-form-field class="commonMatFormField" appearance="outline">
                            <mat-label class="labelbg">Additional Pathologists</mat-label>
                            <mat-select formControlName="additionalPathologist" multiple>
                                <mat-option *ngFor="let patho of secondaryPathologists" [value]="patho.userName"
                                    [disabled]="(studyFrm.peerReviewer.value == patho.userName || studyFrm.pathologist.value == patho.userName)">
                                    {{ patho.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="studyGridField" *ngIf="isAdditionalPathologist">
                        <mat-checkbox formControlName="reviewSharable" color="primary"
                            [disabled]="!studyFrm.additionalPathologist.value.length && !studyFrm.peerReviewer.value">
                            Share Annotations & Reviews?</mat-checkbox>
                    </div>
                </div>
                <div class="threebtn_study">
                    <button type="button" class="btn btn-default cancel_study" (click)="cancleStudy()"
                        *ngIf="studyCreateType != 'TCR' || (studyCreateType == 'TCR' && !isDosageNextClicked)">Cancel</button>
                    <!-- create btns -->
                    <button type="button" mat-flat-button color="primary" *ngIf="!isStudyEdit && !autoCreateStudy"
                        (click)="save('Stage1','Exit')">
                        Save &amp; Exit
                    </button>
                    <ng-container *ngIf="studyCreateType != 'TCR'">
                        <button type="button" mat-flat-button color="primary" *ngIf="!isStudyEdit && !autoCreateStudy"
                            (click)="save('Stage1','Next')">
                            Save &amp; Next
                        </button>
                    </ng-container>
                    <button type="button" mat-flat-button color="primary" *ngIf="autoCreateStudy"
                        [ngClass]="{'disableFunction': slidesArr.length == 0}" (click)="navigateNext('Stage1')">
                        Next
                    </button>
                    <!-- create btns -->

                    <!-- edit btns -->
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="isStudyEdit && (studyCreateType != 'TCR' || (studyCreateType == 'TCR' && !isDosageNextClicked))"
                        (click)="editStudy('Stage1','Exit')">
                        Save &amp; Exit
                    </button>
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="isStudyEdit && (studyCreateType != 'TCR' || (studyCreateType == 'TCR' && !isDosageNextClicked))"
                        (click)="editStudy('Stage1','Next')">
                        Save &amp; Next
                    </button>

                    <div *ngIf="isDosageNextClicked" class="float-right dosageBtn ">
                        <button class="btn-default searchbtn" type="button" disabled>
                            <span class="spinner-border spinner-border-sm"
                                style="margin-right: 0.5vw; width: 1vw; height: 1vw;" role="status"
                                aria-hidden="true"></span>
                            <span>Please wait...</span>
                        </button>
                    </div>


                    <!-- edit btns -->
                </div>
            </div>
        </form>
        <div id="second" class="cassetteMainDiv">
            <div *ngIf="isStudyDataReady">
                <div class="row headers">
                    <div class="col-md-2 col-lg-2 col-xl-2" style="display: inherit;">
                        <span class="headersPara" style="margin-right: 1vw;">#</span>
                        <p class="headersPara">Cassette Id</p>
                    </div>
                    <div class="col-md-4 col-lg-4 col-xl-4">
                        <p class="headersPara">Stain</p>
                    </div>
                    <div class="col-md-5 col-lg-5 col-xl-5">
                        <p class="headersPara">Tissues</p>
                    </div>
                    <div class="col-md-1 col-lg-1 col-xl-1">
                        <p *ngIf="!isStudyEdit || cassetteCrationInProgress" class="headersPara"
                            (click)="addNewCassette()"><i class="fa fa-plus"></i></p>
                    </div>
                </div>
                <div class="content">
                    <div class="row formData" [formGroup]="cassette"
                        *ngFor="let cassette of cassettesFormGroup.get('rows')['controls'];let i = index">
                        <div class="col-md-2 col-lg-2 col-xl-2" style="display: inherit; align-items: center;">
                            <span class="indexing">{{i+1}}</span>
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Cassette Id</mat-label> -->
                                <mat-error>{{cassette.controls.cassetteId.errors | inputError:'cassetteId' | translate}}
                                </mat-error>
                                <input matInput formControlName="cassetteId"
                                    [readonly]="isStudyEdit && !cassette.value.newCassette" maxlength="255" />

                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Stains</mat-label> -->
                                <mat-select formControlName="stains" multiple
                                    (selectionChange)="onCassetteStainChange($event, i)">
                                    <mat-select-trigger>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let stain of cassette.value.stains" [removable]="true"
                                                (removed)="remove(stain, 'stain', i)">
                                                {{ stain }}
                                                <mat-icon matChipRemove
                                                    *ngIf="cassette.value.newCassette || !fieldsToDisable[i].stains.includes(stain) ">
                                                    cancel</mat-icon>
                                                <!--  -->
                                            </mat-chip>
                                        </mat-chip-list>
                                    </mat-select-trigger>
                                    <ng-container>
                                        <mat-option *ngFor="let stain of stainsOptions" [value]="stain"
                                            [disabled]="!cassette.value.newCassette || (cassette.value.stains.indexOf('Unknown') >= 0 && (stain != 'Unknown'))">
                                            {{ stain }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error>{{cassette.controls.stains.value.length | inputError:'stains' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-5 col-lg-5 col-xl-5">
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Tissues</mat-label> -->
                                <mat-select formControlName="organs" multiple>
                                    <mat-select-trigger>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let organ of cassette.value.organs" [removable]="true"
                                                (removed)="remove(organ, 'organ', i)">
                                                {{ organ }}
                                                <mat-icon matChipRemove
                                                    *ngIf="cassette.value.newCassette || !fieldsToDisable[i].organs.includes(organ) ">
                                                    cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let organ of organOptions" [value]="organ"
                                        [disabled]="!cassette.value.newCassette">{{ organ }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{cassette.controls.organs.value.length | inputError:'organs' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div [ngClass]="[!cassette.value.newCassette ? 'wrapper' : '']"
                            class="col-md-1 col-lg-1 col-xl-1">
                            <p [ngClass]="[!cassette.value.newCassette ? 'disableCross' : 'enabledCross']"
                                *ngIf="counterCassette > 1 && (!isStudyEdit || cassetteCrationInProgress)"
                                (click)="removeCassette(i)">
                                <i class="fa fa-times"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cass_btns">
                <!-- <div class="float-left" *ngIf="!autoCreateStudy && !isStudyEdit">
                    <input type="file" class="uploaddoc_btn" style="display: none;"
                        (change)="uploadExcel($event,'Cassettes')" placeholder="Upload file" accept=".xlsx, .xls, .csv"
                        #cassettesFileInput />
                    <button type="button" class="btn-default" (click)="cassettesFileInput.click()">
                        Upload Document
                    </button>
                </div> -->
                <div class="float-right cassetteBtn">
                    <button type="button" class="btn btn-default" (click)="previous('Stage1')">Previous</button>
                    <!-- study save btns -->
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="(!isStudyEdit || cassetteCrationInProgress) && !autoCreateStudy"
                        (click)="save('Stage2','Exit')">
                        Save &amp; Exit
                    </button>
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="(!isStudyEdit || cassetteCrationInProgress) && !autoCreateStudy"
                        (click)="save('Stage2','Next')">
                        Save &amp; Next
                    </button>
                    <button type="button" mat-flat-button color="primary" *ngIf="autoCreateStudy"
                        (click)="navigateNext('Stage2')">
                        Next
                    </button>
                    <!-- study save btns -->

                    <!-- study  edit btns -->
                    <!-- <button type="button" class="btn btn-default button25" *ngIf="isStudyEdit" (click)="editStudy('Stage2','Exit')">
            Save &amp; Exit
          </button>
                    <button type="button" class="btn btn-default button25" *ngIf="isStudyEdit" (click)="editStudy('Stage2','Next')">
            Save &amp; Next
          </button> -->
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="isStudyEdit && !cassetteCrationInProgress" (click)="gotoThirdStage()">
                        Next
                    </button>
                    <!-- study edit btns -->
                </div>
            </div>
        </div>
        <div id="third">
            <div *ngIf="isStudyDataReady">
                <div class="headers full-width" style="display: inline-flex;">
                    <div style="width: 15%; display: inherit;">
                        <span class="headersPara" style="margin-right: 1vw;">#</span>
                        <p class="headersPara">Dosage Group</p>
                    </div>
                    <div style="width: 10%;">
                        <p class="headersPara">Type</p>
                    </div>
                    <div style="width: 15%;">
                        <p class="headersPara">Male</p>
                    </div>
                    <div style="width: 15%;">
                        <p class="headersPara">Female</p>
                    </div>
                    <div style="width: 15%;">
                        <p class="headersPara">Not Known</p>
                    </div>
                    <div style="width: 5%;">
                        <p class="headersPara">Dose</p>
                    </div>
                    <div style="width: 10%;">
                        <p class="headersPara">Dose Unit</p>
                    </div>
                    <div style="width: 15%; display: inline-flex;">
                        <p class="headersPara" style="width: 85%;">Control Group</p>
                        <p *ngIf="!isStudyEdit || dosageCrationInProgress" class="headersPara" (click)="addNewDosage()">
                            <i class="fa fa-plus" style="margin-left: 0.5vw;"></i>
                        </p>
                    </div>
                    <!-- <div class="col-md-1 col-lg-1 col-xl-1">
                        <p class="headersPara" (click)="addNewDosage()"><i class="fa fa-plus"></i></p>
                    </div> -->
                </div>
                <div class="content">
                    <div class="formData dosageForm full-width" style="display: inline-flex;" [formGroup]="dosageF"
                        *ngFor="let dosageF of dosageFormGroup.get('rows')['controls'];let i = index;">
                        <div style="width: 15%; display: inherit;">
                            <span class="indexing">{{i+1}}</span>
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Dosage Group</mat-label> -->
                                <mat-select formControlName="dosageCode"
                                    [disabled]="(isStudyEdit && !dosageF.value.newDosage)">
                                    <ng-container *ngFor="let d of dosageOptions">
                                        <mat-option *ngIf="studyCreateType != 'TCR' && d.code != 'TCR'"
                                            [value]="d.code">{{d.description}}</mat-option>
                                        <mat-option *ngIf="studyCreateType == 'TCR' && d.code == 'TCR'"
                                            [value]="d.code">{{d.description}}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error>{{dosageF.controls.dosageCode.errors | inputError:'dosageCode' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div style="width: 10%;">
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Type</mat-label> -->
                                <mat-select formControlName="animalSequenceType"
                                    (selected)='dosageF.value.animalSequenceType'
                                    [disabled]="(isStudyEdit && !dosageF.value.newDosage)">
                                    <mat-option *ngFor="let t of typeList" [value]="t">{{t}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    {{dosageF.controls.animalSequenceType.invalid | inputError:'type' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div [formGroup]="dosageF.controls.animals" style="width: 15%;">
                            <!--  Male Range and  custom inputs starts -->
                            <div *ngIf="dosageF.value.animalSequenceType == 'RANGE'">
                                <mat-form-field class="half-width gselect bgremove">
                                    <!-- <mat-label class="labelbg">From</mat-label> -->
                                    <input matInput formControlName="maleStartIndex"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.maleStartIndex.invalid |
                                        inputError:'range_male_from' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <span style="font-weight: 20px;">-</span>
                                <mat-form-field class="half-width gselect bgremove">
                                    <!-- <mat-label class="labelbg">To</mat-label> -->
                                    <input matInput formControlName="maleEndIndex"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.maleEndIndex.invalid |
                                        inputError:'range_male_to' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="dosageF.value.animalSequenceType == 'CUSTOM'">
                                <mat-form-field class="commonMatFormField" appearance="outline">
                                    <!-- <mat-label class="labelbg">Comma seperated number</mat-label> -->
                                    <input matInput formControlName="maleList"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.maleList.invalid | inputError:'custom_male'
                                        | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--  Male Range and  custom inputs ends -->
                        </div>
                        <div [formGroup]="dosageF.controls.animals" style="width: 15%;">
                            <!--  Female Range and  custom inputs starts -->
                            <div *ngIf="dosageF.value.animalSequenceType == 'RANGE'">
                                <mat-form-field class="half-width gselect bgremove">
                                    <!-- <mat-label class="labelbg">From</mat-label> -->
                                    <input matInput formControlName="femaleStartIndex"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.femaleStartIndex.invalid |
                                        inputError:'range_female_from' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <span>-</span>
                                <mat-form-field class="half-width gselect bgremove">
                                    <!-- <mat-label class="labelbg">To</mat-label> -->
                                    <input matInput formControlName="femaleEndIndex"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.femaleEndIndex.invalid |
                                        inputError:'range_female_to' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="dosageF.value.animalSequenceType == 'CUSTOM'">
                                <mat-form-field class="commonMatFormField" appearance="outline">
                                    <!-- <mat-label class="labelbg">Comma seperated number</mat-label> -->
                                    <input matInput formControlName="femaleList"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <mat-error>
                                        {{dosageF.controls.animals.controls.femaleList.invalid |
                                        inputError:'custom_female' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--  Female Range and  custom inputs ends -->
                        </div>
                        <div [formGroup]="dosageF.controls.animals" style="width: 15%;">
                            <div *ngIf="dosageF.value.animalSequenceType == 'CUSTOM'">
                                <mat-form-field class="commonMatFormField" appearance="outline">
                                    <!-- <mat-label class="labelbg">Comma seperated number</mat-label> -->
                                    <input matInput formControlName="notKnownList"
                                        [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                                    <!-- <mat-error>
                                        {{dosageF.controls.animals.controls.femaleList.invalid | inputError:'custom_female' | translate}}
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="width: 5%;">
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Dose</mat-label> -->
                                <input matInput formControlName="dose" maxlength="25"
                                    [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                            </mat-form-field>
                        </div>

                        <div style="width: 10%;">
                            <mat-form-field class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Dose Unit</mat-label> -->
                                <input matInput formControlName="dosageUnit" maxlength="25"
                                    [readonly]="(isStudyEdit && !dosageF.value.newDosage)" />
                            </mat-form-field>
                        </div>

                        <div style="width: 15%; display: inline-flex">
                            <!-- <mat-radio-group aria-label="Select an option" formControlName="controlGroup">
                                <mat-radio-button value="Yes">Yes </mat-radio-button> &nbsp;
                                <mat-radio-button value="No">No </mat-radio-button>
                            </mat-radio-group>
                            <mat-error>{{dosageF.controls.controlGroup.invalid | inputError:'controlGroup' | translate}}
                            </mat-error> -->
                            <mat-form-field style="width: 85%;" class="commonMatFormField" appearance="outline">
                                <!-- <mat-label class="labelbg">Control Group<span class="mandatory">*</span></mat-label> -->
                                <mat-select formControlName="controlGroup"
                                    [disabled]="(isStudyEdit && !dosageF.value.newDosage)">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error>{{dosageF.controls.controlGroup.invalid | inputError:'controlGroup' | translate}}
                            </mat-error>

                            <div [ngClass]="[!dosageF.value.newDosage ? 'wrapper' : '']" style="margin-left: 0.5vw;">
                                <p [ngClass]="[!dosageF.value.newDosage ? 'disableCross' : 'enabledCross']"
                                    *ngIf="counterDosage > 1 && (!isStudyEdit || dosageCrationInProgress)"
                                    (click)="removeDosage(i)">
                                    <i class="fa fa-times"></i>
                                </p>
                            </div>
                        </div>
                        <!-- <div [ngClass]="[!dosageF.value.newDosage ? 'wrapper' : '']"  class="col-md-1 col-lg-1 col-xl-1">
                            <p [ngClass]="[!dosageF.value.newDosage ? 'disableCross' : 'enabledCross']"  *ngIf="counterDosage > 1" (click)="removeDosage(i)">
                                <i class="fa fa-times"></i>
                            </p>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="cass_btns">
                <!-- <div class="float-left" *ngIf="!autoCreateStudy && !isStudyEdit">
                    <input type="file" class="uploaddoc_btn" style="display: none;"
                        (change)="uploadExcel($event,'Dosage')" placeholder="Upload file" accept=".xlsx, .xls, .csv"
                        #dosageInputFile />
                    <button type="button" class="btn-default" (click)="dosageInputFile.click()">
                        Upload Document
                    </button>
                </div> -->
                <div *ngIf="!isDosageNextClicked" class="float-right dosageBtn">
                    <button type="button" class="btn btn-default" (click)="previous('Stage2')">
                        Previous
                    </button>
                    <!-- dosage save btns -->
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="(!isStudyEdit || dosageCrationInProgress) && !autoCreateStudy"
                        (click)="save('Stage3','Complete')">
                        Save &amp; Finish
                    </button>
                    <button type="button" mat-flat-button color="primary" *ngIf="autoCreateStudy"
                        (click)="navigateNext('Stage3')">
                        Next
                    </button>
                    <!-- dosage save btns -->

                    <!-- dosage edit btns -->
                    <!-- <button type="button" class="btn btn-primary button25" *ngIf="isStudyEdit && !showSlideTab" (click)="editStudy('Stage3','Complete')">
                        Save &amp; Finish
                      </button>
                    <button type="button" class="btn btn-default button25" *ngIf="isStudyEdit" (click)="editStudy('Stage3','Exit')">
                        Save &amp; Exit
                      </button>
                    <button type="button" class="btn btn-primary button25" *ngIf="isStudyEdit && showSlideTab" (click)="editStudy('Stage3','Next')">
              Save &amp; Next
            </button> -->
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="isStudyEdit && !showSlideTab && !dosageCrationInProgress" (click)="exitForm()">
                        Finish
                    </button>
                    <button type="button" mat-flat-button color="primary"
                        *ngIf="isStudyEdit && showSlideTab && !dosageCrationInProgress" (click)="studyEditSlideTab()">
                        Next
                    </button>
                    <!-- dosage edit btns -->
                </div>


                <div *ngIf="isDosageNextClicked" class="float-right dosageBtn ">
                    <button class="btn-default searchbtn" type="button" disabled>
                        <span class="spinner-border spinner-border-sm"
                            style="margin-right: 0.5vw; width: 1vw; height: 1vw;" role="status"
                            aria-hidden="true"></span>
                        <span>Please wait...</span>
                    </button>
                </div>
            </div>
        </div>
        <div id="forth">
            <div *ngIf="isStudyDataReady">
                <div class="clearfix">
                    <div class="float-left slideViewByFilter">
                        <div class="viewByText">View By: </div>
                        <div>
                            <mat-form-field class="commonMatFormField" appearance="outline"
                                style="width: 30vw !important;">
                                <mat-label class="labelbg">Slide Filter</mat-label>
                                <mat-select (selectionChange)='slideViewBySelection($event)'>
                                    <mat-option value="all">All</mat-option>
                                    <!-- <option value="errorRows">Rows with Error</option> -->
                                    <!-- <option value="custom">Custom</option> -->
                                    <mat-option value="blankSubjectId">Rows with Blank Subject Id</mat-option>
                                    <mat-option value="blankDuplicateImageName">Rows with Blank or Duplicate Image Name
                                    </mat-option>
                                    <mat-option value="blankStain">Rows with Blank Stain</mat-option>
                                    <mat-option *ngIf="studyCreateType != 'TCR'" value="blankDuplicateSlideId">Rows with
                                        Blank or Duplicate Slide Id</mat-option>
                                    <mat-option *ngIf="studyCreateType != 'TCR'" value="duplicateSlideData">Rows with
                                        Duplicate Data (Subject Id + Cassette Id + Stain)</mat-option>
                                    <mat-option *ngIf="studyCreateType == 'TCR'" value="blankSlideId">Rows with Blank
                                        Slide Id</mat-option>
                                    <mat-option *ngIf="studyCreateType == 'TCR'" value="duplicateTCRSlideData">Rows with
                                        Duplicate Data (Slide Id + Tissue Section)</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="float-right slideColumnChooserDiv cssTooltip">
                        <select id="slideColumnChooser" name="slideColumnChooser" multiple class="cssTooltip">
                            <ng-container *ngFor="let slideColumn of slideColumnArr;let columnIndex = index;">
                                <option
                                    *ngIf="((slideColumn.type != 'btn') && (slideColumn.isColumnHideable)) && (slideColumn.showInStudy.indexOf(studyCreateType) >= 0)"
                                    [value]="slideColumn.columnId">{{slideColumn.columnName}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="tabledata slideTableData">
                    <div class="tableHeaderMainDiv">
                        <table id="dataSlidesTableHeader" class="display nowrap" style="width:100%;">
                            <thead>
                                <tr class="headers">
                                    <ng-container *ngFor="let slideColumn of slideColumnArr">
                                        <th class="headersPara"
                                            *ngIf="(slideColumn.type != 'btn') && (slideColumn.showInStudy.indexOf(studyCreateType) >= 0)"
                                            [style.min-width]="slideColumn.width" [style.max-width]="slideColumn.width"
                                            [style.width]="slideColumn.width" [attr.colid]="slideColumn.columnId">
                                            <span>{{slideColumn.columnName}}</span><span *ngIf="slideColumn.isMandatory"
                                                class='mandatory'> *</span>
                                        </th>
                                        <th class="headersPara slideRowBtn"
                                            *ngIf="(slideColumn.type == 'btn') && (slideColumn.showInStudy.indexOf(studyCreateType) >= 0)"
                                            [style.min-width]="slideColumn.width" [style.max-width]="slideColumn.width"
                                            [style.width]="slideColumn.width" [attr.colid]="slideColumn.columnId">
                                            <!-- <div (click)="addSlideData()"> -->
                                            <mat-icon (click)="addNewSlide()"
                                                style="vertical-align: middle; cursor: pointer;">add</mat-icon>
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                    <cdk-virtual-scroll-viewport class="cdkVirtualScrollView" itemSize="50" minBufferPx="500"
                        maxBufferPx="700" (scrolledIndexChange)="nextBatchScroll($event)"
                        (scroll)="virtualScrollHandler($event)">
                        <div class="slideTableBodyDiv">
                            <table id="dataSlidesTableBody">
                                <tbody>
                                    <!-- <tr>
                                        <ng-container *ngFor="let slideColumn of slideColumnArr">
                                            <td class="headersPara"
                                                *ngIf="(slideColumn.type != 'btn') && (slideColumn.showInStudy.indexOf(studyCreateType) >= 0)"
                                                [style.min-width]="slideColumn.width"
                                                [style.max-width]="slideColumn.width" [style.width]="slideColumn.width"
                                                [attr.colid]="slideColumn.columnId">
                                                <span>{{slideColumn.columnName}}</span><span
                                                    *ngIf="slideColumn.isMandatory" class='mandatory'> *</span>
                                            </td>
                                            <td class="headersPara slideRowBtn"
                                                *ngIf="(slideColumn.type == 'btn') && (slideColumn.showInStudy.indexOf(studyCreateType) >= 0)"
                                                [style.min-width]="slideColumn.width"
                                                [style.max-width]="slideColumn.width" [style.width]="slideColumn.width"
                                                [attr.colid]="slideColumn.columnId">
                                                <mat-icon (click)="addNewSlide()"
                                                    style="vertical-align: middle; cursor: pointer;">add</mat-icon>
                                            </td>
                                        </ng-container>
                                    </tr> -->
                                    <tr *cdkVirtualFor="let slideItemInfo of tempSlideArrList; let itemIndex = index;"
                                        [attr.data-uniqcomb]="slideItemInfo.uniqCombination" rowduplicate="No">
                                        <ng-container *ngFor="let slideColumnObj of slideColumnArr;">
                                            <td *ngIf="(slideColumnObj.type != 'btn') && (slideColumnObj.showInStudy.indexOf(studyCreateType) >= 0)"
                                                [attr.data-index]="itemIndex" [style.min-width]="slideColumnObj.width"
                                                [style.max-width]="slideColumnObj.width"
                                                [style.width]="slideColumnObj.width"
                                                [attr.data-columnid]="slideColumnObj.columnId"
                                                [attr.data-val]="slideColumnObj.columnId =='animalId' && checkAnimalIdExist(slideItemInfo[slideColumnObj.columnId])"
                                                [attr.data-fieldval]="slideItemInfo[slideColumnObj.columnId] ? slideItemInfo[slideColumnObj.columnId] : ''">

                                                <ng-container *ngIf="slideColumnObj.columnId =='seq'">
                                                    <span>{{itemIndex + 1}}</span>
                                                </ng-container>

                                                <ng-container *ngIf="slideColumnObj.columnId =='slideId'">
                                                    <!-- <mat-form-field class="commonMatFormField" appearance="outline"
                                                        *ngIf="!slideItemInfo.slideTextBox">
                                                        <input type="text" matInput [readonly]="true" maxlength="250"
                                                            style="width: 100% "
                                                            [value]="slideItemInfo[slideColumnObj.columnId]">
                                                    </mat-form-field> -->
                                                    <div class="readOnlySlideField slideText"
                                                        *ngIf="(!slideItemInfo.slideTextBox || (slideItemInfo.slideTextBox && showSlideTab));else nonReadOnlySlideField">
                                                        <span>{{slideItemInfo[slideColumnObj.columnId]}}</span>
                                                    </div>
                                                    <ng-template #nonReadOnlySlideField>
                                                        <div class="disabledSlideField slideText"
                                                            *ngIf="(slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' ));else enableTextField">
                                                            <span>{{slideItemInfo[slideColumnObj.columnId]}}</span>
                                                        </div>
                                                        <ng-template #enableTextField>
                                                            <mat-form-field class="commonMatFormField slideText"
                                                                appearance="outline">
                                                                <input type="text" matInput maxlength="250"
                                                                    style="width: 100%"
                                                                    [value]="slideItemInfo[slideColumnObj.columnId]"
                                                                    (change)='onFieldchanged($event, slideColumnObj.columnId, slideItemInfo.index)'
                                                                    [disabled]="(slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' )) ">
                                                            </mat-form-field>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-container>

                                                <ng-container *ngIf="slideColumnObj.columnId !='slideId'">
                                                    <div (click)="deleteSlideItemData(slideItemInfo.index)"
                                                        *ngIf="slideColumnObj.type == 'btn'">
                                                        <mat-icon>delete_outline</mat-icon>
                                                    </div>

                                                    <!-- <mat-form-field class="commonMatFormField slideText"
                                                        appearance="outline" *ngIf="slideColumnObj.type=='display'">
                                                        <input type="text" matInput [readonly]="true" maxlength="250"
                                                            style="width: 100% "
                                                            [value]="slideItemInfo[slideColumnObj.columnId]">
                                                    </mat-form-field> -->
                                                    <div class="readOnlySlideField slideText"
                                                        *ngIf="(slideColumnObj.type=='display')">
                                                        <span>{{slideItemInfo[slideColumnObj.columnId]}}</span>
                                                    </div>

                                                    <!-- <mat-form-field class="commonMatFormField slideText"
                                                        appearance="outline" *ngIf="slideColumnObj.type=='text'">
                                                        <input
                                                            (change)='onFieldchanged($event, slideColumnObj.columnId, slideItemInfo.index)'
                                                            type="text" matInput [readonly]="showSlideTab"
                                                            maxlength="250" style="width: 100% "
                                                            [value]="slideItemInfo[slideColumnObj.columnId]"
                                                            [disabled]="(slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' )) ">
                                                    </mat-form-field> -->

                                                    <ng-container *ngIf="slideColumnObj.type=='text'">
                                                        <div class="slideText"
                                                            [ngClass]="{'readOnlySlideField': showSlideTab, 'disabledSlideField':(slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' ))}"
                                                            *ngIf="(showSlideTab || (slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' )));else editableTextField">
                                                            <span>{{slideItemInfo[slideColumnObj.columnId]}}</span>
                                                        </div>
                                                        <ng-template #editableTextField>
                                                            <mat-form-field class="commonMatFormField slideText"
                                                                appearance="outline">
                                                                <input style="width: 100% " type="text" matInput
                                                                    (change)='onFieldchanged($event, slideColumnObj.columnId, slideItemInfo.index)'
                                                                    maxlength="250"
                                                                    [value]="slideItemInfo[slideColumnObj.columnId]">
                                                            </mat-form-field>
                                                        </ng-template>
                                                    </ng-container>


                                                    <ng-container *ngIf="slideColumnObj.type=='date'">
                                                        <div class="slideText slideDateField"
                                                            [ngClass]="{'readOnlySlideField': showSlideTab, 'disabledSlideField':(slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' ))}"
                                                            *ngIf="(showSlideTab || (slideColumnObj.isStainIHCDisable && (slideItemInfo[ 'stain']!='IHC' )));else editableDateField">
                                                            <span>{{slideItemInfo[slideColumnObj.columnId] | date:
                                                                'dd/MM/yyyy'}}</span><mat-icon
                                                                class="icon">today</mat-icon>
                                                        </div>

                                                        <ng-template #editableDateField>
                                                            <mat-form-field class="commonMatFormField slideText"
                                                                appearance="outline">
                                                                <input matInput [matDatepicker]="picker"
                                                                    class="datePicker" style="width: 100% "
                                                                    (dateInput)="onFieldchanged($event, slideColumnObj.columnId, slideItemInfo.index)"
                                                                    [value]="slideItemInfo[slideColumnObj.columnId]| date:'yyyy-MM-dd'">
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                        </ng-template>
                                                    </ng-container>


                                                    <ng-container *ngIf="slideColumnObj.type=='select'">
                                                        <div class="slideText slideDateField"
                                                            [ngClass]="{'readOnlySlideField': showSlideTab}"
                                                            *ngIf="showSlideTab;else editableSelectField">
                                                            <span>{{slideItemInfo[slideColumnObj.columnId]}}</span>
                                                            <mat-icon class="icon">arrow_drop_down</mat-icon>
                                                        </div>

                                                        <ng-template #editableSelectField>
                                                            <mat-form-field class="commonMatFormField slideText"
                                                                appearance="outline">
                                                                <mat-select
                                                                    [value]='slideItemInfo[slideColumnObj.columnId]'
                                                                    (selectionChange)='onFieldchanged($event, slideColumnObj.columnId, slideItemInfo.index)'
                                                                    [disabled]="showSlideTab">
                                                                    <mat-option
                                                                        *ngIf="slideColumnObj.columnId =='animalId' && !checkAnimalIdExist(slideItemInfo[slideColumnObj.columnId])"
                                                                        value="select">Select</mat-option>
                                                                    <ng-container
                                                                        *ngIf="slideColumnObj.columnId != 'stain' && slideColumnObj.columnId != 'tissueSection' && slideColumnObj.columnId != 'slideControl' && slideColumnObj.columnId !='sex'">
                                                                        <mat-option
                                                                            *ngFor="let optionData of slideColumnObj.optionData"
                                                                            [value]="optionData">
                                                                            {{optionData}}</mat-option>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="slideColumnObj.columnId =='tissueSection' || slideColumnObj.columnId =='slideControl' || slideColumnObj.columnId =='sex'">
                                                                        <mat-option
                                                                            *ngFor="let optionData of slideColumnObj.optionData"
                                                                            [value]="optionData.optionId">
                                                                            {{optionData.optionName}}</mat-option>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="slideColumnObj.columnId =='stain'">
                                                                        <mat-option value="">Select</mat-option>
                                                                        <mat-option
                                                                            *ngFor="let optionData of getStainsOptions(slideItemInfo.cassetteId)"
                                                                            [value]="optionData">
                                                                            {{optionData}}</mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </ng-template>
                                                    </ng-container>

                                                    <mat-form-field *ngIf="slideColumnObj.type=='matChips'"
                                                        class="commonMatFormField slideText matChipColumn matTissueTag"
                                                        appearance="outline">
                                                        <mat-chip-list>
                                                            <mat-chip
                                                                *ngFor="let organ of slideItemInfo[slideColumnObj.columnId].split(';')">
                                                                {{organ}}</mat-chip>
                                                        </mat-chip-list>
                                                    </mat-form-field>
                                                </ng-container>

                                            </td>
                                            <td class="slideRowBtn"
                                                *ngIf="(slideColumnObj.type == 'btn') && (slideColumnObj.showInStudy.indexOf(studyCreateType) >= 0)"
                                                [attr.data-index]="itemIndex" [style.min-width]="slideColumnObj.width"
                                                [style.max-width]="slideColumnObj.width"
                                                [attr.data-columnid]="slideColumnObj.columnId">
                                                <mat-icon (click)="deleteSlide(itemIndex)"
                                                    style="cursor:pointer;">delete</mat-icon>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    <div class="noDataRow">No data to display</div>
                </div>
                <div class="slideBtn clearfix ">
                    <div *ngIf="!isAutoCreateSaveClicked" class="float-right dosageBtn ">
                        <button type="button " class="btn btn-default "
                            (click)="previous( studyCreateType == 'TCR' ? 'Stage1' : 'Stage3') ">
                            Previous
                        </button>
                        <!-- slide save btns -->
                        <button type="button" mat-flat-button color="primary" *ngIf="!isStudyEdit && !autoCreateStudy "
                            (click)="save( 'Stage4', 'Complete') ">
                            Save &amp; Finish
                        </button>
                        <button type="button" mat-flat-button color="primary" *ngIf="autoCreateStudy "
                            (click)="navigateNext( 'Stage4') ">
                            Save &amp; Finish
                        </button>
                        <!-- slide save btns -->

                        <!-- slide edit btns -->
                        <button type="button" mat-flat-button color="primary" *ngIf="isStudyEdit"
                            (click)="editStudy( 'Stage4', 'Complete') ">Finish</button>
                        <!-- slide edit btns -->
                    </div>
                    <div *ngIf="isAutoCreateSaveClicked" class="float-right dosageBtn ">
                        <button class="btn-default searchbtn" type="button" disabled>
                            <span class="spinner-border spinner-border-sm"
                                style="margin-right: 0.5vw; width: 1vw; height: 1vw;" role="status"
                                aria-hidden="true"></span>
                            <span>{{slideSavingText}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>