import { LoaderComponent } from './../../common/loader/loader.component';
import { StudyService } from './../study.service';
import { CommonService } from "./../../common.service";
import { ConfirmDialogComponent } from "./../../common/confirm-dialog/confirm-dialog.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from '@angular/common';
import { LocationMappingDialogComponent } from './../location-mapping-dialog/location-mapping-dialog.component';
import { MappingReportDialogComponent } from './../mapping-report-dialog/mapping-report-dialog.component';
import { AppConfigService } from './../../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'process';
import { map } from 'rxjs';
import { disable } from '@rxweb/reactive-form-validators';
import { Row } from 'jspdf-autotable';
import { GalleryService } from 'src/app/gallery/gallery.service';

declare var $: any;

@Component({
  selector: "app-study-listing",
  templateUrl: "./study-listing.component.html",
  styleUrls: ["./study-listing.component.css"]
})
export class StudyListingComponent implements OnInit, OnDestroy {
  config: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private studyListingService: StudyService,
    public galleryService: GalleryService,
    public configService: AppConfigService,
    private dialog: MatDialog,
    private commonService: CommonService,
    public translate: TranslateService,
    private activeRoute: ActivatedRoute,
    public datepipe: DatePipe
  ) {
    this.config = configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  observable: any;
  activeRole: any;
  searchForm: any;
  searchItems: any = {};
  mainActiveTab: any;
  activeTab: any;
  dataTable: any;
  dtOptions: any;
  tableData = [];
  search: any;
  showLoader: boolean = false;
  noTableData: boolean = false;
  speciesArr = [];
  pathologistDataArr = [];
  selectedParam: any;
  filterData = [];
  tagsInput: any;
  myStudyCount = 0;
  peerStudyCount = 0;
  pathoCompletedStudyCount = 0;
  pathoArchivedStudyCount = 0;
  createdStudyCount = 0;
  inprogressStudyCount = 0;
  completedStudyCount = 0;
  archievedStudyCount = 0;
  technicianStudyCount = 0;
  inactiveUserStudyCount = 0;
  messageDuration = 2000;
  techinicainStudyNos: Array<any> = [];
  technicianInterval: any;
  inActiveUserList: any;
  pathologists: any;
  technicians: any;
  studyDirectors: any;
  listOfRandomizedStudy: any;
  @ViewChild("dataTable", { static: false }) table;

  ngOnInit() {
    this.commonService.resetGalleryFilters();
    if (this.commonService.ProjectLSData.studyCreationType) {
      delete this.commonService.ProjectLSData.studyCreateType;
    }

    if (this.commonService.ProjectLSData.isTCRStudy) {
      delete this.commonService.ProjectLSData.isTCRStudy;
    }

    if (this.commonService.ProjectLSData.creationType) {
      delete this.commonService.ProjectLSData.creationType;
    }

    this.commonService.setProjectLSData();

    this.activeRoute.paramMap.subscribe((paramsData: any) => {
      if (this.technicianInterval) {
        clearInterval(this.technicianInterval);
      }
      this.activeRole = paramsData.params.role;

      if (this.activeRole.toLowerCase() == "pathologist") {

        this.searchForm = this.formBuilder.group({
          title: [""],
          species: [""],
          /*scarifice: [''],
          stain: [""],*/
          creationDate: [""]
        });
        this.studyListingService.getAllSpecies().subscribe((res: any) => {
          this.speciesArr = res.data;
          if (this.config.isForEpl) {
            this.studyListingService.getAllRandomizedStudies().pipe(map((res: any) => {
              // if (res?.length) {
              //   for (let a = 0; a < res.length; a++) {
              //     res[a].slides = res[a].slides.map((item: any) => {
              //       return item.slideId;
              //     });
              //   }
              //   this.galleryService.randomisedStudies = res;
              // }
              let studyList = res.map((item: any) => {
                return { 'studyName': item.study, 'selectedSlides': item.slides }
              });
              return studyList;
            })).subscribe((raodomizedStudyList: any) => {
              this.listOfRandomizedStudy = raodomizedStudyList;
              // this.commonService.ProjectLSData['randomisedStudies'] = [...raodomizedStudyList];
              // this.commonService.setProjectLSData();
              this.getPathologistStudies("myStudy");
            })
          } else {
            this.getPathologistStudies("myStudy");
          }

          $("#p_study_table").off();
          $("#p_study_table").on("click", "#galleryIcon", e => {
            let table = this.dataTable.DataTable();
            var data = table.row($(e.currentTarget).parents("tr")).data();
            var galleryFilter = this.commonService.ProjectLSData.galleryFilters;
            if (galleryFilter.studyName != data.studyGuid) {
              galleryFilter.studyName = data.studyGuid;
              galleryFilter.isTCRStudy = data.isTCRStudy;
              galleryFilter.stains = [];
              galleryFilter.organ = [];
              galleryFilter.animalNo = [];
              galleryFilter.control = [];
            }

            if (this.commonService.ProjectLSData['randomisedStudies']?.length) {
              for (let a = 0; a < this.commonService.ProjectLSData['randomisedStudies']?.length; a++) {
                if (galleryFilter.studyName == this.commonService.ProjectLSData['randomisedStudies'][a].studyName) {
                  this.commonService.ProjectLSData.isRandomisationStarted = true;
                  // this.commonService.ProjectLSData.randomisedSlideIds = this.commonService.ProjectLSData['randomisedStudies'][a]?.selectedSlides;

                  this.commonService.ProjectLSData.randomisedSlideIds = this.commonService.ProjectLSData['randomisedStudies'][a]?.selectedSlides.map((item: any) => {
                    return item.slideId;
                  });
                }
                else {
                  this.commonService.ProjectLSData.isRandomisationStarted = false;
                  this.commonService.ProjectLSData.randomisedSlideIds = [];
                }
              }
            }
            // if (this.galleryService.randomisedStudies?.length) {
            //   for (let a = 0; a < this.galleryService.randomisedStudies?.length; a++) {
            //     if (galleryFilter.studyName == this.galleryService.randomisedStudies[a].studyName) {
            //       this.galleryService.isStudyRandomised = true;
            //       break;
            //     } else {
            //       this.galleryService.isStudyRandomised = true;
            //     }
            //   }
            // }
            this.commonService.ProjectLSData.galleryFilters = galleryFilter;
            this.commonService.ProjectLSData.isTCRStudy = data.isTCRStudy;
            this.commonService.setProjectLSData();
            this.commonService.changePlace("gallery");
            this.router.navigate(["/dashboard/gallery"]);
          });
          $("#p_study_table").on("click", "#studyDetailIcon", e => {
            let table = this.dataTable.DataTable();
            var data = table.row($(e.currentTarget).parents("tr")).data();
            this.showDetails(data);
          });
          $("#p_study_table").on("click", "#markAsComplete", e => {
            let table = this.dataTable.DataTable();
            var data = table.row($(e.currentTarget).parents("tr")).data();
            this.changeStudyStatus(data.studyGuid, 'COMPLETED');
          });

          $("#p_study_table").on("click", "#pathoInProgressSudyIcon", e => {
            let table = this.dataTable.DataTable();
            var data = table.row($(e.currentTarget).parents("tr")).data();
            this.changeStudyStatus(data.studyGuid, 'IN_PROGRESS');
          });
        });
      } else if (this.activeRole.toLowerCase() == "study_director" || this.activeRole == "APPLICATION_ADMIN") {
        this.studyListingService.getAllPathologist().subscribe((response: any) => {
          this.pathologists = this.commonService.sortByMultiKey(response.data, ['displayName']);
        });
        this.studyListingService.getAllTechnician().subscribe((response: any) => {
          this.technicians = this.commonService.sortByMultiKey(response.data, ['displayName']);
        });
        this.studyListingService.getAllStudyDirector().subscribe((response: any) => {
          this.studyDirectors = this.commonService.sortByMultiKey(response.data, ['displayName']);

          // for async operation 
          let param = this.activeRole == 'APPLICATION_ADMIN' ? 'inActiveUser' : 'created';
          this.getStudyDirectorStudies(param);
        });
        // let param = this.activeRole == 'APPLICATION ADMIN' ?  'inActiveUser' : 'created';
        // this.getStudyDirectorStudies(param);
        $("#p_study_table").off();
        $("#p_study_table").on("click", "#deleteStudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.showDeleteStudyModel(data);
        });

        $("#p_study_table").on("click", "#editStudyIcon", e => {
          let table = this.dataTable.DataTable();
          this.commonService.ProjectLSData.isAdmin = this.activeRole == 'APPLICATION_ADMIN';
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.commonService.changePlace("study/" + this.activeRole + "/edit/" + data.studyGuid);
          this.router.navigate(["/dashboard/study/" + this.activeRole + "/edit/" + data.studyGuid]);
        });

        $("#p_study_table").on("click", "#studyDetailIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.showDetails(data);
        });

        $("#p_study_table").on("click", "#completeSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'COMPLETED');
        });

        $("#p_study_table").on("click", "#archiveSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'ARCHIVED');
        });

        $("#p_study_table").on("click", "#inProgressSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'IN_PROGRESS');
        });

        $("#p_study_table").on("change", ".reassignSelect", e => {
          //let table = this.dataTable.DataTable();
          if (e.currentTarget.value != "noval") {
            $(e.currentTarget).parents("tr").find('#saveReassignedUser').removeClass('disableFunction');
          } else {
            $(e.currentTarget).parents("tr").find('#saveReassignedUser').addClass('disableFunction');
          }
          //$(e.currentTarget).parents("tr").find('#saveReassignedUser').css('color', '#F47625');
        })

        $("#p_study_table").on("click", "#saveReassignedUser", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          data.user = $(e.currentTarget).closest('tr').find('select').val();
          this.saveReassignedData(data);
        });
      } else if (this.activeRole.toLowerCase() == "technician") {
        this.getTechnicianStudies();
        $("#p_study_table").off();
        $("#p_study_table").on("click", "#studyDetailIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.showDetails(data);
        }
        );

        $("#p_study_table").on("click", "#galleryIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          var galleryFilter = this.commonService.ProjectLSData.galleryFilters;
          if (galleryFilter.studyName != data.studyGuid) {
            galleryFilter.studyName = data.studyGuid;
            galleryFilter.isTCRStudy = data.isTCRStudy;
            galleryFilter.stains = [];
            galleryFilter.organ = [];
            galleryFilter.animalNo = [];
            galleryFilter.control = [];
          }
          this.commonService.ProjectLSData.galleryFilters = galleryFilter;
          this.commonService.ProjectLSData.isTCRStudy = data.isTCRStudy;
          this.commonService.setProjectLSData();
          this.commonService.changePlace("mapped");
          this.router.navigate(["/dashboard/mapped"]);
        });

        $("#p_study_table").on("click", ".mapBtn", (e) => {
          let table = this.dataTable.DataTable();
          let data = table.row($(e.currentTarget).parents("tr")).data();
          this.mapImage(data, "start", e.currentTarget);
        });
        $("#p_study_table").on("click", ".syncBtn", (e) => {
          let table = this.dataTable.DataTable();
          let data = table.row($(e.currentTarget).parents("tr")).data();
          this.mapImage(data, "sync", e.currentTarget);
        });
        $("#p_study_table").on("click", ".reportBtn", (e) => {
          let table = this.dataTable.DataTable();
          let data = table.row($(e.currentTarget).parents("tr")).data();
          this.mapReport(data);
        });
      }

      setTimeout(() => {
        $("#studysearch").on("keyup click", e => {
          let table = this.dataTable.DataTable();
          table.search($(e.currentTarget).val()).draw();
        });
      }, 50);
    });
  }

  navigateToCreateStudy(paramValue, manualAuto) {
    if (paramValue) {
      this.commonService.ProjectLSData.studyCreationType = paramValue;
    }
    if (manualAuto) {
      this.commonService.ProjectLSData.creationType = manualAuto;
    }
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study/" + this.activeRole + "/create");
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/create"]);
  }

  showDeleteStudyModel(studyData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Study",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_STUDY"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        dialogConfig.width = "auto";
        dialogConfig.height = "auto";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          'Msg': "Deleting..."
        };
        const loaderDialogRef = this.dialog.open(LoaderComponent, dialogConfig);
        this.studyListingService.deleteStudy(studyData.studyGuid).subscribe((res: any) => {
          loaderDialogRef.close();
          this.commonService.showToastMsg(res.message);
          /* this.studyListingService.getDirectorStudyCount().subscribe((res: any) => {
            this.createdStudyCount = res.data.created;
            this.inprogressStudyCount = res.data.inProgress;
            this.completedStudyCount = res.data.completed;
            this.archievedStudyCount = res.data.archived;
            this.inactiveUserStudyCount = res.data.actions; */
          this.getStudyDirectorStudies('created');
          //});
        }, (err) => {
          loaderDialogRef.close();
        });
      }
    });
  }

  showHideFilter() {
    if ($(".multiselectfilter").is(":visible")) {
      $(".multiselectfilter").hide();
      this.clearFilterFields();
      if (this.tagsInput.tagsinput("items").length == 0) {
        this.tagsInput.tagsinput("destroy");
      }
    } else {
      $(".multiselectfilter").show();
      this.tagsInput = $(".tags");
      if ($('.bootstrap-tagsinput').length > 0) {
        $('.bootstrap-tagsinput').css('display', 'block');
        $('#studysearch').css('display', 'none');
      } else {
        this.tagsInput.tagsinput({
          itemValue: "criteria",
          itemText: "value",
          maxChars: 100,
          trimValue: false
        });
      }
      this.setData();
      this.generateFilterDataTable();
      this.tagsInput.on("itemRemoved", event => {
        if (this.tagsInput.tagsinput("items").length == 0) {
          //this.tagsInput.tagsinput("destroy");
          $('.bootstrap-tagsinput').css('display', 'none');
          $('#studysearch').css('display', 'block');
        }
        this.setData();
        this.generateFilterDataTable();
      });
    }
  }

  onSearch() {
    this.tagsInput.tagsinput("removeAll");
    for (var prop in this.searchForm.value) {
      if (
        this.searchForm.value.hasOwnProperty(prop) &&
        this.searchForm.value[prop]
      ) {
        var innerObj = {};
        innerObj["criteria"] = prop;
        innerObj["value"] = this.searchForm.value[prop];
        this.tagsInput.tagsinput("add", innerObj);
      }
    }
    if (this.tagsInput.tagsinput("items").length == 0) {
      this.tagsInput.tagsinput("destroy");
    }
    this.generateFilterDataTable();
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  generateFilterDataTable() {
    this.showLoader = true;
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    let formValues = this.searchForm.value;
    if (formValues.title && formValues.species && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase() &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.title && formValues.species) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase()
      });
    } else if (formValues.title && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.species && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase() &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.title || formValues.species || formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return ((formValues.title && elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase())) ||
          (formValues.species && elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase()) ||
          (formValues.creationDate && new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime()));
      });
    } else {
      this.filterData = this.pathologistDataArr;
    }
    this.dtOptions = {
      "bDestroy": true,
      data: this.filterData,
      responsive: true,
      paging: false,
      info: false,
      scrollY: "calc(100vh - 19vw)",
      scrollCollapse: true,
      order: [],
      columns: [
        {
          title: "<span style='color:#10458b'>#</span>",
          data: "",
          sWidth: "3%",
          bSearchable: false,
          bSortable: false,
          render: function (data: any, type: any, row: any, meta: any) {
            if (row.status == "IN_PROGRESS") {
              return "<a class='imgicontable' id='galleryIcon'><i class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";
            } else {
              return "";
            }
          }
        },
        {
          title: "Study No", data: "studyNo", sWidth: "13%",
          render: function (data) {
            return "<div id='studyDetailIcon' class='orangeText' title='" + data + "'>" + data + "</div>"
          }
        },
        {
          title: "Study Title", data: "studyTitle", sWidth: "12%",
          render: function (data) {
            if (data) {
              return "<div class='studyTitle' title='" + data + "'>" + data + "</div>"
            } else {
              return "--"
            }
          }
        },
        {
          title: "Status", data: "status", sWidth: "12%",
          render: (data: any) => {
            //return this.commonService.camelCase(data).replace("_", " ");
            if (data == 'CREATION_DONE') {
              return (
                '<span title="Created">Created</span>'
              );
            } else if (data == 'CREATION_ONGOING') {
              return (
                '<span title="Draft">Draft</span>'
              );
            } else if (data == 'IN_PROGRESS') {
              return (
                '<span title="In Progress">In Progress</span>'
              );
            } else {
              return (
                '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
              );
            }
          }
        },
        /* { title: 'Sacrifice', data: 'sacrifice' }, */
        {
          title: "Creation Date", sWidth: "15%",
          data: "creationDate",
          render: (data) => {
            return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
          }
        },
        {
          title: "Slides Mapped", data: "mappedImagesCount", sWidth: "14%",
          render: function (data: any, type: any, row: any, meta: any) {
            return "<span>" + row.mappedImagesCount + " / " + (row.mappedImagesCount + row.unmappedImagesCount) + "</span>"
          }
        },
        /* { title: "Unmapped", data: "unmappedImagesCount", sWidth: "12%" }, */
        { title: "Species", data: "species", sWidth: "13%" },
        /* { title: 'Stain', data: 'stain' }, */
        {
          title: "Peer Reviewer",
          data: "peerReviewer",
          sWidth: "18%",
          visible: this.selectedParam == "myStudy",
          render: function (data) {
            if (data) {
              return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
            }
            else return "";
          }
        },
        {
          title: "Pathologist",
          data: "pathologist",
          visible: this.selectedParam == "peerReviewer",
          sWidth: "15%",
          render: function (data) {
            if (data) {
              return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
            }
            else return "";
          }
        }
      ]
    }
    if (this.filterData.length > 0) {
      this.noTableData = false
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOptions);
    } else {
      this.noTableData = true;
      this.dataTable = '';
    }
    this.showLoader = false;
  }

  onCancel() {
    if ($(".tags").tagsinput("items").length == 0) {
      $(".tags").tagsinput("destroy");
    }
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  onReset() {
    this.clearFilterFields();
    this.tagsInput.tagsinput("removeAll");
    this.generateFilterDataTable();
  }

  setData() {
    this.searchItems = {};
    let tagArr = $(".tags").tagsinput("items");
    for (let i = 0; i < tagArr.length; i++) {
      this.searchItems[tagArr[i].criteria] = tagArr[i].value;
    }
    this.searchForm.setValue({
      title: this.searchItems.title ? this.searchItems.title : "",
      species: this.searchItems.species ? this.searchItems.species : "",
      /*scarifice: this.searchItems.scarifice ? this.searchItems.scarifice : '',*/
      /* stain: this.searchItems.stain ? this.searchItems.stain : "", */
      creationDate: this.searchItems.creationDate
        ? this.searchItems.creationDate
        : ""
    });
  }
  clearFilterFields() {
    this.searchForm.setValue({
      title: "",
      species: "",
      /*scarifice: '',
      stain: "",*/
      creationDate: ""
    });
  }

  getPathologistStudies(paramValue) {
    this.showLoader = true;
    this.studyListingService.getPathologistStudyCount().subscribe((res: any) => {
      this.myStudyCount = res.data.myStudy;
      this.peerStudyCount = res.data.peerReviewStudy;
      this.pathoCompletedStudyCount = res.data.completed;
      this.pathoArchivedStudyCount = res.data.archived;
      this.selectedParam = paramValue;
      if ($('.bootstrap-tagsinput').length > 0) {
        if ($(".multiselectfilter").is(":visible")) {
          $(".multiselectfilter").hide();
          this.clearFilterFields();
        }
        this.tagsInput.tagsinput('removeAll');
        $('.bootstrap-tagsinput').css('display', 'none');
        $('#studysearch').css('display', 'block');
      }
      $("#studysearch").val("");
      if (this.dataTable) {
        this.dataTable.DataTable().destroy();
        this.dataTable.empty();
      }
      if (paramValue == "myStudy") {
        this.activeTab = "myStudy";
        this.observable = this.studyListingService.getMyStudies();
      } else if (paramValue == "peerReviewer") {
        this.activeTab = "peerReview";
        this.observable = this.studyListingService.getPeerStudies();
      } else if (paramValue == "pathoCompleted") {
        this.activeTab = "pathoCompleted";
        this.observable = this.studyListingService.getStudylisting(10, 'PATHOLOGIST');
      } else if (paramValue == "pathoArchived") {
        this.activeTab = "pathoArchived";
        this.observable = this.studyListingService.getStudylisting(25, 'PATHOLOGIST');
      }
      this.observable.subscribe((response: any) => {
        if (paramValue == 'myStudy' && this.config.isForEpl && this.listOfRandomizedStudy?.length) {
          response.data.forEach((item: any) => {
            // if (this.listOfRandomizedStudy.includes(item.studyGuid)) {
            //   item.isRandomized = true;
            // }

            for (let a = 0; a < this.listOfRandomizedStudy?.length; a++) {
              if (item.studyGuid == this.listOfRandomizedStudy[a].studyName) {
                item.isRandomized = true;
              }
            }

          })
        }
        this.pathologistDataArr = response.data;
        this.tableData = response.data;
        this.dtOptions = {
          "bDestroy": true,
          data: this.tableData,
          responsive: true,
          paging: false,
          info: false,
          scrollY: "calc(100vh - 19vw)",
          scrollCollapse: true,
          order: [],
          columns: [
            {
              title: "<span style='color:#10458b'>#</span>",
              data: "",
              sWidth: "5%",
              bSearchable: false,
              bSortable: false,
              render: function (data: any, type: any, row: any, meta: any) {
                if (row.status == "IN_PROGRESS" && paramValue == "myStudy") {
                  return "<a class='imgicontable' id='galleryIcon'><i class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";//<a class='imgicontable' id='markAsComplete'><i class='fa fa-check imageicon' title='Complete Study'></i></a>
                } else if (paramValue == "pathoCompleted") {
                  return "<a class='imgicontable' id='pathoInProgressSudyIcon'><i class='fa fa-tasks imageicon' title='In Progress Study'></i></a>";
                } else {
                  return "";
                }
              }
            },
            {
              title: "Study No", data: "studyNo", sWidth: "13%",
              render: function (data, type: any, row: any,) {
                if (row.isRandomized) {
                  return "<div id='studyDetailIcon' class='disabledStudy' title='" + data + "'>" + data + "</div>"
                } else {
                  return "<div id='studyDetailIcon' class='orangeText' title='" + data + "'>" + data + "</div>"
                }

              }
            },
            {
              title: "Study Title", data: "studyTitle", sWidth: "12%",
              render: function (data) {
                if (data) {
                  return "<div class='studyTitle' title='" + data + "'>" + data + "</div>"
                } else {
                  return "--"
                }
              }
            },
            {
              title: "Status", data: "status", sWidth: "12%",
              render: (data: any) => {
                //return this.commonService.camelCase(data).replace("_", " ");
                if (data == 'CREATION_DONE') {
                  return (
                    '<span title="Created">Created</span>'
                  );
                } else if (data == 'CREATION_ONGOING') {
                  return (
                    '<span title="Draft">Draft</span>'
                  );
                } else if (data == 'IN_PROGRESS') {
                  return (
                    '<span title="In Progress">In Progress</span>'
                  );
                } else {
                  return (
                    '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
                  );
                }
              }
            },
            /* { title: 'Sacrifice', data: 'sacrifice' }, */
            {
              title: "Creation Date", sWidth: "15%",
              data: "creationDate",
              render: (data) => {
                return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
              }
            },
            {
              title: "Slides Mapped", data: "mappedImagesCount", sWidth: "14%",
              render: function (data: any, type: any, row: any, meta: any) {
                return "<span>" + row.mappedImagesCount + " / " + (row.mappedImagesCount + row.unmappedImagesCount) + "</span>"
              }
            },
            /* { title: "Unmapped", data: "unmappedImagesCount", sWidth: "12%" }, */
            { title: "Species", data: "species", sWidth: "13%" },
            /* { title: 'Stain', data: 'stain' }, */
            {
              title: "Peer Reviewer",
              data: "peerReviewer",
              sWidth: "15%",
              visible: paramValue == "myStudy",
              render: function (data) {
                if (data) {
                  return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
                }
                else return "";
              }
            },
            {
              title: "Pathologist",
              data: "pathologist",
              visible: paramValue == "peerReviewer",
              sWidth: "12%",
              render: function (data) {
                if (data) {
                  return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
                }
                else return "";
              }
            }
          ]
        };
      }, err => { }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false
          this.dataTable = $(this.table.nativeElement);
          this.dataTable.DataTable(this.dtOptions);
        } else {
          this.noTableData = true;
          this.dataTable = '';
        }
        this.showLoader = false;
      });
    });
  }

  // getStudyDirectorstudies(paramValue){
  //   this.showLoader = true;
  //   this.studyListingService.getPathologistStudyCount().subscribe((res: any) => {
  //     this.myStudyCount = res.data.myStudy;
  //     this.peerStudyCount = res.data.peerReviewStudy;
  //     this.pathoCompletedStudyCount = res.data.completed;
  //     this.pathoArchivedStudyCount = res.data.archived;
  //     this.selectedParam = paramValue;
  //     if ($('.bootstrap-tagsinput').length > 0) {
  //       if ($(".multiselectfilter").is(":visible")) {
  //         $(".multiselectfilter").hide();
  //         this.clearFilterFields();
  //       }
  //       this.tagsInput.tagsinput('removeAll');
  //       $('.bootstrap-tagsinput').css('display', 'none');
  //       $('#studysearch').css('display', 'block');
  //     }
  //     $("#studysearch").val("");
  //     if (this.dataTable) {
  //       this.dataTable.DataTable().destroy();
  //       this.dataTable.empty();
  //     }
  //     if (paramValue == "myStudy") {
  //       this.activeTab = "myStudy";
  //       this.observable = this.studyListingService.getMyStudies();
  //     } else if (paramValue == "peerReviewer") {
  //       this.activeTab = "peerReview";
  //       this.observable = this.studyListingService.getPeerStudies();
  //     } else if (paramValue == "pathoCompleted") {
  //       this.activeTab = "pathoCompleted";
  //       this.observable = this.studyListingService.getStudylisting(10, 'PATHOLOGIST');
  //     } else if (paramValue == "pathoArchived") {
  //       this.activeTab = "pathoArchived";
  //       this.observable = this.studyListingService.getStudylisting(25, 'PATHOLOGIST');
  //     }
  //     this.observable.subscribe((response: any) => {
  //       this.pathologistDataArr = response.data;
  //       this.tableData = response.data;
  //       this.dtOptions = {
  //         "bDestroy": true,
  //         data: this.tableData,
  //         responsive: true,
  //         paging: false,
  //         info: false,
  //         scrollY: "calc(100vh - 19vw)",
  //         scrollCollapse: true,
  //         order: [],
  //         columns: [
  //           {
  //             title: "<span style='color:#10458b'>#</span>",
  //             data: "",
  //             sWidth: "5%",
  //             bSearchable: false,
  //             bSortable: false,
  //             render: function (data: any, type: any, row: any, meta: any) {
  //               if (row.status == "IN_PROGRESS" && paramValue == "myStudy") {
  //                 return "<a class='imgicontable' id='galleryIcon'><i class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";//<a class='imgicontable' id='markAsComplete'><i class='fa fa-check imageicon' title='Complete Study'></i></a>
  //               } else if (paramValue == "pathoCompleted") {
  //                 return "<a class='imgicontable' id='pathoInProgressSudyIcon'><i class='fa fa-tasks imageicon' title='In Progress Study'></i></a>";
  //               } else {
  //                 return "";
  //               }
  //             }
  //           },
  //           {
  //             title: "Study No", data: "studyNo", sWidth: "13%",
  //             render: function (data) {
  //               return "<div id='studyDetailIcon' class='orangeText' title='" + data + "'>" + data + "</div>"
  //             }
  //           },
  //           {
  //             title: "Study Title", data: "studyTitle", sWidth: "12%",
  //             render: function (data) {
  //               if (data) {
  //                 return "<div class='studyTitle' title='" + data + "'>" + data + "</div>"
  //               } else {
  //                 return "--"
  //               }
  //             }
  //           },
  //           {
  //             title: "Status", data: "status", sWidth: "12%",
  //             render: (data: any) => {
  //               //return this.commonService.camelCase(data).replace("_", " ");
  //               if (data == 'CREATION_DONE') {
  //                 return (
  //                   '<span title="Created">Created</span>'
  //                 );
  //               } else if (data == 'CREATION_ONGOING') {
  //                 return (
  //                   '<span title="Draft">Draft</span>'
  //                 );
  //               } else if (data == 'IN_PROGRESS') {
  //                 return (
  //                   '<span title="In Progress">In Progress</span>'
  //                 );
  //               } else {
  //                 return (
  //                   '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
  //                 );
  //               }
  //             }
  //           },
  //           /* { title: 'Sacrifice', data: 'sacrifice' }, */
  //           {
  //             title: "Creation Date", sWidth: "15%",
  //             data: "creationDate",
  //             render: (data) => {
  //               return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
  //             }
  //           },
  //           {
  //             title: "Slides Mapped", data: "mappedImagesCount", sWidth: "14%",
  //             render: function (data: any, type: any, row: any, meta: any) {
  //               return "<span>" + row.mappedImagesCount + " / " + (row.mappedImagesCount + row.unmappedImagesCount) + "</span>"
  //             }
  //           },
  //           /* { title: "Unmapped", data: "unmappedImagesCount", sWidth: "12%" }, */
  //           { title: "Species", data: "species", sWidth: "13%" },
  //           /* { title: 'Stain', data: 'stain' }, */
  //           {
  //             title: "Peer Reviewer",
  //             data: "peerReviewer",
  //             sWidth: "15%",
  //             visible: paramValue == "myStudy",
  //             render: function (data) {
  //               if (data) {
  //                 return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
  //               }
  //               else return "";
  //             }
  //           },
  //           {
  //             title: "Pathologist",
  //             data: "pathologist",
  //             visible: paramValue == "peerReviewer",
  //             sWidth: "12%",
  //             render: function (data) {
  //               if (data) {
  //                 return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
  //               }
  //               else return "";
  //             }
  //           }
  //         ]
  //       };
  //     }, err => { }, () => {
  //       if (this.tableData.length > 0) {
  //         this.noTableData = false
  //         this.dataTable = $(this.table.nativeElement);
  //         this.dataTable.DataTable(this.dtOptions);
  //       } else {
  //         this.noTableData = true;
  //         this.dataTable = '';
  //       }
  //       this.showLoader = false;
  //     });
  //   });
  // }

  getTechnicianStudies() {
    this.techinicainStudyNos = [];
    this.showLoader = true;
    $("#studysearch").val("");
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    this.studyListingService
      .getTechnicianStudies()
      .subscribe((response: any) => {
        response.data.forEach(study => {
          if (study.thumbnailGenStatus) {
            this.techinicainStudyNos.push(study.studyGuid);
          }
        });
        this.technicianInterval = setInterval(() => {
          if (this.techinicainStudyNos.length > 0) {
            let data = { "studyGuids": this.techinicainStudyNos };
            this.studyListingService.getStudyThumbnailGenerationStatus(data).subscribe((res: any) => {
              if (res.data) {
                //let flag = false;
                for (const property in res.data) {
                  if (!res.data[property]) {
                    //flag = true;
                    //studydata.thumbnailGenStatus = true;
                    //table.row($(target)).data( studydata ).invalidate();
                    //this.dataTable.dataTable().fnUpdate(studydata, ($(target).parents("tr")), undefined, false);
                    var studyData = this.dataTable.DataTable().rows((idx, data, node) => {
                      return data.studyGuid == property
                    }).data();
                    studyData[0].thumbnailGenStatus = res.data[property];
                    this.dataTable.dataTable().fnUpdate(studyData[0], $("[data-guid='" + property + "']").parents("tr"), undefined, false);
                    let index = this.techinicainStudyNos.indexOf(property);
                    this.techinicainStudyNos.splice(index);
                  }
                }
                /* if (flag) {
                  //this.getTechnicianStudies();
                } */
              }
            });
          }
        }, 20000);
        this.technicianStudyCount = response.data.length;
        this.tableData = response.data;
        this.dtOptions = {
          "bDestroy": true,
          data: this.tableData,
          bAutoWidth: false,
          responsive: true,
          paging: false,
          scrollY: "calc(100vh - 19vw)",
          scrollCollapse: false,
          info: false,
          order: [],
          columns: [
            {
              title: "<span style='color:#10458b'>#</span>",
              data: "",
              sWidth: "3%",
              bSearchable: false,
              bSortable: false,
              render: function (data: any, type: any, row: any, meta: any) {
                if (row.status == "IN_PROGRESS") {
                  return "<a class='imgicontable' id='galleryIcon'><i class='fa fa-picture-o imageicon' title='Open Mapped'></i></a>";
                } else {
                  return "";
                }
              }
            },
            {
              title: "Study No", data: "studyNo", sWidth: "10%",
              render: function (data: any, type: any, row: any, meta: any) {
                return "<div id='studyDetailIcon' class='orangeText' title='" + data + "' data-guid='" + row.studyGuid + "'>" + data + "</div>"
              }
            },
            {
              title: "Study Title", data: "studyTitle", sWidth: "10%",
              render: function (data) {
                if (data) {
                  return "<div class='studyTitle' title='" + data + "'>" + data + "</div>"
                } else {
                  return "--"
                }
              }
            },
            {
              title: "Status", data: "status", sWidth: "10%",
              render: (data: any) => {
                //return this.commonService.camelCase(data).replace("_", " ");
                if (data == 'CREATION_DONE') {
                  return (
                    '<span title="Created">Created</span>'
                  );
                } else if (data == 'CREATION_ONGOING') {
                  return (
                    '<span title="Draft">Draft</span>'
                  );
                } else if (data == 'IN_PROGRESS') {
                  return (
                    '<span title="In Progress">In Progress</span>'
                  );
                } else {
                  return (
                    '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
                  );
                }
              }
            },
            /* { title: 'Sacrifice', data: 'sacrifice' }, */
            {
              title: "Creation Date", sWidth: "13%",
              data: "creationDate",
              render: (data) => {
                return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
              }
            },
            { title: "Species", data: "species", sWidth: "8%" },
            {
              title: "Pathologist", data: "pathologist", sWidth: "10%",
              render: (data) => {
                return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
              }
            },
            // { title: "Total Images Mapped", data: "totalSlideMapped" },
            { title: "Unmapped Images", data: "unmappedImagesCount", sWidth: "8%" },
            { title: "Mapped Images", data: "mappedImagesCount", sWidth: "8%" },
            // {  bSearchable: false,
            //   bSortable: false,
            //   render: function (data: any, type: any, row: any, meta: any) {
            //     return (
            //       '<i style="font-size:1.5vw" title="Image Mapping In Progress" class="fa fa-spinner fa-spin"></i>'
            //     )

            //   }
            //   },
            // {
            //   bSearchable: false,
            //   bSortable: false,
            //   render: function (data) {
            //     return (
            //       '<div class="dropdown technidropdown">' +
            //       '<button type="button" class="btn-default technidropdown_list dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
            //       "Map Image" +
            //       "</button>" +
            //       '<div class="dropdown-menu">' +
            //       '<a class="dropdown-item scannedImage">Scanned Images</a>' +
            //       '<a class="dropdown-item uploadedImage">Uploaded Images</a>' +
            //       "</div>" +
            //       "</div>"
            //     );
            //   }
            // },
            {
              bSearchable: false,
              bSortable: false,
              sWidth: "10%",
              render: function (data: any, type: any, row: any, meta: any) {
                if (row.thumbnailGenStatus) {
                  return (
                    // '<i style="font-size:1.5vw" title="Thumbnail Generation In Progress" class="fa fa-spinner fa-spin"></i>'
                    '<i style="font-size:1.5vw; text-align: center; width: 100%;color:#f47625;" title="Thumbnail Generation In Progress" class="fa fa-spinner fa-spin"></i>'
                  )
                } else {
                  if (row.unmappedImagesCount == 0 && row.mappedImagesCount == 0) {
                    return '';
                  } else if (row.unmappedImagesCount == 0) {
                    return (
                      '<button aria-label="reportBtn" class="btn filterBarItems reportBtn" style="width: 100%;background: #D6DFF2" type="button">View Report</button>'
                    );
                  } else if (row.mappedImagesCount == 0) {
                    return (
                      '<div class="dropdown">' +
                      '<a class="btn" href="#" role="button" id="dropdownMenuLink" style="width: 100%" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                      '<i class="fa fa-ellipsis-v"></i>' +
                      '</a>' +
                      '<div class="dropdown-menu" aria-labelledby="dropdownMenuLink">' +
                      '<a class="dropdown-item mapBtn" href="javascript:void(0)">Map Image</a>' +
                      '<a class="dropdown-item reportBtn" href="javascript:void(0)">View Report</a>' +
                      '</div>' +
                      '</div>'
                    );
                  } else {
                    return (
                      '<div class="dropdown">' +
                      '<a class="btn" href="#" role="button" id="dropdownMenuLink" style="width: 100%" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                      '<i class="fa fa-ellipsis-v"></i>' +
                      '</a>' +
                      '<div class="dropdown-menu" aria-labelledby="dropdownMenuLink">' +
                      '<a class="dropdown-item syncBtn" href="javascript:void(0)">Sync Images</a>' +
                      '<a class="dropdown-item reportBtn" href="javascript:void(0)">View Report</a>' +
                      '</div>' +
                      '</div>'
                    );
                  }
                }
              }
            }
          ]
        };
      }, err => {
        this.noTableData = true;
        this.dataTable = '';
        this.showLoader = false;
      }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false
          this.dataTable = $(this.table.nativeElement);
          this.dataTable.DataTable(this.dtOptions);

          // $("#p_study_table").on("click", ".scannedImage", (e) => {
          //   let table = this.dataTable.DataTable();
          //   let data = table.row($(e.currentTarget).parents("tr")).data();
          //   let galleryFilter = this.commonService.ProjectLSData.galleryFilters;
          //   galleryFilter.studyName = data.studyGuid;
          //   this.commonService.ProjectLSData.galleryFilters = galleryFilter;
          //   this.commonService.setProjectLSData();
          //   this.commonService.changePlace("scanned");
          //   this.router.navigate(["/dashboard/scanned"]);
          // });
          // $("#p_study_table").on("click", ".uploadedImage", (e) => {
          //   let table = this.dataTable.DataTable();
          //   let data = table.row($(e.currentTarget).parents("tr")).data();
          //   let galleryFilter = this.commonService.ProjectLSData.galleryFilters;
          //   galleryFilter.studyName = data.studyGuid;
          //   this.commonService.ProjectLSData.galleryFilters = galleryFilter;
          //   this.commonService.setProjectLSData();
          //   this.commonService.changePlace("uploaded");
          //   this.router.navigate(["/dashboard/uploaded"]);
          // });
        } else {
          this.noTableData = true;
          this.dataTable = '';
        }
        this.showLoader = false;
      });
  }

  navigateScanned(data) {
    alert(data);
  }

  getStudyDirectorStudies(paramValue) {
    this.studyListingService.getDirectorStudyCount().subscribe((res: any) => {
      this.createdStudyCount = res.data.created;
      this.inprogressStudyCount = res.data.inProgress;
      this.completedStudyCount = res.data.completed;
      this.archievedStudyCount = res.data.archived;
      this.inactiveUserStudyCount = res.data.actions;
      this.showLoader = true;
      $("#studysearch").val("");
      if (this.dataTable) {
        this.dataTable.DataTable().destroy();
        this.dataTable.empty();
      } if (paramValue == "created") {
        this.mainActiveTab = "study_listing";
        this.activeTab = "created";
        this.observable = this.studyListingService.getCreatedStudies();
      } else if (paramValue == "inProgress") {
        this.mainActiveTab = "study_listing";
        this.activeTab = "inProgress";
        this.observable = this.studyListingService.getInProgressStudies();
      } else if (paramValue == "completed") {
        this.mainActiveTab = "study_listing";
        this.activeTab = "completed";
        this.observable = this.studyListingService.getCompletedStudies();
      } else if (paramValue == "archieved") {
        this.mainActiveTab = "study_listing";
        this.activeTab = "archieved";
        this.observable = this.studyListingService.getArchievedStudies();
      } else if (paramValue == "inActiveUser") {
        this.mainActiveTab = 'reassign_role';
        this.activeTab = "inActiveUser";
        this.observable = this.activeRole == 'APPLICATION_ADMIN' ? this.studyListingService.getStudyDirectorsStudy() : this.studyListingService.getInActiveStudyList();
        //this.showInactiveUsers();
      }
      this.observable.subscribe((response: any) => {
        this.tableData = response.data;
        this.dtOptions = {
          "bDestroy": true,
          data: this.tableData,
          responsive: true,
          bAutoWidth: false,
          paging: false,
          scrollY: this.activeRole == 'APPLICATION_ADMIN' ? "calc(100vh - 15vw)" : "calc(100vh - 19vw)",
          scrollCollapse: true,
          info: false,
          order: [],
          columns: [
            {
              title: "",
              data: "",
              sWidth: "5%",
              bSearchable: false,
              bSortable: false,
              visible: this.activeRole != 'APPLICATION_ADMIN',
              render: function (data: any, type: any, row: any, meta: any) {
                if (paramValue == "markAsComplete") {
                  //return "<a class='imgicontable' id='completeSudyIcon'><i class='fa fa-check imageicon' title='Complete Study'></i></a>";
                  return '';
                } else if (paramValue == "completed") {
                  return "<a class='imgicontable' id='archiveSudyIcon'><i class='fa fa-archive imageicon' title='Archive Study'></i></a><a class='imgicontable' id='inProgressSudyIcon'><i class='fa fa-tasks imageicon' title='In Progress Study'></i></a>";
                  return '';
                } else if (paramValue == "archieved") {
                  return "<a class='imgicontable' id='inProgressSudyIcon'><i class='fa fa-tasks imageicon' title='In Progress Study'></i></a>";
                  return '';
                } else if (row.slideMapped || paramValue == "inActiveUser") {
                  return "<a class='imgicontable' id='editStudyIcon'><i class='fa fa-edit imageicon' title='Edit'></i></a>";
                } else {
                  return "<a class='imgicontable' id='deleteStudyIcon'><i class='fa fa-trash imageicon' title='Delete'></i></a><a class='imgicontable' id='editStudyIcon'><i class='fa fa-edit imageicon' title='Edit'></i></a>";
                }
              }
            },
            {
              title: "Study No", data: "studyNo", sWidth: "11%",
              render: function (data) {
                return "<div id='studyDetailIcon' class='orangeText' title='" + data + "'>" + data + "</div>"
              }
            },
            {
              title: "Study Title", data: "studyTitle", sWidth: "9%",
              render: function (data) {
                if (data) {
                  return "<div class='studyTitle' title='" + data + "'>" + data + "</div>"
                } else {
                  return "--"
                }
              }
            },
            {
              title: "Status", data: "status", sWidth: "10%", visible: paramValue != "inActiveUser",
              render: (data: any) => {
                //return this.commonService.camelCase(data).replace("_", " ");
                if (data == 'CREATION_DONE') {
                  return (
                    '<span title="Created">Created</span>'
                  );
                } else if (data == 'CREATION_ONGOING') {
                  return (
                    '<span title="Draft">Draft</span>'
                  );
                } else if (data == 'IN_PROGRESS') {
                  return (
                    '<span title="In Progress">In Progress</span>'
                  );
                } else if (data) {
                  return (
                    '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
                  );
                } else {
                  return '';
                }
              }
            },
            /* { title: 'Sacrifice', data: 'sacrifice' }, */
            {
              title: "Creation Date",
              data: "creationDate",
              sWidth: "18%",
              visible: paramValue != "inActiveUser",
              render: (data) => {
                return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
              }
            },
            {
              title: "Species", data: "species", sWidth: "8%", visible: paramValue != "inActiveUser",
              render: (data) => {
                return "<div class='speciesData' title='" + data + "'>" + data + "</div>";
              }
            },
            {
              title: "Pathologist", data: "pathologist", sWidth: "13%", visible: paramValue != "inActiveUser",
              render: (data) => {
                return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
              }
            },
            {
              title: "Technician", data: "technician", sWidth: "13%", visible: paramValue != "inActiveUser",
              render: (data) => {
                return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
              }
            },
            {
              title: "Peer Reviewer", data: "peerReviewer", sWidth: "13%", visible: paramValue != "inActiveUser",
              render: (data) => {
                return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
              }
            },
            {
              title: "User", data: "user", sWidth: "13%", visible: paramValue == "inActiveUser",
              render: (data) => {
                return "<div class='textEllipsis' title='" + data + "'>" + data + "</div>";
              }
            },
            {
              title: "Role", data: "role", sWidth: "13%", visible: paramValue == "inActiveUser",
              render: (data) => {
                if (data) {
                  let role = this.commonService.camelCase(data).replace("_", " ");
                  return "<div class='textEllipsis' title='" + role + "'>" + role + "</div>";
                } else {
                  return "";
                }
              }
            },
            {
              title: "Reassign", data: "peerReviewer", sWidth: "13%", visible: paramValue == "inActiveUser",
              render: (data, type, row, meta) => {
                let select = '<option selected value="noval">Select user</option>';
                if (row.role == 'PATHOLOGIST' || row.role == 'PRIMARY_INVESTIGATOR' || row.role == 'PEER_REVIEWER' || row.role == 'ADDITIONAL_PATHOLOGIST') {
                  $.each(this.pathologists, (key, value) => {
                    //select = select + '<mat-option [value]="' + value.userName + '">' + value.displayName + '</mat-option>';
                    select = select + '<option value="' + value.userName + '">' + value.displayName + '</option>';
                  })
                } else if (row.role == 'TECHNICIAN') {
                  $.each(this.technicians, (key, value) => {
                    //select = select + '<mat-option [value]="' + value.userName + '">' + value.displayName + '</mat-option>';
                    select = select + '<option value="' + value.userName + '">' + value.displayName + '</option>';
                  })
                } else if (row.role == 'STUDY_DIRECTOR') {
                  $.each(this.studyDirectors, (key, value) => {
                    //select = select + '<mat-option [value]="' + value.userName + '">' + value.displayName + '</mat-option>';
                    select = select + '<option value="' + value.userName + '">' + value.displayName + '</option>';
                  })
                }

                //let $matSelect = $('<mat-form-field class="full-width gselect bgremove"><mat-label class="labelbg">Pathologist<span class="mandatory">*</span></mat-label><mat-select>' + select +'</mat-select></mat-form-field>');
                let $matSelect = $('<select class="form-control gselect bgremove reassignSelect">' + select + '</select>');
                return $matSelect[0].outerHTML;
              }
            },
            {
              title: "",
              data: "",
              sWidth: "5%",
              bSearchable: false,
              bSortable: false,
              visible: paramValue == "inActiveUser",
              render: function (data: any, type: any, row: any, meta: any) {
                return '<button class="btn-default searchbtn disableFunction" id="saveReassignedUser">Save</button>'
                //return '<i class="fa fa-floppy-o disableFunction saveReassigned" style="font-size: 1.1vw;" id="saveReassignedUser" aria-hidden="true"></i>'
              }
            }
          ]
        }
      }, err => {
        this.noTableData = true;
        this.dataTable = '';
        this.showLoader = false;
      }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false
          this.dataTable = $(this.table.nativeElement);
          this.dataTable.DataTable(this.dtOptions);
        } else {
          this.noTableData = true;
          this.dataTable = '';
        }
        this.showLoader = false;
      });
    });
  }

  showDetails(data: any) {
    this.commonService.ProjectLSData.isTCRStudy = data.isTCRStudy;
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study/" + this.activeRole + "/details/" + data.studyGuid);
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/details/" + data.studyGuid]);
    // this.router.navigate(['/dashboard/study/details/'+studyId]);
  }

  mapImage(studydata, param, target) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = { "locationMapping": param, "studyData": studydata }
    const dialogref = this.dialog.open(LocationMappingDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      studydata.thumbnailGenStatus = true;
      var parentTr = $(target).parents("tr");
      this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
      setTimeout(() => {
        this.studyListingService.addLocatioMapping(result).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          studydata.mappedImagesCount = res.data.mappedSlideCount;
          studydata.unmappedImagesCount = res.data.unMappedSlideCount;
          //studydata.thumbnailGenStatus = true;
          studydata.status = res.data.studyStatus;
          this.techinicainStudyNos.push(studydata.studyGuid);
          this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
        },
          e => {
            studydata.thumbnailGenStatus = false;
            this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
          });
      }, 100);
    })

  }

  mapReport(studydata) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = { "studyData": studydata }
    const dialogref = this.dialog.open(MappingReportDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result) {

      }
    })
  }

  saveReassignedData(dataObj) {
    this.studyListingService.reassignUser(dataObj).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      /* //$(element.currentTarget).parents("tr").remove();
      $.fn.dataTable.ext.search.push(
        (settings, data, dataIndex) => {
          return !(this.dataTable.DataTable().row(dataIndex).data().studyGuid == dataObj.studyGuid &&
            this.dataTable.DataTable().row(dataIndex).data().role == dataObj.role);
        }
      );
      this.dataTable.DataTable().draw(); */
      this.getStudyDirectorStudies(this.activeTab);
    })
  }

  changeStudyStatus(studyGuid, status) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE_HEADER"),
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Ok"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.studyListingService.changeStudyStatus(studyGuid, status).subscribe((res: any) => {
          if (this.activeRole.toLowerCase() == "pathologist") {
            this.getPathologistStudies(this.activeTab);
          } else {
            this.getStudyDirectorStudies(this.activeTab);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.technicianInterval) {
      clearInterval(this.technicianInterval);
    }
  }
}
