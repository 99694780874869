import { AppConfigService } from './../app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private config: any;
  constructor(private http: HttpClient, private cookieService: CookieService, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getUserInfo() {
    return this.http.get(`${this.config.apiUrl}user/userinfo`);
  }

  getUserRoles() {
    return this.http.get(`${this.config.apiUrl}user/roles`);
  }

  sendEmailForVerification(email: string) {
    return this.http.get(`${this.config.apiUrl}user/forgetUserPassword?userId=${email}`);
  }

  sendVerificationCode(userdata: any) {
    return this.http.post(`${this.config.apiUrl}user/verifyCode`, userdata);
  }

  resetPassword(user: any) {
    return this.http.post(`${this.config.apiUrl}user/resetPassword`,user);
  }
}