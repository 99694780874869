import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GalleryService } from '../gallery/gallery.service';
import { AppConfigService } from '../app-config.service';
import { CommonService } from '../common.service';
import { Papa } from 'ngx-papaparse';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-randomized-image-detail',
  templateUrl: './randomized-image-detail.component.html',
  styleUrls: ['./randomized-image-detail.component.less']
})
export class RandomizedImageDetailComponent implements OnInit {
  slidelist:any=[];
  slideInfoFieldList = [
    [{key: 'slidePk',title:'Slide ID'}, {title:'Image Name',key:'slidename'}],
    [{title:'Subject Id',key:'animalNo'}, {title:'Stain',key:'stain'}],
    [{title:'Dosage Group',key:'dosageLevel'}, {title:'Sex',key:'sex'}],
    [{title:'Dose',key:'dose'}, {title:'Dosage Units',key:'dosageUnit'}],
    [{title:'Created Date',key :'scanDateTime'}, {title:'Magnification',key:'scanMagnification'}],
    [{title:'Location',key:'loacationPath'}],
    [{title:'Tissue',key:'organ'}]
  ];
  startIndex: any = 0;
  endIndex: any = 0;
  totalImageCount = 0;
  noImgDisplayed= 0;
  config:any;
  thumbnailUrl:any;
  defaultMacroImagePath: any;
  constructor(@Optional() private dialogRef: MatDialogRef<RandomizedImageDetailComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public configData: any,public galleryService:GalleryService,public appConfigService:AppConfigService,public commonService:CommonService, private papa: Papa) { 
  this.slidelist= this.galleryService.selectedSlidesArr;
  this.config = appConfigService.config;
    //     "opened": null,
//     "slidePk": 43,
//     "readStatus": "UNREAD",
//     "reviewStatus": "UNREAD",
//     "animalNo": "101",
//     "animalId": null,
//     "dosageLevel": "Control",
//     "sex": "M",
//     "cassetteCode": "2",
//     "organ": [
//         "Spleen",
//         "Lung",
//         "Heart",
//         "Thymus"
//     ],
//     "unTouched": null,
//     "identifier": "1177enzy",
//     "slidename": "TOX05-0065_00101-2.ndpi",
//     "slideLabel": null,
//     "dosageCode": null,
//     "dose": null,
//     "dosageUnit": null,
//     "scanDateTime": null,
//     "scanMagnification": null,
//     "stain": "H&E",
//     "stainId": null,
//     "ihcStain": null,
//     "chromogen": null,
//     "targetAntigen": null,
//     "ihcExperimentNumber": null,
//     "primaryAntibodyID": null,
//     "secondaryAntibodyID": null,
//     "detectionMethod": null,
//     "slideComments": null,
//     "mappingDate": null,
//     "dosageControlGroup": false,
//     "physicalLoc": null,
//     "slideControl": "Test",
//     "slideMapped": true,
//     "organName": [
//         null,
//         null,
//         null,
//         null
//     ]
// }
  }

  ngOnInit(): void {
    // this.baseProjName = this.commonService.webViewerParentAppObj.viewerBaseName;
    // this.parentUrl = this.commonService.webViewerParentAppObj.urlParent;
    // this.defaultMacroImagePath = '../../' + this.baseProjName + 'assets/images/viewer_icon/default_img.svg';
    this.defaultMacroImagePath = "../../../" + this.config?.appBaseName + "assets/images/viewer_icon/gallery_noimg.png";
    this.thumbnailUrl = `${this.config.thumbnailUrl}`;
    this.dialogRef.disableClose = true;
    this.totalImageCount = this.slidelist.length;
    this.noImgDisplayed = 3;
    this.endIndex = this.totalImageCount > this.noImgDisplayed ?  this.noImgDisplayed : this.totalImageCount;
    console.log(this.galleryService.selectedSlidesArr[0].loacationPath)
    console.log(this.galleryService.selectedSlidesArr[0].loacationPath);
    
  }

  closeDialog() {
    this.dialogRef.close();
  }

  navNext() {
    this.startIndex =  this.startIndex + this.noImgDisplayed  ;
    this.endIndex = this.totalImageCount > (this.endIndex + this.noImgDisplayed) ? (this.endIndex + this.noImgDisplayed) : this.totalImageCount ;
  }

  navPrev() {
   this.startIndex = this.startIndex - this.noImgDisplayed;
   this.endIndex =   this.totalImageCount > (this.startIndex + this.noImgDisplayed) ?  (this.startIndex + this.noImgDisplayed) : this.totalImageCount;
  }

  downloadExcel() {
    let datePipe = new DatePipe('en-US');
    let excelData = this.slidelist.map((indvSlide: any) => {
      // indvSlide['Score'] = this.viewerService.randomisationInfo.randomizationScoreObj[indvSlide.imageId];
      return {
        "Score": (this.commonService.ProjectLSData['randomizationScoreObj']? this.commonService.ProjectLSData['randomizationScoreObj'][indvSlide.slidePk]? this.commonService.ProjectLSData['randomizationScoreObj'][indvSlide.slidePk] : '-' : '-') ,
        "Dosage Group": indvSlide['dosageLevel']? indvSlide['dosageLevel']: '-',
        "Dose": indvSlide['dose']? indvSlide['dose'] : '-',
        "Dosage Units": indvSlide['dosageUnit']?  indvSlide['dosageUnit'] : '-',
        "Image Name": indvSlide.slidename? indvSlide.slidename :'-',
        "Slide ID": indvSlide['slidePk']?  indvSlide['slidePk'] : '-',
        "Sex": indvSlide['sex']? indvSlide['sex'] :'-',
        "Created Date": indvSlide['scanDateTime']? datePipe.transform(indvSlide['scanDateTime'],'medium') : '-',
        "Location": indvSlide['loacationPath']?  indvSlide['loacationPath'] : '-',
        "Tissue" :  indvSlide['organ']? indvSlide['organ'] : '-'
      };
    });
    this.writeCSVFile(excelData,this.configData.studyName);
  }

  private writeCSVFile(dataToWrite: any, fileName: String = 'Randomised Study') {
    let jsonArr = dataToWrite;
    let headers = Object.keys(jsonArr[0]);
    // headers = headers.map((header) => { return header.toUpperCase()});
    let allDataArray = [];
    allDataArray.push(headers)
    jsonArr.forEach((data: any) => {
      let eachData = [];
      for (let i = 0; i < headers.length; i++) {
        let value = data[headers[i]];
        if (value && (typeof value == "object")) { // this condition is added for annotation export only, it has no spl significance
          if (value.length) {
            let temp = ""
            value.forEach((val: any) => {
              val = JSON.stringify(val).replace("{", "").replace("}", "").replace(/["']/g, "").concat("\n");
              temp = temp + val;
            });
            value = temp;
          } else {
            let keys = Object.keys(value);
            let temp = "";
            for (let j = 0; j < keys.length; j++) {
              temp = temp + value[keys[j]];
              temp.replace(";", "").toString();
            }
            value = temp;
          }
        }
        eachData.push(value);
      }
      allDataArray.push(eachData);
    });

    let csv = this.papa.unparse(allDataArray);
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    // if (navigator["msSaveBlob"]) { // IE 10+
    // navigator["msSaveBlob"](blob, fileName + ".csv");
    // } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    // }
  }

}
