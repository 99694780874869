import { TranslateService } from '@ngx-translate/core';
import { GalleryDialogComponent } from './../gallery-dialog/gallery-dialog.component';
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { StudyDetailsComponent } from './../../study/study-details/study-details.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '../../common.service';
import * as XLSX from "xlsx";
import { SuccessDialogComponent } from '../../common/success-dialog/success-dialog.component';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { StudyListingComponent } from 'src/app/study/study-listing/study-listing.component';
import { StudyService } from 'src/app/study/study.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { AppConfigService } from 'src/app/app-config.service';
import { RandomizedImageDetailComponent } from 'src/app/randomized-image-detail/randomized-image-detail.component';

@Component({
  selector: 'app-galleryheader',
  templateUrl: './galleryheader.component.html',
  styleUrls: ['./galleryheader.component.css']
})
export class GalleryheaderComponent implements OnInit, OnDestroy {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  @ViewChild("mappingExcelUpload") mappingExcelUpload: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  files = [];

  selectedOpt: String = "study";
  studyProjectList: any = [];
  selectedStudyProj: any = "";
  selectedStudyName: any;
  groupBy: any = '5';
  galleryFilterisVisible: Boolean = false;
  mappedImgDropDownisVisible: Boolean = false;
  uploadedImgDropDownisVisible: Boolean = false;
  studyStainList: Array<String>;
  studyOrganList: Array<String>;
  studyOrganObj = {};
  studyAnimalList: Array<String>;
  studyDosageList: Array<String>;
  studyNormalList: Array<String>;
  customDateSC: Boolean = false;
  previewP: Boolean = false;
  // selectedSlides: any = [];
  // selectedSlidesObjForThumbnail = {};
  messageDuration = 2000;
  galleryFilter: any;
  uploadDataList: any;
  stainsF: any = [];
  organsF: any = [];
  animalsF: any = [];
  controlF: any = [];
  dosageF: any = [];
  uploadedViewBy: any = 'study';
  recentlyMappedImageList: any = [];
  fileUploadProgress = 0;
  uploadProgress = 0;
  slidesData: any;
  excelfileData: any;
  readStatus: any = null;
  reviewStatus: any = null;
  unmapFlag: boolean = false;
  unmappingStatus: boolean = false;
  thumbnailGenStatus: boolean = false;
  mappingStatus: boolean = false;
  discardingStatus: boolean = false;
  objectKeys = Object.keys;
  rawSlidesData: any = [];
  readReviewFlag: any = '';
  filterBySeverityGrading: any = '';
  disableCompare: boolean = false;
  isViewChnagedForRandomization: boolean = false;
  currentStudyGradingGrade: any = [];

  slideControl: any = [
    { optionId: 'Positive', optionName: 'Positive Control Slides' },
    { optionId: 'Negative', optionName: 'Negative Control Slides' },
    { optionId: 'Test', optionName: 'Test Slides' },
  ];
  slideDataObservable: any;


  @Input() activeMode: string;
  @Input() dialogData: any;
  quickFilterView: any = "-2";
  dosageArr = [];
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(public galleryService: GalleryService, private dialog: MatDialog, private router: Router, public appConfig: AppConfigService,
    private commonService: CommonService, public translate: TranslateService, private studyListingService: StudyService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    // this.galleryService.isStudyRandomised = this.commonService.ProjectLSData.isRandomisationStarted;
  }

  ngOnInit() {
    this.galleryService.setSlidesList({});
    this.galleryService.preview.pipe(takeUntil(this.destroy$)).subscribe((preview) => {
      this.previewP = preview;
    });
    /* this.galleryService.selecteSlidesList.pipe(takeUntil(this.destroy$)).subscribe((slides) => {
      this.selectedSlides = slides;
      this.showCompareOption();
    }); */
    /* this.galleryService.selecteSlidesListForThumbnail.pipe(takeUntil(this.destroy$)).subscribe((slidesObj) => {
      this.selectedSlidesObjForThumbnail = slidesObj;
    }) */
    this.commonService.uploadList.pipe(takeUntil(this.destroy$)).subscribe((uploadData: any) => {
      this.uploadDataList = uploadData;
    });
    this.commonService.uploadProgress.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.uploadProgress = value;
    })
    this.commonService.uploadListProgress.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.fileUploadProgress = value;
    })
    this.galleryService.refreshSlideList.pipe(takeUntil(this.destroy$)).subscribe((refreshVal: any) => {
      if (refreshVal) {
        this.unSubscribeDataObservable();
        this.getSlidesData();
      }
    });
    this.galleryService.galleryList.pipe(takeUntil(this.destroy$)).subscribe((galleryList) => {
      this.slidesData = galleryList.length;
    });

    if (this.activeMode == 'gallery' || this.activeMode == 'image-viewer' || this.activeMode == 'consultation' || this.activeMode == 'consultation-viewer') {
      // this.getSelectedSlides(() => {

      // });
      this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
        this.galleryService.allRandomisedResp = [];
        if (response?.length) {
          this.galleryService.allRandomisedResp = [...response];
        }
        this.selectOption(this.selectedOpt, 'init');
        // this.selectOption(this.selectedOpt, 'init');
      });
    } else {
      this.selectOption(this.activeMode, 'init');
    }
  }

  processAllRandomisedResp() {
    this.commonService.ProjectLSData.randomisedSlideIds = [];
    this.commonService.ProjectLSData.randomizationScoreObj = {};
    this.commonService.ProjectLSData.randomizationScore = [];
    this.galleryService.isStudyRandomised = false;
    this.commonService.ProjectLSData.isRandomisationStarted = false;
    for (let a = 0; a < this.galleryService.allRandomisedResp.length; a++) {
      if (!this.galleryService.allRandomisedResp[a].initSlides) {
        this.galleryService.allRandomisedResp[a].initSlides = this.galleryService.allRandomisedResp[a].slides ? [...this.galleryService.allRandomisedResp[a].slides] : this.galleryService.allRandomisedResp[a].slides;
        // this.galleryService.allRandomisedResp[a].initSlides = [...this.galleryService.allRandomisedResp[a].slides];
      }
      this.galleryService.allRandomisedResp[a].slides = this.galleryService.allRandomisedResp[a].initSlides.map((item: any) => {
        if (this.selectedStudyProj == this.galleryService.allRandomisedResp[a].study) {
          // this.galleryService.randomisedSelection = this.galleryService.randomisedStudies[a]?.slides;
          // this.galleryService.showLoader = true;

          this.galleryService.isStudyRandomised = true;
          this.commonService.ProjectLSData.isRandomisationStarted = true;
          this.commonService.ProjectLSData.randomizationScoreObj[item.slideId] = item.score ? item.score : null;
          this.commonService.ProjectLSData.randomisedSlideIds.push(item.slideId);
          this.commonService.setProjectLSData();
          this.galleryService.setSlidesList({ 'Randomised Slides': {} });

        }
        return item.slideId;
      });
    }
    this.galleryService.randomisedSelection = this.commonService.ProjectLSData.randomisedSlideIds;
    this.commonService.ProjectLSData.randomizationScore = this.galleryService.randomisedSelection.map((item: any) => {
      return {
        slideId: item,
        score: this.commonService.ProjectLSData.randomizationScoreObj[item]
      }
    });
    this.galleryService.randomisedStudies = this.galleryService.allRandomisedResp;
  }

  showCompareOption() {
    if (this.activeMode == 'gallery') {
      this.disableCompare = (this.galleryService.selectedSlidesArr.length < 2);
    }
    if (this.activeMode == 'image-viewer') {
      //get the no of slides from localstorage
      let storedSamples = this.commonService.ProjectLSData.samples;
      let samples_length = (storedSamples) ? storedSamples.length : 0;
      let allowedSamples = 4 - samples_length;
      this.disableCompare = (this.galleryService.selectedSlidesArr.length >= 1 && this.galleryService.selectedSlidesArr.length <= allowedSamples) ? false : true;
      //items - 4
    }
  }

  switchGridList() {
    let galleryFilterObj = this.commonService.ProjectLSData.galleryFilters;
    this.galleryFilter.selectedAccordion = galleryFilterObj.selectedAccordion;

    let gridListP;
    // this.galleryService.setSelecteSlidesList([]);
    this.galleryService.gridList.pipe(takeUntil(this.destroy$)).subscribe((gridList) => {
      gridListP = gridList;
    });
    if (gridListP == 'grid') {
      this.galleryService.switchGridList('list');
    } else {
      this.galleryService.switchGridList('grid');
    }
    this.galleryFilter.viewType = gridListP;
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
    this.galleryService.changePreview(false);
  }

  changePreview() {
    this.galleryService.changePreview(!this.previewP);
    setTimeout(this.setTitleDivWidth.bind(this), 200);
  }

  setTitleDivWidth() {
    $(".prevTitleDiv").width($(".previewaction_btn").width() - ($(".previewaction_btn .leftBtn").width() + $(".previewaction_btn .rightBtn").width() + this.commonService.vwTOpx('2.2vw') + (2 * $(".previewimgdet .prev").width())));
  }

  fatchStudySeverityGrading() {
    this.studyListingService.getStudySeverityGrading(this.selectedStudyProj).subscribe((response: any) => {
      if (response.success) {
        this.currentStudyGradingGrade = response.data;
      }
    });
  }

  selectOption(value, call: any) {
    this.selectedOpt = value;
    this.galleryService.changePreview(false);
    if (value == "study") {
      this.galleryService.getMyStudiesP().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.studyProjectList = response.data;
          if (response.data.length > 0) {
            this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
            if (this.galleryFilter.studyName) {
              this.selectedStudyProj = this.galleryFilter.studyName;
              if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare') {
                this.getDosageData(this.selectedStudyProj);
              }
            } else {
              this.selectedStudyProj = response.data[0].guid;
              this.galleryFilter.studyName = this.selectedStudyProj;
              this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
              this.commonService.setProjectLSData();
            }
            this.changeStudyProject(call);
            this.getFilterData();
            this.unSubscribeDataObservable();
            if (this.activeMode == 'image-viewer') {
              this.quickFilterView = this.dialogData && this.dialogData.dialogType == 'compare' ? this.dialogData.studyTypeTCR ? 'Positive' : 'CD' : "-2";
              // this.getSlidesListInViewer(this.quickFilterView);
            }
            this.getSlidesData();
          } else {
            this.galleryService.showLoader = false;

          }
        }
      });
    } else if (value == 'peer_review') {
      this.galleryService.getPeerStudies().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.studyProjectList = response.data;
          if (response.data.length > 0) {
            this.selectedStudyProj = response.data[0].guid;
            this.changeStudyProject(call);
          } else {
            this.galleryService.showLoader = false;
          }
        } else {
        }
      });
    } else if (value == 'projects') {
      this.galleryService.getMyProjects().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      });
    } else {
      // Technician code start 
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
      this.galleryService.getMyStudiesT(this.activeMode).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.studyProjectList = response.data;
            if (this.galleryFilter.studyName) {
              this.selectedStudyProj = this.galleryFilter.studyName;
            } else {
              this.selectedStudyProj = response.data[0].guid;
              this.galleryFilter.studyName = this.selectedStudyProj;
              this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
              this.commonService.setProjectLSData();
            }
            this.changeStudyProject(call);
            this.galleryService.setStudyListST(this.studyProjectList);
            this.getFilterData();
            if (this.activeMode != 'scanned' && this.activeMode != 'uploaded') {
              this.unSubscribeDataObservable();
              this.getSlidesData();
            }

            if (this.activeMode == 'mapped') {
              this.checkUnmapStatus();
            }
          } else {
            this.galleryService.showLoader = false;
          }
        } else {
          // console.log("error Handling");
        }
      });
      if (this.activeMode == 'scanned' || this.activeMode == 'uploaded') {
        this.unSubscribeDataObservable();
        this.getSlidesData();
      }
    }
  }

  changeStudyProject(call: any) {
    this.fatchStudySeverityGrading();

    let isSameStudy = true;
    if (this.galleryFilter.studyName != this.selectedStudyProj) {
      this.galleryFilter.studyName = this.selectedStudyProj;
      isSameStudy = false;
    }
    let selectedStudy = this.studyProjectList.filter(elem => {
      return elem.guid == this.selectedStudyProj
    });
    this.selectedStudyName = selectedStudy[0].studyNo;
    this.processAllRandomisedResp();
    if (this.galleryService.randomisedStudies?.length) {
      for (let a = 0; a < this.galleryService.randomisedStudies?.length; a++) {
        if (this.selectedStudyProj == this.galleryService.randomisedStudies[a].study) {
          this.galleryService.isStudyRandomised = true;
          this.galleryService.randomisedSelection = this.galleryService.randomisedStudies[a]?.slides;
          this.galleryService.showLoader = true;
          this.galleryService.getSlideDetailsArr(this.galleryService.randomisedSelection).pipe(finalize(() => this.galleryService.showLoader = false)).subscribe((successData: any) => {
            this.galleryService.selectedSlidesArr = [];
            for (let x = 0; x < successData.data.length; x++) {
              successData.data[x].id = successData?.data[x].slide.slidePk;
              successData.data[x].slidePk = successData?.data[x].slide.slidePk;
              successData.data[x].identifier = successData?.data[x].slide.identifier;
              successData.data[x].slidename = successData?.data[x]?.slide.slidename;
              successData.data[x].dosageLevel = successData?.data[x]?.animal.dosageLevel;
              successData.data[x].animalNo = successData?.data[x]?.animal.animalNo;
              successData.data[x].sex = successData?.data[x]?.animal.sex;
              successData.data[x].organ = successData?.data[x]?.cassetteOrgan.organ;
              successData.data[x].cassetteCode = successData?.data[x]?.cassetteOrgan.cassetteCode;
              this.galleryService.selectedSlidesArr.push(successData.data[x]);
            }
            if (this.galleryService.isStudyRandomised) {
              this.startRandomization(true);
            }
          });
          break;
        } else {
          this.galleryService.isStudyRandomised = false;
          this.galleryService.randomisedSelection = [];
          this.galleryService.selectedSlidesArr = [];
        }
      }
    } else {
      this.galleryService.isStudyRandomised = false;
      this.galleryService.randomisedSelection = [];
      this.galleryService.selectedSlidesArr = [];
    }
    if (!this.galleryService.isStudyRandomised) {
      this.galleryFilter.isTCRStudy = selectedStudy[0].isTCRStudy;
      this.galleryFilter.stains = isSameStudy ? this.galleryFilter.stains : [];
      this.galleryFilter.organ = isSameStudy ? this.galleryFilter.organ : [];
      this.galleryFilter.animalNo = isSameStudy ? this.galleryFilter.animalNo : [];
      this.galleryFilter.control = isSameStudy ? this.galleryFilter.control : [];
      this.galleryFilter.dosageCode = isSameStudy ? this.galleryFilter.dosageCode : [];
      this.galleryFilter.filterBySeverityGrading = isSameStudy ? this.galleryFilter.filterBySeverityGrading : 'Show All';
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.ProjectLSData.isTCRStudy = selectedStudy[0].isTCRStudy;
      this.commonService.setProjectLSData();
      this.getFilterData();
      if (this.activeMode == 'mapped') {
        this.checkUnmapStatus();
      }
      this.unSubscribeDataObservable();
      if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare') {
        this.getDosageData(this.selectedStudyProj);
        this.quickFilterView = this.dialogData && this.dialogData.dialogType == 'compare' ? 'CD' : this.quickFilterView
        this.getSlidesData();
      } else {
        this.getSlidesData();
      }
      if (!this.galleryService.isCalledFromViewer) {
        this.galleryService.activeAccordian = 0;
        // this.galleryFilter.selectedAccordion = null;
        this.galleryService.isCalledFromViewer = false;
      }
    }
  }

  unSubscribeDataObservable() {
    if (this.slideDataObservable) {
      this.slideDataObservable.unsubscribe();
    }
  }

  checkUnmapStatus() {
    if (this.selectedStudyProj) {
      this.galleryService.checkUnmapFlag(this.selectedStudyProj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unmapFlag = res.success;
        this.galleryService.setunMapFLag(this.unmapFlag);
      });
    }
  }

  openStudyProjectDetailDialog() {
    if (this.selectedOpt == "projects") {
      console.log("project dtails popup");
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = "90%";
      dialogConfig.data = { 'showHeader': true, 'studyGuid': this.selectedStudyProj }
      this.dialog.open(StudyDetailsComponent, dialogConfig);
    }
  }

  getFilterData() {
    if (this.activeMode == 'gallery' || this.activeMode == 'mapped' || this.activeMode == 'image-viewer') {
      this.getstudyStainList();
      this.getstudyOrganList();
      this.getstudyAnimalList();
      this.getDosageList();
      this.stainsF = [];
      this.organsF = [];
      this.animalsF = [];
      this.controlF = [];
      this.dosageF = [];
    }
  }

  getSlidesData() {
    if (this.galleryService.isStudyRandomised) {
      return
    }
    this.galleryService.changePreview(false);
    this.galleryService.setSlidesList({});
    // this.galleryService.setSelecteSlidesList([]);
    // this.galleryService.setSelectedSlidesListForThumbnail({});
    this.galleryService.showLoader = true;

    if (this.activeMode == 'uploaded') {
      this.slideDataObservable = this.galleryService.getSlidesListSTU().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.rawSlidesData = response.data;
          this.groupBy = this.galleryFilter.viewByU;
          this.getOrganisedData('');
        } else {
          this.rawSlidesData = [];
          this.galleryService.setSlidesList({});
        }
        this.galleryService.showLoader = false;
      });
    } else if (this.activeMode == 'scanned') {
      this.groupBy = this.galleryFilter.viewByS;
      this.slideDataObservable = this.galleryService.getFolderLocation().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.rawSlidesData = response.data;
          this.getOrganisedData('');
        } else {
          this.rawSlidesData = [];
          this.galleryService.setSlidesList({});
        }
        this.galleryService.showLoader = false;
      });

    } else {
      /* this.slideDataObservable = this.galleryService.getSlidesList(this.selectedStudyProj).subscribe((response: any) => {
        if (response.success) { 
          this.rawSlidesData = response.data;*/
      this.groupBy = (!this.galleryFilter.isTCRStudy && this.galleryFilter.viewByG == '6') ? '5' : this.galleryFilter.viewByG;
      this.filterBySeverityGrading = this.galleryFilter.filterBySeverityGrading ?? 'Show All';
      // this.filterBySeverityGrading = this.galleryFilter.filterBySeverityGrading;
      this.readStatus = this.galleryFilter.readStatus;
      this.reviewStatus = this.galleryFilter.reviewStatus;
      this.readReviewFlag = this.galleryFilter.readReviewFlag;
      this.stainsF = this.galleryFilter.stains;
      this.animalsF = this.galleryFilter.animalNo;
      this.organsF = this.galleryFilter.organ;
      this.controlF = this.galleryFilter.control;
      this.dosageF = this.galleryFilter.dosageCode;
      this.getAccordionData();
    }
  }

  getAccordionData(isDropDownChanged = false) {
    if (isDropDownChanged) {
      this.galleryService.activeAccordian = 0;
      // this.galleryService.setSelecteSlidesList([]);
      // this.galleryService.setSelectedSlidesListForThumbnail({});

      this.galleryFilter.selectedAccordion = "";
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
    }

    let orderby, stain = [], tissue = [], subjectid = [], control = [], dosage = [], controlDosage = false, callmetadaApi = false;
    if (this.activeMode == 'image-viewer' && ((this.quickFilterView == -1) || (this.quickFilterView == "-2"))) {
      this.galleryFilter.quickFilter = this.quickFilterView;
      callmetadaApi = true;
      if (this.quickFilterView == -1) {
        orderby = 'tissue';
        tissue = this.dialogData.organArr;
      } else {
        orderby = 'subjectId';
        subjectid = [this.dialogData.animalId]
      }
    } else if (this.activeMode == 'image-viewer' && this.quickFilterView == "CD") {
      callmetadaApi = false;
      this.galleryFilter.quickFilter = this.quickFilterView;
      orderby = 'dosage';
      controlDosage = true;
    } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && !this.dialogData.studyTypeTCR && this.quickFilterView != 'viewerCustom') {
      callmetadaApi = false;
      this.galleryFilter.quickFilter = this.quickFilterView;
      orderby = 'dosage';
    } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && this.dialogData.studyTypeTCR && this.quickFilterView != 'viewerCustom') {
      callmetadaApi = false;
      this.galleryFilter.quickFilter = this.quickFilterView;
      orderby = 'subjectId';
    } else {
      callmetadaApi = true;
      orderby = this.galleryService.getGroupByString(this.groupBy);
      this.galleryFilter.viewByG = this.groupBy;
      this.galleryFilter.readReviewFlag = this.readReviewFlag;
      this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
      this.galleryFilter.stains = this.stainsF;
      stain = this.stainsF;
      this.galleryFilter.organ = this.organsF;
      tissue = this.organsF;
      this.galleryFilter.animalNo = this.animalsF;
      subjectid = this.animalsF;
      this.galleryFilter.control = this.controlF;
      control = this.controlF;
      this.galleryFilter.quickFilter = this.quickFilterView;
      dosage = this.dosageF;
      this.galleryFilter.dosageCode = this.dosageF;
    }
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
    if (callmetadaApi) {
      let searchBy = {
        "mainFilter": [],
        "subfilters": {
          "tissue": tissue,
          "cassetteStain": stain,
          "subjectId": subjectid,
          "tcrSlideControl": control,
          "dosage": dosage
        },
        "dosageControlGroup": controlDosage,
        "slidesMapped": this.galleryService.showOnlyMappedSlides,
        "viewByMore": this.galleryService.getReviewFilterString(this.readReviewFlag),
        "severityGrading": this.galleryFilter.filterBySeverityGrading
      }
      this.slideDataObservable = this.galleryService.getSlideAccordionData(this.selectedStudyProj, orderby, searchBy)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          if (response.success) {
            this.rawSlidesData = response.data;
            //this.getOrganisedData('');
            this.generateAccordionObj();
          } else {
            this.rawSlidesData = [];
            this.galleryService.setSlidesList({});
          }
          this.galleryService.showLoader = false;
        });
    } else {
      this.rawSlidesData = [{ attribute: this.quickFilterView == "CD" ? "Control Group" : this.quickFilterView }];
      this.generateAccordionObj();
      this.galleryService.showLoader = false;
    }
    this.toggleFilter();
  }
  generateAccordionObj() {
    let slidesData = {};
    $.each(this.rawSlidesData, (key, value) => {
      slidesData[value.attribute] = {};
    });
    this.galleryService.setSlidesList(slidesData);
  }
  getOrganisedData(advFilter) {
    this.galleryService.activeAccordian = 0;
    this.galleryService.changePreview(false);
    // if (this.activeMode != 'image-viewer') {
    //   this.galleryService.setSelecteSlidesList([]);  
    // }
    this.galleryService.showLoader = true;

    if (advFilter) {
      this.toggleFilter();
    }

    let slidesData = {};
    let checkKey = '';

    if (this.activeMode == 'scanned') {
      $.each(this.rawSlidesData, (key, value) => {
        slidesData[value.name] = {
          "alreadyrender": false,
          "slidesArr": [],
          "id": value.id
        }
      });
      this.galleryService.switchGridList('list');
      this.galleryFilter.viewType = 'list';
      this.galleryFilter.viewByS = this.groupBy;
    } else {
      $.each(this.rawSlidesData, (key, value) => {
        let inFlag = true;
        value.state = false;
        if (value.sex == 'M') {
          value.sexId = "1"
        } else if (value.sex == 'F') {
          value.sexId = "2"
        } else {
          value.sexId = "3"
        }

        if (this.activeMode == 'image-viewer' && ((this.quickFilterView == -1) || (this.quickFilterView == "-2"))) {
          checkKey = (this.quickFilterView == -1) ? 'Subject ID:' : 'Tissues:';
          if (this.quickFilterView == -1) {
            if (this.dialogData.organArr.length > 0 && !(this.dialogData.organArr.some(r => value.organ.includes(r)))) {
              inFlag = false;
            }
          } else {
            if (this.dialogData.animalId != value.animalId) {
              inFlag = false;
            }
          }
        } else if (this.activeMode == 'image-viewer' && this.quickFilterView == "CD") {
          checkKey = value.dosageCode;
          if (!value.dosageControlGroup) {
            inFlag = false;
          }
        } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && this.quickFilterView != 'viewerCustom') {
          if (this.dialogData.studyTypeTCR) {
            checkKey = value.slideControl;
          } else {
            checkKey = value.dosageCode;
          }
          if (this.quickFilterView != checkKey) {
            inFlag = false;
          }
        } else {
          if (this.groupBy == 5) { // view by subject Id
            checkKey = value.animalNo;
          } else if (this.groupBy == 3) { // view by tissue
            checkKey = value.organ.join(", ");
          } else if (this.groupBy == 1) { // view by dosage
            checkKey = value.dosageCode;
          } else if (this.groupBy == 2) { // view by sex
            checkKey = value.sexId;
          } else if (this.groupBy == 0) { // view by cassttee
            checkKey = value.cassetteCode;
          } else if (this.groupBy == 6) { // view by targetAntigen
            checkKey = value.targetAntigen;
            if (checkKey == null) {
              checkKey = 'Not Known';
            }
          } else if (this.groupBy == 'week') {
            var d = new Date(value.uploadedDate);
            var firstday = new Date(d.setDate(d.getDate() - (d.getDay() - 1)));
            checkKey = 'MONDAY ' + firstday.getFullYear() + '-' + (firstday.getMonth() + 1) + '-' + firstday.getDate();;
          } else if (this.groupBy == 'month') {
            var d = new Date(value.uploadedDate);
            let n = d.getMonth();
            checkKey = this.months[d.getMonth()] + ' ' + d.getFullYear();
          } else if (this.groupBy == 'year') {
            var d = new Date(value.uploadedDate);
            checkKey = d.getFullYear().toString();
          } else if (this.groupBy == 'quarter') {
            var d = new Date(value.uploadedDate);
            var month = d.getMonth();
            var year = d.getFullYear();
            var quarter;
            if (month < 4)
              quarter = 1;
            else if (month < 7)
              quarter = 2;
            else if (month < 10)
              quarter = 3;
            else if (month < 13)
              quarter = 4;
            if (quarter == 1) {
              checkKey = 'JANUARY To MARCH ' + year;
            } else if (quarter == 2) {
              checkKey = 'APRIL To JUNE ' + year;
            } else if (quarter == 3) {
              checkKey = 'JULY To SEPTEMBER ' + year;
            } else if (quarter == 4) {
              checkKey = 'OCTOBER To DECEMBER ' + year;
            }
          }

          if (this.activeMode == 'gallery') {
            if (this.readReviewFlag) {
              if (this.readReviewFlag == 'READ' || this.readReviewFlag == 'UNREAD') {
                if (value.readStatus != this.readReviewFlag) {
                  inFlag = false;
                }
              } else {
                if (value.reviewStatus != this.readReviewFlag) {
                  inFlag = false;
                }
              }
            }
          }

          if (this.activeMode == 'gallery' || this.activeMode == 'mapped' || this.activeMode == 'image-viewer') {
            if (this.stainsF.length > 0 && (this.stainsF.indexOf(value.stainId) == -1)) {
              inFlag = false;
            }
            if (this.animalsF.length > 0 && (this.animalsF.indexOf(value.animalId) == -1)) {
              inFlag = false;
            }
            if (this.organsF.length > 0 && !(this.organsF.some(r => value.organ.includes(r)))) {
              inFlag = false;
            }
            if (this.controlF && this.controlF.length > 0 && !(this.controlF.some(r => value.slideControl.includes(r)))) {
              inFlag = false;
            }
            if (this.dosageF.length > 0 && (this.dosageF.indexOf(value.dosageCode) == -1)) {
              inFlag = false;
            }
          }
        }


        if (inFlag) {
          if (checkKey in slidesData) {
            slidesData[checkKey].slidesArr.push(value);
          } else {
            if (this.groupBy == 0) {
              slidesData[checkKey] = {
                "alreadyrender": false,
                "organ": value.organ,
                "slidesArr": [value]
              };
            } else {
              slidesData[checkKey] = {
                "alreadyrender": false,
                "slidesArr": [value]
              };
            }
          }
        }
      });

      if (this.activeMode == 'uploaded') {
        this.galleryFilter.viewByU = this.groupBy;
      } else {
        this.galleryFilter.viewByG = this.groupBy;
        this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
        this.galleryFilter.readReviewFlag = this.readReviewFlag;
        this.galleryFilter.stains = this.stainsF;
        this.galleryFilter.organ = this.organsF;
        this.galleryFilter.animalNo = this.animalsF;
        this.galleryFilter.control = this.controlF;
        this.galleryFilter.dosageCode = this.dosageF;
      }
    }
    this.galleryService.showLoader = false;
    //updated to resolve epl sorting issue
    //sorting order is Accordion - alphanumeric, inside data - sex(M,F,N) with sorted animalno
    let ordered = Object.keys(slidesData).sort(
      (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      }
    ).reduce((obj, key) => {
      let a = slidesData[key].slidesArr.sort((a, b) => {
        return a.animalNo.localeCompare(b.animalNo, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      });
      slidesData[key].slidesArr = this.commonService.sortByMultiKey(a, ['sexId']);
      obj[key] = slidesData[key];
      return obj;
    }, {});
    if (this.groupBy == 2) {
      for (let key in ordered) {
        if (key == '1') {
          ordered['M'] = ordered[key];
        } else if (key == '2') {
          ordered['F'] = ordered[key];
        } else {
          ordered['N'] = ordered[key];
        }
        delete ordered[key];
      }
    }
    this.galleryService.setSlidesList(ordered);
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
  }



  openSplitView() {
    if (this.galleryService.selectedSlidesArr.length > 4) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.GALLERY.COMPARE"));
    } else {
      let slidesIdArr: any = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
      this.galleryService.getSlideDetailsArr(slidesIdArr).subscribe((successData: any) => {
        let slideIds: any = [];
        for (let x = 0; x < successData.data.length; x++) {
          successData.data[x].id = successData?.data[x].slide.slidePk;
          successData.data[x].identifier = successData?.data[x].slide.identifier;
          successData.data[x].slidename = successData?.data[x]?.slide.slidename;
          slideIds.push(successData.data[x].id);

          this.galleryService.changeOpenStatus(successData.data[x].id, { "opened": true }).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          });
        }
        this.commonService.setViewerOpenedFrom(this.activeMode);
        this.commonService.ProjectLSData['ViewerSlides'] = successData.data;

        // this.galleryFilter.selectedAccordion = selectedAccordionText;
        // this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
        // this.commonService.setProjectLSData();
        // this.router.navigate(['WebViewer/' + slideIds.join()]);
        this.router.navigate(['WebViewer']);

        // this.galleryService.setSelecteSlidesList([]);
      });
    }
  }

  toggleFilter() {
    this.galleryFilterisVisible = !this.galleryFilterisVisible;
  }

  resetFilter() {
    this.stainsF = [];
    this.organsF = [];
    this.animalsF = [];
    this.dosageF = [];
    this.getAccordionData();
  }

  toggleMappedDD() {
    if (!this.mappedImgDropDownisVisible) {
      this.galleryService.getRecentlyMappedImages().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.recentlyMappedImageList = response.data;
        } else {
          this.recentlyMappedImageList = [];
        }
      });
    }
    this.mappedImgDropDownisVisible = !this.mappedImgDropDownisVisible;
    if (this.mappedImgDropDownisVisible) {
      this.uploadedImgDropDownisVisible = false;
    }
  }
  toggleUploadedDD() {
    this.uploadedImgDropDownisVisible = !this.uploadedImgDropDownisVisible;
    if (this.uploadedImgDropDownisVisible) {
      this.mappedImgDropDownisVisible = false;
    }
  }

  getstudyStainList() {
    this.galleryService.getStudyStainList(this.selectedStudyProj).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyStainList = response.data;
      } else {
        this.studyStainList = [];
      }
    });
  }

  getstudyOrganList() {
    this.galleryService.getstudyOrganList(this.selectedStudyProj).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyOrganList = response.data;
        this.createStudyOrganObj();
      } else {
        this.studyOrganList = [];
      }
    });
  }

  createStudyOrganObj() {
    this.studyOrganList.forEach((organElem: any) => {
      if (!this.studyOrganObj[organElem.organId]) {
        this.studyOrganObj[organElem.organId] = organElem.organ;
      }
    });
    this.galleryService.setStudyOrganObj(this.studyOrganObj);
  }

  getstudyAnimalList() {
    this.galleryService.getstudyAnimalList(this.selectedStudyProj).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyAnimalList = response.data;
      } else {
        this.studyAnimalList = [];
      }
    });
  }

  getDosageList() {
    this.galleryService.getstudyDossageList(this.selectedStudyProj).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyDosageList = response.data;
      } else {
        this.studyDosageList = [];
      }
    });
  }

  openMapDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "mapImage"
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      this.mappingStatus = true;
      let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
      let mapArr = [{
        "studySlideMappingModel": {
          "studyFk": result.studyGuId,
          "cstCodeFk": result.cassetteId,
          "slideFk": selectedSlideIdsArr[selectedSlideIdsArr.length - 1],
          "animalFk": result.animalId,
          "cstStainFk": result.stainId
        },
        "slideComment": result.comment
      }];
      this.galleryService.mapImageToStudy(mapArr).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unSubscribeDataObservable();
        this.getSlidesData();
        this.commonService.showToastMsg(res.message);
        // this.galleryService.setSelecteSlidesList([]);
        this.mappingStatus = false;
      });
    });
  }

  openDiscardDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "discardImage"
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      this.discardingStatus = true;
      let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
      let discardObj = {
        "slides": selectedSlideIdsArr,
        "comment": result
      }
      this.galleryService.discardImage(discardObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unSubscribeDataObservable();
        this.getSlidesData();
        this.commonService.showToastMsg(res.message);
        // this.galleryService.setSelecteSlidesList([]);
        this.discardingStatus = false;
      });
    });
  }

  openBulkUnMapDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "unMapImage",
      count: this.galleryService.selectedSlidesArr.length
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      this.unmappingStatus = true;
      let unMapDataArr = [];
      this.galleryService.selectedSlidesArr.forEach(slideData => {
        let unMapDataOj = {
          "studyFk": null,
          "cstCodeFk": null,
          "slideFk": slideData.slidePk,
          "animalFk": null,
          "cstStainFk": null
        }
        unMapDataArr.push(unMapDataOj);
      });
      let unMapObj = {
        "studySlideMappingModel": unMapDataArr,
        "slideComment": result
      }
      this.galleryService.bulkUnmapImages(unMapObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unSubscribeDataObservable();
        this.getSlidesData();
        this.commonService.showToastMsg(res.message);
        // this.galleryService.setSelecteSlidesList([]);
        this.unmappingStatus = false;
      });
    });
  }
  generateNewThumbnails() {
    this.thumbnailGenStatus = true;
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    this.galleryService.getSlidesPhysicalLocation(selectedSlideIdsArr).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      let slidesObj = {};
      res.forEach(element => {
        slidesObj[element.identifier] = element.path;
      });
      this.galleryService.generateThumbnail(slidesObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.commonService.showToastMsg(res.message);
        this.unSubscribeDataObservable();
        this.getSlidesData();
        //this.galleryService.setSelectedSlidesListForThumbnail({});
        this.thumbnailGenStatus = false;
      }, (err: any) => {
        this.commonService.showToastMsg(err?.error?.message ? err.error.message : "Error Occurs");
        this.thumbnailGenStatus = false;
      });
    });
  }
  openUploadDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "uploadImage"
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
  }

  onUploadClick() {
    this.files = [];
    for (let index = 0; index < this.fileUpload.nativeElement.files.length; index++) {
      const file = this.fileUpload.nativeElement.files[index];
      this.files.push({ data: file, inProgress: false, progress: 0, status: 'Queued' });
    }
    this.fileUpload.nativeElement.value = '';
    this.commonService.uploadFiles(this.files);
    if (!this.uploadedImgDropDownisVisible) {
      this.toggleUploadedDD();
    }
  }

  uploadMappingSheet(event: any) {
    let fileUploaded = event.target.files[0];
    // read excel
    let readFile = new FileReader();
    readFile.onload = e => {
      let storeData: any = readFile.result;
      var data = new Uint8Array(storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      this.excelfileData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      this.uploadData()
    };
    readFile.readAsArrayBuffer(fileUploaded);
    this.mappingExcelUpload.nativeElement.value = '';
  }
  private uploadData() {
    let excelEntries = [];
    let totalEntriesCount = 0;
    this.excelfileData.forEach((entry: any) => {
      let data = {
        animalNo: '',
        cassetteCode: '',
        comments: '',
        imageName: '',
        stain: '',
        studyNo: ''
      }
      data.animalNo = entry['Animal ID'] ? entry['Animal ID'] : null;
      data.cassetteCode = entry['Cassette Code'] ? entry['Cassette Code'] : null;
      data.comments = entry['Comments'] ? entry['Comments'] : null;
      data.imageName = entry['Image Reference / Image Name'] ? entry['Image Reference / Image Name'] : null;
      data.stain = entry['Stain'] ? entry['Stain'] : null;
      data.studyNo = entry['Study ID'] ? entry['Study ID'] : null;
      excelEntries.push(data);
    });

    totalEntriesCount = excelEntries.length;
    this.commonService.uploadMappingExcel(excelEntries).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message);
      } else {
        let resultCount = 0;
        let Entries = [];
        response.data.forEach((entry: any) => {
          let data = {
            'Image Reference / Image Name': '',
            'Study ID': '',
            'Animal ID': '',
            'Stain': '',
            'Cassette Code': '',
            'Comments': '',
            'Reason': ''
          };

          data['Image Reference / Image Name'] = entry.imageName;
          data['Study ID'] = entry.studyNo;
          data['Animal ID'] = entry.animalNo;
          data['Stain'] = entry.stain;
          data['Cassette Code'] = entry.cassetteCode;
          data['Comments'] = entry.comments == null ? '' : entry.comments;
          data['Reason'] = entry.reason;

          Entries.push(data);
        });

        resultCount = Entries.length;
        let successfullyMapped = totalEntriesCount - resultCount;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "30%";
        dialogConfig.data = {
          header: "Mapping Status",
          message: successfullyMapped == 0 ? "No Image Mapped.  Open downloaded MappingErrorLog.xlsx to view details" :
            successfullyMapped + " out of " + totalEntriesCount + " images mapped successfully. Open downloaded MappingErrorLog.xlsx to view details"
        };
        this.dialog.open(SuccessDialogComponent, dialogConfig);
        this.exprotToExcel(Entries);
      }
    }, (error: any) => {
      this.commonService.showToastMsg(error.error.message);
    });
    this.refreshListData();
  }

  private exprotToExcel(errorData: any) {
    let jsonArr = errorData;
    let jsonKeys = Object.keys(jsonArr[0]);
    let excelHeader = "<thead style='background-color:red;'>" + jsonKeys.map(e => "<td><b>" + e + "</b></td>").join("") + "</thead>";
    var excelBody = "<tbody>";
    jsonArr.forEach(e => {
      excelBody += "<tr>";
      jsonKeys.forEach(k => {
        excelBody += "<td>" + e[k] + "</td>";
      });
      excelBody += "</tr>";
    });
    excelBody += "</tbody>";
    let filename = "MappingErrorLog";
    var dataFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    var tableHTMLData = "<table>" + excelHeader + excelBody + "</table>";
    filename = filename ? filename + '.xls' : 'export_excel_data.xls';

    // Create download link element
    let downloadurl = document.createElement("a");

    document.body.appendChild(downloadurl);

    if (navigator["msSaveOrOpenBlob"]) {
      var blob = new Blob(['\ufeff', tableHTMLData], {
        type: dataFileType
      });
      navigator["msSaveOrOpenBlob"](blob, filename);
    } else {
      // Create a link to the file
      downloadurl.href = 'data:' + dataFileType + ', ' + tableHTMLData;

      // Setting the file name
      downloadurl.download = filename;

      //triggering the function
      downloadurl.click();
    }
  }

  openUnMapDialog(slideId) {
    this.toggleMappedDD();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "unMapImage",
      count: 1
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      let unMapArr = [
        {
          "studySlideMappingModel": {
            "studyFk": null,
            "cstCodeFk": null,
            "slideFk": slideId,
            "animalFk": null,
            "cstStainFk": null
          },
          "slideComment": result
        }
      ];
      this.galleryService.unMapImage(unMapArr).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.commonService.showToastMsg(res.message);
        this.galleryService.getFilterWiseListing();
      });
    });
  }

  cancelUpload(e, uploadData, index) {
    this.commonService.cancelFileUpload(uploadData, index);
  }

  refreshListData() {
    this.galleryService.getFilterWiseListing();
  }

  addImages() {
    // this.consultationservice.setSelectedImageValueOnDialogOpen(this.galleryService.getSelectedAddImage());
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    // this.galleryService.setSelecteSlidesList([]);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeReadStatus(status) {
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let dataObj = {
      "slideIds": selectedSlideIdsArr,
      "status": status
    }
    this.galleryService.changeReadStatus(dataObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      //this.galleryService.setupdateslideData(status);
      // this.galleryService.setSelecteSlidesList([]);
      this.getAccordionData();
    });
  }

  changeReviewStatus(status) {
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let dataObj = {
      "slideIds": selectedSlideIdsArr,
      "status": status
    }
    this.galleryService.changeReviewStatus(dataObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      //this.galleryService.setupdateslideData(status);
      // this.galleryService.setSelecteSlidesList([]);
      this.getAccordionData();
    });
  }

  getDosageData(studyId) {
    this.galleryService.getStudyDosageList(studyId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.dosageArr = res.data;
    })
  }

  onSearchChange(val) {
    this.galleryService.setViewerSearch(val);
  }

  changeStudyStatus(studyGuid, status) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE_HEADER"),
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Ok"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      // if (result) {
      //   this.studyListingService.changeStudyStatus(studyGuid, status).subscribe((res: any) => {
      //     if (this.activeRole.toLowerCase() == "pathologist") {
      //       this.getPathologistStudies(this.activeTab);
      //     } else {
      //       this.getStudyDirectorStudies(this.activeTab);
      //     }
      //   });
      // }
      if (result == true) (
        this.studyListingService.changeStudyStatus(studyGuid, status).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          // this.selectOption(this.selectedOpt);
          this.commonService.resetGalleryFilters();
          // this.selectOption();
          this.ngOnInit();

        }
        ));
    });
  }

  /* openInViewer() {
    // localStorage['viewerSlides'] = this.selectedSlides;
    // this.router.navigate(['WebViewer/' + this.selectedSlides.join()]);
      this.router.navigate(['WebViewer']);
  } */

  startRandomization(isCalledFromViewer: boolean = false) {
    let filterCriteria: any = {}
    this.galleryService.showLoader = false;
    this.galleryService.isStudyRandomised = true;
    if (!isCalledFromViewer) {
      this.galleryService.selectedSlidesArr = this.galleryService.selectedSlidesArr.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
      let slides: any = [];
      let randomizationScoreObj: any = {}
      this.galleryService.selectedSlidesArr.forEach((element: any) => {
        slides.push({
          slideId: element.slidePk,
          score: null
        });
        randomizationScoreObj[element.slidePk] = null;
      });
      this.galleryService.setRandomizedScore({
        study: this.selectedStudyProj,
        slides: slides,
        seed: 'XYZ',
        filterCriteria: this.commonService.ProjectLSData['galleryFilters']
      }).subscribe((res: any) => {
        this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
          this.galleryService.allRandomisedResp = [];
          if (response?.length) {
            this.galleryService.allRandomisedResp = [...response];
          }
          this.processAllRandomisedResp();

          /* for (let a = 0; a < response.length; a++) {
            response[a].slides = response[a].slides.map((item: any) => {
              return item.slideId;
            });
          }
          this.galleryService.randomisedStudies = [...response]; */
        });
        // this.commonService.ProjectLSData.randomizationScore = slides;
        // this.commonService.ProjectLSData.randomizationScoreObj = randomizationScoreObj;
      })
    }
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let randomisedStudy: any = {
      'studyName': this.selectedStudyProj,
      'selectedSlides': selectedSlideIdsArr
    }
    this.commonService.ProjectLSData.randomisedSlideIds = selectedSlideIdsArr;
    // this.commonService.ProjectLSData.randomisedStudies?.push(randomisedStudy);
    // this.commonService.ProjectLSData.randomisedSlideIds?.push(selectedSlideIdsArr);
    this.commonService.ProjectLSData.isRandomisationStarted = true;
    this.commonService.setProjectLSData();
    this.galleryService.setSlidesList({ 'Randomised Slides': {} });
    if (!this.galleryFilter) {
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters
    }
    if ((this.galleryFilter?.viewType == 'list')) {
      this.commonService.ProjectLSData["isListViewType"] = true;
      this.commonService.setProjectLSData();
      // this.isViewChnagedForRandomization = true;
      this.switchGridList();
    }

  }

  stopRandomization() {
    this.galleryService.isStudyRandomised = false;
    this.galleryService.selectedSlidesArr = [];
    delete this.commonService.ProjectLSData.randomisedSlideIds;
    this.commonService.ProjectLSData.isRandomisationStarted = false;
    this.commonService.setProjectLSData();
    this.getAccordionData();
    this.galleryService.deleteRanomizationScore(this.selectedStudyProj).subscribe((res: any) => {
      this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
        /* for (let a = 0; a < response.length; a++) {
          response[a].slides = response[a].slides.map((item: any) => {
            return item.slideId;
          });
        }
        this.galleryService.randomisedStudies = [...response]; */
        this.galleryService.allRandomisedResp = [];
        if (response?.length) {
          this.galleryService.allRandomisedResp = [...response];
        }
        this.processAllRandomisedResp();
      });
    })
    if (this.commonService.ProjectLSData["isListViewType"]) {
      this.switchGridList();
      this.isViewChnagedForRandomization = false;
      this.commonService.ProjectLSData["isListViewType"] = false;
      this.commonService.setProjectLSData();
    }
  }

  getSelectedSlides(callBack: any) {
    if (this.commonService.ProjectLSData.randomisedSlideIds?.length) {
      this.commonService.ProjectLSData.isRandomisationStarted = true;
      this.galleryService.showLoader = true;
      this.galleryService.getSlideDetailsArr(this.commonService.ProjectLSData.randomisedSlideIds).pipe(finalize(() => this.galleryService.showLoader = false)).subscribe((successData: any) => {
        this.galleryService.selectedSlidesArr = [];
        for (let x = 0; x < successData.data.length; x++) {
          successData.data[x].id = successData?.data[x].slide.slidePk;
          successData.data[x].slidePk = successData?.data[x].slide.slidePk;
          successData.data[x].identifier = successData?.data[x].slide.identifier;
          successData.data[x].slidename = successData?.data[x]?.slide.slidename;
          successData.data[x].dosageLevel = successData?.data[x]?.animal.dosageLevel;
          successData.data[x].animalNo = successData?.data[x]?.animal.animalNo;
          successData.data[x].sex = successData?.data[x]?.animal.sex;
          successData.data[x].organ = successData?.data[x]?.cassetteOrgan.organ;
          successData.data[x].cassetteCode = successData?.data[x]?.cassetteOrgan.cassetteCode;
          this.galleryService.selectedSlidesArr.push(successData.data[x]);
        }
        if (callBack) { callBack() }
        if (this.commonService.ProjectLSData.isRandomisationStarted) {
          this.startRandomization(true);
        }
      });
    } else {
      this.galleryService.selectedSlidesArr = [];
      this.commonService.ProjectLSData.isRandomisationStarted = false;
      this.galleryService.isStudyRandomised = false;
      if (callBack) { callBack() }
    }
  }

  openRandomizedImaeDetailPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = "78vw";
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      studyName: this.selectedStudyName
    }


    this.galleryService.selectedSlidesArr.map((slide: any, index: any) => {

      this.galleryService.getSelectedSlideDataForPreview(slide.slidePk).subscribe((res: any) => {
        slide.loacationPath = res?.data?.physicalLoc;
        slide.dosageLevel = res?.data?.dosageLevel;
        slide.scanMagnification = res?.data?.scanMagnification;
        slide.scanDateTime = res?.data?.scanDateTime;
        slide.dose = res?.data?.dose;
        slide.dosageUnit = res?.data?.dosageUnit;
        slide.stain = res?.data?.stain;
        if (index == (this.galleryService.selectedSlidesArr.length - 1)) {
          const dialogref = this.dialog.open(RandomizedImageDetailComponent, dialogConfig);
        }

      });


    });





  }
}
