import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/common.service';
import { ConfigurationService } from 'src/app/configuration/configuration.service';

@Component({
  selector: 'app-missing-stain-tissue',
  templateUrl: './missing-stain-tissue.component.html',
  styleUrls: ['./missing-stain-tissue.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MissingStainTissueComponent implements OnInit {
  organArr: any = [];
  stainArr: any = [];
  organAddedArr: any = [];
  stainAddedArr: any = [];
  isUserAdmin: boolean = false;
  constructor(@Optional() private dialogRef: MatDialogRef<MissingStainTissueComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigurationService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    for (let x = 0; x < this.commonService?.ProjectLSData?.userRoleList?.length; x++) {
      if (this.commonService.ProjectLSData.userRoleList[x].role == "APPLICATION_ADMIN") {
        this.isUserAdmin = true;
        break;
      }
    }
    this.organArr = this.data?.missingData?.missingOrgans;
    this.stainArr = this.data?.missingData?.missingStains;
  }

  addMissingMetaData(dataText: any, dataType: string) {
    let dataObj: any = {};
    dataObj[(dataType == 'organ') ? 'organ' : 'stain'] = dataText;
    this.configService[(dataType == 'organ') ? 'addOrgan' : 'addStain'](dataObj).subscribe((data: any) => {
      this[(dataType == 'organ') ? 'organAddedArr' : 'stainAddedArr'].push(dataText);
      this.data[(dataType == 'organ') ? 'organOptions' : 'stainsOptions'].push(dataText);
      if ((this.organArr?.length == this.organAddedArr?.length) && (this.stainArr?.length == this.stainAddedArr?.length)) {
        this.dialogRef.close("success");
      }
    });
    /* this.configService.addOrgan({ "organ": organText.trim() }).subscribe((organData: any) => {
      this.organAddedArr.push(organText);
    }, (err: any) => {
      this.commonService.showToastMsg(err.error.message);
    }); */
  }

  cancelDialog() {
    this.dialogRef.close("cancel");
  }

}
