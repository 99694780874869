<!--Tab and Search-->
<div class="page-content">
  <div class="pageheader">
    <div class="pageheadtitle" *ngIf="!((activeRole == 'STUDY_DIRECTOR') || (activeRole == 'APPLICATION_ADMIN'))">
      <h4> Study Listing
      </h4>
    </div>
    <div class="mdl-grid" *ngIf="activeRole == 'STUDY_DIRECTOR' || activeRole == 'study_director'">
      <div class="col-sm-8 myStudybardiv">
        <div class="mdl-grid myStudybar">
          <div class="col-sm-3 " (click)="getStudyDirectorStudies('created')"
            *ngIf="(activeRole != 'APPLICATION_ADMIN')">
            <h6 class="tabliketitle headerTabText" [ngClass]="{'active': mainActiveTab == 'study_listing'}">Study
              Listing
            </h6>
          </div>
          <div class="col-sm-3 " (click)="getStudyDirectorStudies('inActiveUser')">
            <h6 class="tabliketitle headerTabText" [ngClass]="{'active': mainActiveTab == 'reassign_role'}">Reassign
            </h6>
          </div>
          <!-- <div class="studySearchInputDiv" *ngIf="(activeRole == 'APPLICATION ADMIN')">
          <div class="input-group">
          <div class="studySearchMainDiv">
            <input type="text" class="tags" id="studysearch" placeholder="Search" [(ngModel)]="search" />
          </div>
          </div>
          </div> -->
        </div>
      </div>
      <div class="col-sm-4 myStudybardiv" *ngIf="(activeRole != 'APPLICATION_ADMIN')">
        <div class="float-right dropdown">
          <!-- <button type="button" class="btn-default searchbtn" *ngIf="activeRole == 'STUDY_DIRECTOR'"
          (click)="navigateToCreateStudy()">Create Study</button> -->
          
          <button *ngIf="activeRole == 'STUDY_DIRECTOR'" class="btn-default searchbtn dropdown-toggle" type="button"
            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Create Study
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="manualDrop">
              <a class="dropdown-header">Manual</a>
              <!-- <a class="dropdown-item" (click)="navigateToCreateStudy('TCR', 'manual')">TCR Study</a> -->
              <a class="dropdown-item" (click)="navigateToCreateStudy('Normal', 'manual')">TOX Study</a>
            </div>
            <div class="autoDrop">
              <a class="dropdown-header">Auto-Create</a>
              <a *ngIf="configService.config.isTCR" class="dropdown-item"
                (click)="navigateToCreateStudy('TCR', 'auto')">TCR Study</a>
              <a class="dropdown-item" (click)="navigateToCreateStudy('Normal', 'auto')">TOX Study</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- new HTML for study listing in ADMIN -->

    <div class="mdl-grid myStudybar" *ngIf="(activeRole == 'APPLICATION_ADMIN')">
      <div class="col-sm-3 " (click)="getStudyDirectorStudies('created')" *ngIf="(activeRole != 'APPLICATION_ADMIN')">
        <h6 class="tabliketitle headerTabText" [ngClass]="{'active': mainActiveTab == 'study_listing'}">Study
          Listing
        </h6>
      </div>
      <div class="col-sm-3 ">
        <h6 class="tabliketitle headerTabText" [ngClass]="{'active': mainActiveTab == 'reassign_role'}">Reassign
        </h6>
      </div>
      <div class="studySearchInputDiv" *ngIf="(activeRole == 'APPLICATION_ADMIN')" style="margin-left:auto;">
        <div class="input-group" style="padding:0.5vw">
          <div class="studySearchMainDiv">
            <input type="text" class="tags" id="studysearch" placeholder="Search" [(ngModel)]="search" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  code ends here -->
  <div class="mdl-grid" *ngIf="(activeRole != 'APPLICATION_ADMIN')">
    <div class="col-sm-7 myStudybardiv" *ngIf="activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'">
      <div class="mdl-grid myStudybar">
        <div class="col-sm-2 " (click)="getPathologistStudies('myStudy')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'myStudy'}">My Study
            (<span>{{myStudyCount}}</span>)
          </h6>
        </div>
        <!-- <div class="col-sm-4 borderbefore" (click)="getPathologistStudies('peerReviewer')">
            <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'peerReview'}">Peer Review
              (<span>{{peerStudyCount}}</span>)
            </h6>
          </div> -->
        <!-- <div class="col-sm-2 borderbefore" (click)="getPathologistStudies('pathoCompleted')">
            <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'pathoCompleted'}">Completed
              (<span>{{pathoCompletedStudyCount}}</span>)
            </h6>
          </div>
          <div class="col-sm-2 borderbefore" (click)="getPathologistStudies('pathoArchived')">
            <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'pathoArchived'}">Archived
              (<span>{{pathoArchivedStudyCount}}</span>)
            </h6>
          </div> -->
        <div class="col-sm-10"></div>
      </div>
    </div>
    <div class="col-sm-7 myStudybardiv" *ngIf="activeRole == 'STUDY_DIRECTOR' || activeRole == 'study_director'">
      <div class="mdl-grid myStudybar" *ngIf="mainActiveTab == 'study_listing'">
        <div class="col-sm-3" (click)="getStudyDirectorStudies('created')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'created'}">Created
            (<span>{{createdStudyCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-3 borderbefore" (click)="getStudyDirectorStudies('inProgress')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'inProgress'}">In Progress
            (<span>{{inprogressStudyCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-3 borderbefore" (click)="getStudyDirectorStudies('completed')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'completed'}">Completed
            (<span>{{completedStudyCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-3 borderbefore" (click)="getStudyDirectorStudies('archieved')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'archieved'}">Archived
            (<span>{{archievedStudyCount}}</span>)
          </h6>
        </div>
      </div>
    </div>
    <div class="col-sm-7 myStudybardiv " *ngIf="activeRole == 'TECHNICIAN' || activeRole == 'technician'">
      <!-- <h6 class="tabliketitle">Studies (<span>{{technicianStudyCount}}</span>)
        </h6> -->
      <div class="mdl-grid myStudybar">
        <div class="col-sm-4">
          <h6 class="tabliketitle active">My Study (<span>{{technicianStudyCount}}</span>)</h6>
        </div>
        <div class="col-sm-8 "></div>
      </div>
    </div>
    <div class="col-sm-5 filterinlineview no_m_p">
      <div class="input-group">
        <div class="input-group-prepend" *ngIf="activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'">
          <span class="input-group-text dropdown dropdown-small open" id="inputGroupPrepend">
            <a data-hover="dropdown" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"
              (click)="showHideFilter()" aria-label="filterdropdown"><span class="key"></span><span class="value">
              </span><!-- <b class="caret"></b> --></a>
          </span>
        </div>
        <ul class="dropdown-menu multiselectfilter" *ngIf="activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'">
          <form [formGroup]="searchForm">
            <li class="form-control-li">
              <label class="form-control-label" for="studytitle">Study No</label>
              <input type="text" class="form-control-field" id="studytitle" formControlName="title" />
            </li>
            <li class="form-control-li">
              <label class="form-control-label" for="species">Species</label>
              <select name="species" id="species" class="form-control-field" formControlName="species">
                <option *ngFor="let species of speciesArr" [ngValue]="species.species">{{species.species}}</option>
              </select>
            </li>
            <li class="form-control-li">
              <label class="form-control-label" for="creationDate">Date Created</label>
              <input type="date" name="creationDate" id="creationDate" class="form-control-field"
                formControlName="creationDate">
            </li>
            <li>
              <div class="row filterbtn">
                <!-- <div class="col-md-3 col-lg-3 col-xl-3"></div> -->
                <div class="col-sm-12 filterbtn">
                  <button type="button" (click)="onSearch()" class="btn-default searchbtn"
                    aria-label="searchbtn">Search</button>
                  <button type="button" (click)="onCancel()" class="btn-default" aria-label="cancelbtn">Close</button>
                  <button type="button" (click)="onReset()" class="btn-default" aria-label="resetbtn">Reset</button>
                </div>
              </div>
            </li>
          </form>
        </ul>
        <!-- <img (click)="getTechnicianStudies()" *ngIf="activeRole == 'TECHNICIAN' || activeRole == 'technician'" src="../../../assets/images/viewer_icon/Sync-Active.svg" alt="Refresh List" aria-label="Refresh List"
          class="uploadicon" /> -->
        <div class="studySearchMainDiv"
          [ngClass]="{'pathologistStudySearch': activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'}">
          <input type="text" class="tags" id="studysearch" placeholder="Search" [(ngModel)]="search" />
        </div>
        <!-- <i class="fa fa-search searchicon"></i> -->
      </div>
    </div>
  </div>
  <mat-spinner style="margin:0 auto;" *ngIf="showLoader"></mat-spinner>
  <div *ngIf="noTableData && !showLoader" class="row" id="preview_hidden" style="margin: 9rem auto;width: fit-content;">
    <img [src]="'../../../' + configService.config.appBaseName + 'assets/images/viewer_icon/no_study_project.png'" />
  </div>
  <div class="table-responsive">
    <table id="p_study_table" #dataTable class="hover row-border">
    </table>
  </div>
</div>