import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { AuthService } from "../auth/auth.service";

import { ErrorStateMatcher } from "@angular/material/core";
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from "../common.service";
// import { MustMatch } from './must-match.validator';
import { MustMatch, LengthValidator, CharacterValidator } from '../Input.Validators'
import { ConfirmDialogComponent } from "../common/confirm-dialog/confirm-dialog.component";
import { AppConfigService } from '../app-config.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  public email: String;
  public password: String;
  loginForm: FormGroup;
  formForgotPassword: FormGroup;
  formResetPassword: FormGroup;
  isResetPassword: boolean = false;
  submitted = false;
  loginServerError: String;
  loginSuccess: String;
  matcher: any;
  hide = true;
  meetingData: any = {};
  meetingParam: any = '';
  isForgotPassword: boolean = false;
  isMailSend: boolean = false;
  showSuccess: boolean = false;
  isVerificationSend: boolean = false;
  hideNewPass: boolean = true;
  hideRePass: boolean = true;
  showLoader: boolean = false;
  recoveryEmail: any;
  verficationCode: String;
  message: String = "";
  header: String = "Account Recovery";
  config: any;
  resetUser: any;
  defaultRoleSequenceArr = [
    "APPLICATION_ADMIN",
    "STUDY_DIRECTOR",
    "TECHNICIAN",
    "PATHOLOGIST"
   ]
  // tabID: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private configService: AppConfigService,
  ) {
    this.config = this.configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.authService.doLogoutUser();
    this.commonService.ProjectLSData = {};

    //const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
    // this.tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    // console.log(this.tabID);
    this.activatedRoute.queryParams.subscribe(params => {
      this.authService.clearLocalStorage();
      this.authService.removeTokens();
      this.loginForm = this.formBuilder.group({
        email: ["", [Validators.required, this.commonService.checkEmailPattern]],
        password: ["", [Validators.required]],
        displayName: [""]
      });
      this.matcher = new MyErrorStateMatcher();
      let paramsKey: any = Object.keys(params);
      if (paramsKey.length > 0) {
        this.resetUser = atob(paramsKey[0]);
        this.isResetPassword = true;
      } else {
        this.isResetPassword = false;
      }
    });
    this.formResetPassword = this.formBuilder.group({
      userName: [this.resetUser],
      newPassword: ["", [Validators.required, LengthValidator, CharacterValidator]],
      rePassword: ["", [Validators.required]]
    }, {
      validator: MustMatch('newPassword', 'rePassword'),
    });

    this.formForgotPassword = this.formBuilder.group({
      recoveryEmail: ["", [Validators.required, this.commonService.checkEmailPattern]], // change the key as required from BE
      verficationCode: ["", [Validators.required]],
      newPassword: ["", [Validators.required, LengthValidator, CharacterValidator]],
      rePassword: ["", [Validators.required]]
    }, {
      validator: MustMatch('newPassword', 'rePassword'),
    });

    this.loginForm.get("email").valueChanges.subscribe(value => {
      this.loginServerError = '';
    });

    this.loginForm.get("password").valueChanges.subscribe(value => {
      this.loginServerError = '';
    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get forgotPassFrm() {
    return this.formForgotPassword.controls
  }

  get resetPassFrm() {
    return this.formResetPassword.controls
  }

  onLogin() {
    this.loginServerError = "";
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(response => {
        if (response == "true") {
          //this.commonService.checkSessionActive();
          this.commonService.fetchProjectLSData();
          if (this.commonService.getLicensingModuleFlag() && this.commonService.ProjectLSData.chooseSession) {
            this.commonService.stopSessionActiveStatus();
            const dialogConfig = new MatDialogConfig();
            dialogConfig.width = "30%";
            dialogConfig.data = {
              headerTitle: "Choose Session",
              confirmMsg: this.translate.instant("GENERIC_MSG.LOGIN.END_PREVIOUS_SESSION"),
              cancelButtonText: "No",
              confirmButtonText: "Yes"
            };
            const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            dialogref.afterClosed().subscribe(result => {
              if (result && result != 'btnClose') {
                this.authService.activateSession().subscribe((res: any) => {
                  this.postLoginProcess();
                });
              } else {
                this.authService.logout().subscribe((response) => {
                  this.commonService.stopSessionActiveStatus();
                });
              }
            });
          } else {
            this.postLoginProcess();
          }
        } else {
          if (response == "invalid") {
            this.loginServerError = "Please enter a valid login credentials";
          } else if (response == "resetPassword") {
            this.resetUser = this.loginForm.value.email;
            this.formResetPassword.patchValue({
              userName: this.resetUser
            });
            this.isResetPassword = true;
          }
        }
      });
    } else {
      //  handled error through formbulider
    }
  }

  postLoginProcess() {
    if (this.commonService.getLicensingModuleFlag()) {
      this.commonService.checkSessionActive(this.commonService.getPingInterval());
    }
    this.loginService.getUserInfo().subscribe((res: any) => {
      if (res.success && res.data != "") {
        this.commonService.ProjectLSData.currentUser = res.data;
        this.commonService.setProjectLSData();
        this.loginService.getUserRoles().subscribe((res: any) => {
          if (res != "") {
            let roleArr = [];
            for(let i=0 ; i< this.defaultRoleSequenceArr.length; i++){
              if(res.includes(this.defaultRoleSequenceArr[i])){
                roleArr.push({
                  role: this.defaultRoleSequenceArr[i],
                  roleDisplayText: this.camelCase(this.defaultRoleSequenceArr[i])
                });
              }
             
            }
            // for (let i = 0; i < res.length; i++) {
              
            //   roleArr.push({
            //     role: res[i],
            //     roleDisplayText: this.camelCase(res[i])
            //   });
            // }
            this.loginSuccess = "true";
            this.commonService.ProjectLSData.userRoleList = roleArr;
            this.commonService.setProjectLSData();
            this.router.navigate(["/dashboard"]); // it will redirect to dashboard when dashboards are ready for each role
          } else {
            this.authService.logout();
            this.loginServerError = res.message;
          }
        });
      } else {
        this.authService.logout();
        this.loginServerError = res.message;
      }
    });
  }

  camelCase(str) {
    let newStr = str.replace(/[^A-Z0-9]+/gi, " ");
    // .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    //   return index == 0 ? word.toUpperCase() : word.toLowerCase();
    // });
    let splitStr = newStr.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  showForgetPassowrd() {
    this.isForgotPassword = true;
    this.loginServerError = '';
    this.message = "Enter your email ID to recieve a verification code";
  }

  sendEmail() {
    this.showLoader = true;
    this.loginService.sendEmailForVerification(this.formForgotPassword.value.recoveryEmail).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message);
        this.message = "Verification code successfully sent to " + this.formForgotPassword.value.recoveryEmail;
        this.showSuccess = true;
        this.showLoader = false;
      }
    }, (error: any) => {
      this.showLoader = false;
    });
  }

  proceed() {
    this.showSuccess = false;
    this.isMailSend = true;
    this.message = "Enter Verification code sent to " + this.formForgotPassword.value.recoveryEmail;
  }

  sendVerificationCode() {
    this.formForgotPassword.value.verficationCode = this.formForgotPassword.value.verficationCode.trim();
    let payload = {
      "userId": this.formForgotPassword.value.recoveryEmail,
      "verificationCode": this.formForgotPassword.value.verficationCode
    }
    this.loginService.sendVerificationCode(payload).subscribe((response: any) => {
      if (response.success) {
        this.message = "";
        this.header = "Reset Password"
        this.isVerificationSend = true
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.LOGIN.INVALID_VERIFICATION_CODE"));
      }
    });
  }

  sendNewPassword(isResetPassword: boolean = false) {
    let user = {
      "email": isResetPassword ? this.formResetPassword.value.userName : this.formForgotPassword.value.recoveryEmail,
      "password": isResetPassword ? this.formResetPassword.value.newPassword : this.formForgotPassword.value.newPassword,
    }
    this.loginService.resetPassword(user).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message + ". Please login again");
        this.resetForgetPasswordData();
        if (isResetPassword) {
          this.router.navigate(['/login']);
        }
      } else {
        this.commonService.showToastMsg(response.message ? response.message : this.translate.instant("GENERIC_MSG.LOGIN.FAILED"));
      }
    })
  }

  closeForgetPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: this.translate.instant("GENERIC_MSG.LOGIN.CLOSE_FORGOT_PASSWORD_HEADER"),
      confirmMsg: this.translate.instant("GENERIC_MSG.LOGIN.CLOSE_FORGOT_PASSWORD_MESSAGE"),
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.resetForgetPasswordData();
      }
    });
    // this.loginForm.reset();
  }

  resetForgetPasswordData() {
    this.isForgotPassword = false;
    this.isVerificationSend = false;
    this.isResetPassword = false;
    this.showSuccess = false;
    this.isMailSend = false;
    this.message = "";
    this.formForgotPassword.reset();
    this.formResetPassword.reset();
    this.loginForm.reset();
  }

  // lengthValidator(control: FormControl) {
  //   const lengthSeven = (control.value)?.trim().length < 7;
  //   const isValid = !lengthSeven;
  //   return isValid ? null : { 'lengthSeven': true };
  // }

  // characterValidator(control: FormControl) {
  //   let hasNumber = /\d/.test(control.value);
  //   let hasUpper = /[A-Z]/.test(control.value);
  //   //let hasLower = /[a-z]/.test(control.value);
  //   let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);
  //   // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
  //   const isValid = hasNumber && hasUpper /* && hasLower */ && hasSpecialCharacter;
  //   return isValid ? null : { 'character': true };
  // }
}
