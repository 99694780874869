import { CommonService } from '../../common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { StudyService } from '../study.service';
import { TranslateService } from '@ngx-translate/core';
import jspdf from 'jspdf';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { FileReaderService } from 'src/app/FileReader.service';
declare var $: any;

@Component({
  selector: 'app-mapping-report-dialog',
  templateUrl: './mapping-report-dialog.component.html',
  styleUrls: ['./mapping-report-dialog.component.css']
})

export class MappingReportDialogComponent implements OnInit {

  reportData: any = [];
  reportData1: any = [];
  initReportData: any = [];
  selectedImageType: any = 0;
  showLoader: boolean = true;
  disableSelect: boolean = false;
  isDownloadBtnClicked: boolean = false;
  studyType: string = 'TOX';

  reportHeaderArr: any = [
    { code: 'slideLabel', name: 'Slide ID', width: ['7', '9'], studyType: ['TOX', 'TCR'] },
    { code: 'animalNumber', name: 'Subject Id', width: ['7', '9'], studyType: ['TOX', 'TCR'] },
    { code: 'fileName', name: 'Image Name', width: ['17', '19'], studyType: ['TOX', 'TCR'] },
    { code: 'cassetteCode', name: 'Cassette Id', width: ['10'], studyType: ['TOX'] },
    { code: 'stain', name: 'Stain', width: ['7', '9'], studyType: ['TOX', 'TCR'] },
    { code: 'status', name: 'Status', width: ['7', '9'], studyType: ['TOX', 'TCR'] },
  ]

  constructor(@Optional() private dialogRef: MatDialogRef<MappingReportDialogComponent>,
    private fileReaderService: FileReaderService,
    public translate: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private studyService: StudyService,
    private commonService: CommonService) {
    dialogRef.disableClose = true;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {

    this.getReportData();
    $('#filterSearch').on('keyup click', (e) => {
      let searchVal = $(e.currentTarget).val();
      if (searchVal) {
        this.reportData = this.reportData1.filter(elem => {
          return elem.fileName.toLowerCase().includes(searchVal.toLowerCase())
        });
      } else {
        this.reportData = [...this.reportData1];
      }
    });
  }

  getReportData() {
    this.showLoader = true;
    this.disableSelect = true;
    this.studyService.getFolderLocationMappingReport(this.data.studyData.studyGuid, this.selectedImageType).subscribe((res: any) => {
      if (res.success) {
        this.reportData = res.data.slides;
        this.reportData1 = res.data.slides;
        this.initReportData = res.data.slides;
        this.showLoader = false;
        this.disableSelect = false;
        this.studyType = res.data.isTCRStudy ? 'TCR' : 'TOX';
      }
    }, (error) => {
      this.commonService.showToastMsg(this.translate.instant("WARNING.SERVER_ERROR"));
      // this.commonService.showToastMsg("Something went wrong. Please try again");
      this.showLoader = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  imageSelectionChanged(selectedValue) {
    this.selectedImageType = selectedValue;
    if (this.selectedImageType) {
      this.reportData1 = this.initReportData.filter(elem => {
        return (this.selectedImageType == 1) ? elem.slidemapped : !elem.slidemapped
      });
      this.reportData = [...this.reportData1];
    } else {
      this.reportData = [...this.initReportData];
      this.reportData = [...this.initReportData];

    }
    //this.getReportData();
    $('#filterSearch').val('');
  }

  downloadMappingReport1() {
    // let data = $(".studyReport").css("height", "auto").find(".slideTableBodyDiv").css("height", "auto").closest(".studyReport");
    var strFrameName = "Report-" + (new Date()).getTime();

    if (window.location.hostname !== document.domain && navigator.userAgent.match(/msie/i)) {
      var iframeSrc = "javascript:document.write(\"<head><script>document.domain=\\\"" + document.domain + "\\\";</s" + "cript></head><body></body>\")";
      var printI = document.createElement('iframe');
      printI.name = "printIframe";
      printI.id = strFrameName;
      printI.className = "MSIE";
      document.body.appendChild(printI);
      printI.src = iframeSrc;

    } else {
      var $frame = $("<iframe id='" + strFrameName + "' />");
      $frame.appendTo("body");
    }

    var $iframe = $("#" + strFrameName);

    $iframe.ready(function () {
      $iframe.contents().find("body").append($(".studyReport").clone());
      $iframe.contents().find("body .studyReport").css({ "width": "100%", "height": "auto" });
      $iframe.contents().find("body .slideTableBodyDiv").css({ "width": "100%", "height": "auto" });

      $iframe.css({
        position: "absolute",
        width: $(".studyReport").css("width"),
        height: $iframe.contents().find("body .studyReport").css("height"),
        left: "-" + $(".studyReport").css("width"),
        top: "-" + $iframe.contents().find("body .studyReport").css("height"),
        visibility: "hidden"
      });

      var data = $iframe.contents().find("body .studyReport");
      var HTML_Width = $(data).width();
      var HTML_Height = $(data).height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas($(data)[0], { allowTaint: true }).then(function (canvas) {
        canvas.getContext('2d');

        // console.log(canvas.height + "  " + canvas.width);

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


        for (var i = 1; i <= totalPDFPages; i++) {
          // pdf.addPage(PDF_Width, PDF_Height);
          pdf.addPage(i.toString(), 'p');
          pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }

        pdf.save("Test.pdf");

        // $(".studyReport").css("height", studyReportDivHeight).find(".slideTableBodyDiv").css("height", slideTableBodyDivHeight);
      });
    });
  }

  downloadReportCsv() {
    let reportArr = [];
    $.each(this.reportData, (i, reportInfo) => {
      reportArr.push({
        "Slide Id": reportInfo.slideLabel,
        "Subject Id": reportInfo.animalNumber,
        "Image Name": reportInfo.fileName,
        "Cassette Id": reportInfo.cassetteCode,
        "Stain": reportInfo.stain,
        "Status": reportInfo.slidemapped ? "Mapped" : "Unmapped"
      });
    });
    this.fileReaderService.writeFile("csv", reportArr, "Report");
  }
  downloadReport1() {
    const doc = new jsPDF();
    const rows = [
      ["John Doe", 30, "USA"],
      ["Jane Smith", 25, "Canada"],
      ["Bob Johnson", 40, "UK"],
    ];
    // doc.autoTable(columns, rows, options);
    // doc.autoTable({ html: '#my-table' })
    let tableData = [];
    this.reportData.map((slideInfo: any) => {
      tableData.push(
        [slideInfo.slideLabel, slideInfo.animalNumber, slideInfo.fileName, slideInfo.cassetteCode, slideInfo.stain, slideInfo.slidemapped]
      )
    })
    autoTable(doc, {
      margin: { top: 5, right: 8, bottom: 5, left: 8 },
      theme: 'plain',
      head: [['Slide Id', 'Subject Id', 'Image Name', 'Cassette Id', 'Stain', 'Status']],
      body: tableData,
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   ['2001', '101','ADRENAL_104_2015-12-0111_52_48.scn', '11' ,'H&E', 'Unmapped'],
      //   // ...
      // ],

      headStyles: {
        // lineWidth:0.2,
        halign: 'center',
        lineColor: '#FFFFFF',
        fillColor: '#10458B',
        textColor: '#FFFFFF'
      },
      bodyStyles: {
        lineColor: '#FFFFFF',
        // lineWidth:0.2,

      },
      columnStyles: {
        0: {
          halign: 'center',
          // lineWidth:0.2
        },
        1: {
          halign: 'center',
          // lineWidth:0.2
        },
        3: {
          halign: 'center',
          // lineWidth:0.2

        },
        2: {
          // lineWidth:0.2
        },
        4: {
          // lineWidth:0.2,
          halign: 'center',
        },
        5: {
          // lineWidth:0.2,
          halign: 'center',
        }

      }

    });
    doc.save('Report.pdf');
  }
  downloadReportPdf() {
    var doc = new jspdf('p', 'pt', 'a4');
    // let verticalOffset = 50;
    let verticalOffset = 50;
    let isNewPage = false;
    let pageCount = 1;

    // doc.setFontSize(13);
    doc.setFontSize(13)
    doc.setFillColor(16, 69, 139);
    //  doc.setFillColor(16, 69, 169);
    // doc.rect(10, 10, 575, 35, 'F');
    doc.rect(10, 10, 575, 30, 'F');

    doc.setTextColor(255, 255, 225);
    doc.text('Slide Id', 15, 30);
    doc.text('Subject Id', 85, 30);
    doc.text('Image Name', 155, 30);
    doc.text('Cassette Id', 325, 30);
    doc.text('Stain', 445, 30);
    doc.text('Status', 515, 30);
    $.each(this.reportData, (i, slideInfo) => {
      let maxSlideLine = 1;
      let maxSlideLineHeight = 10;
      doc.setFontSize(9);
      doc.setTextColor(0, 0, 0);
      // [slideInfo.slideLabel,slideInfo.animalNumber, slideInfo.fileName, slideInfo.cassetteCode,slideInfo.stain,slideInfo.slidemapped]
      let slideLabelTextArr = doc.splitTextToSize("" + slideInfo.slideLabel, 65);
      if (maxSlideLine < slideLabelTextArr.length) {
        maxSlideLine = slideLabelTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(slideLabelTextArr)["h"];
      }

      doc.text(slideLabelTextArr, 15, verticalOffset);

      let animalNumberTextArr = doc.splitTextToSize("" + slideInfo.animalNumber, 70);
      if (maxSlideLine < animalNumberTextArr.length) {
        maxSlideLine = animalNumberTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(animalNumberTextArr)["h"];
      }
      doc.text(animalNumberTextArr, 85, verticalOffset);

      let fileNameTextArr = doc.splitTextToSize("" + slideInfo.fileName, 160);
      if (maxSlideLine < fileNameTextArr.length) {
        maxSlideLine = fileNameTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(fileNameTextArr)["h"];
      }
      doc.text(fileNameTextArr, 155, verticalOffset);

      let cassetteCodeTextArr = doc.splitTextToSize("" + slideInfo.cassetteCode, 100);
      if (maxSlideLine < cassetteCodeTextArr.length) {
        maxSlideLine = cassetteCodeTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(cassetteCodeTextArr)["h"];
      }
      doc.text(cassetteCodeTextArr, 325, verticalOffset);

      let stainTextArr = doc.splitTextToSize("" + slideInfo.stain, 70);
      if (maxSlideLine < stainTextArr.length) {
        maxSlideLine = stainTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(stainTextArr)["h"];
      }
      doc.text(stainTextArr, 445, verticalOffset);

      let mappedText = slideInfo.slidemapped ? "Mapped" : "Unmapped";
      doc.text(mappedText, 515, verticalOffset);
      verticalOffset += (maxSlideLineHeight + 15);

      if (verticalOffset > 820) {
        doc.addPage();

        doc.setFontSize(13);
        doc.setFillColor(16, 69, 139);
        doc.rect(10, 10, 575, 30, 'F');
        doc.setTextColor(255, 255, 225);
        doc.text('Slide Id', 15, 30);
        doc.text('Subject Id', 85, 30);
        doc.text('Image Name', 155, 30);
        doc.text('Cassette Id', 325, 30);
        doc.text('Stain', 445, 30);
        doc.text('Status', 515, 30);
        verticalOffset = 50;
        isNewPage = true;
        pageCount += 1;
      }
    });
    // Output as Data URI
    // let outPut = doc.output('datauristring');
    // window.open(outPut, "_blank");
    doc.save("Report.pdf");
    // window.open("Report.pdf");


  }

  downloadReportPdf1() {
    var doc = new jspdf('p', 'pt', 'a4');
    let verticalOffset = 50;
    let isNewPage = false;
    let pageCount = 1;
    doc.setFontSize(13)
    doc.setFillColor(16, 69, 139);
    //   cell One
    doc.rect(10, 10, 85, 30, 'F');
    doc.setTextColor(255, 255, 225);
    doc.text('Slide Id', 15, 30,);

    // cell Two
    doc.rect(95, 95, 85, 30, 'F');
    doc.setTextColor(255, 255, 225);
    doc.text('Subject Id', 15, 30,);
    // doc.rect(10, 10, 85, 30, 'F');
    // doc.setTextColor(255, 255, 225);
    // doc.text('Subject Id', 85, 30);
    // doc.setFillColor(16, 69, 139);
    // doc.rect(10, 10, 215, 30, 'F');
    // doc.setTextColor(255, 255, 225);
    // doc.text('Image Name', 155, 30);
    // doc.setFillColor(16, 69, 139);
    // doc.rect(10, 10, 85, 30, 'F');
    // doc.text('Cassette Id', 325, 30);
    // doc.rect(10, 10, 85, 30, 'F');
    // doc.setFillColor(16, 69, 139);
    // doc.text('Stain', 445, 30);
    // doc.rect(10, 10, 85, 30, 'F');
    // doc.setFillColor(16, 69, 139);
    // doc.text('Status', 515, 30);
    $.each(this.reportData, (i, slideInfo) => {
      let maxSlideLine = 1;
      let maxSlideLineHeight = 10;
      doc.setFontSize(9);
      doc.setTextColor(0, 0, 0);

      let slideLabelTextArr = doc.splitTextToSize("" + slideInfo.slideLabel, 65);
      if (maxSlideLine < slideLabelTextArr.length) {
        maxSlideLine = slideLabelTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(slideLabelTextArr)["h"];
      }
      doc.text(slideLabelTextArr, 15, verticalOffset);

      let animalNumberTextArr = doc.splitTextToSize("" + slideInfo.animalNumber, 70);
      if (maxSlideLine < animalNumberTextArr.length) {
        maxSlideLine = animalNumberTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(animalNumberTextArr)["h"];
      }
      doc.text(animalNumberTextArr, 85, verticalOffset);

      let fileNameTextArr = doc.splitTextToSize("" + slideInfo.fileName, 160);
      if (maxSlideLine < fileNameTextArr.length) {
        maxSlideLine = fileNameTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(fileNameTextArr)["h"];
      }
      doc.text(fileNameTextArr, 155, verticalOffset);

      let cassetteCodeTextArr = doc.splitTextToSize("" + slideInfo.cassetteCode, 100);
      if (maxSlideLine < cassetteCodeTextArr.length) {
        maxSlideLine = cassetteCodeTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(cassetteCodeTextArr)["h"];
      }
      doc.text(cassetteCodeTextArr, 325, verticalOffset);

      let stainTextArr = doc.splitTextToSize("" + slideInfo.stain, 70);
      if (maxSlideLine < stainTextArr.length) {
        maxSlideLine = stainTextArr.length;
        maxSlideLineHeight = doc.getTextDimensions(stainTextArr)["h"];
      }
      doc.text(stainTextArr, 445, verticalOffset);

      let mappedText = slideInfo.slidemapped ? "Mapped" : "Unmapped";
      doc.text(mappedText, 515, verticalOffset);
      verticalOffset += (maxSlideLineHeight + 15);

      if (verticalOffset > 820) {
        doc.addPage();

        doc.setFontSize(13);
        doc.setFillColor(16, 69, 139);
        doc.rect(10, 10, 575, 30, 'F');
        doc.setTextColor(255, 255, 225);
        doc.text('Slide Id', 15, 30);
        doc.text('Subject Id', 85, 30);
        doc.text('Image Name', 155, 30);
        doc.text('Cassette Id', 325, 30);
        doc.text('Stain', 445, 30);
        doc.text('Status', 515, 30);
        verticalOffset = 50;
        isNewPage = true;
        pageCount += 1;
      }
    });
    // Output as Data URI
    // let outPut = doc.output('datauristring');
    // window.open(outPut, "_blank");
    doc.save("Report.pdf");
    // window.open("Report.pdf");


  }
  downloadMappingReport_Old() {
    this.isDownloadBtnClicked = true;
    $("#downloadReportDiv").append($(".studyReport").clone().css("height", "auto").find(".slideTableBodyDiv").css("height", "auto").closest(".studyReport"));
    let data = $("#downloadReportDiv");

    var HTML_Width = $(data).width();
    var HTML_Height = $(data).height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas($(data)[0], { allowTaint: true }).then(((canvas) => {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(i.toString(), 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }

      pdf.save("Report.pdf");

      $("#downloadReportDiv").html('');
      this.isDownloadBtnClicked = false;
    }).bind(this), (() => {
      this.isDownloadBtnClicked = false;
    }).bind(this));
    return;

    var titleFontSize = this.commonService.vwTOpx('1vw');
    $(".studyReport").clone().css("height", "auto").find(".slideTableBodyDiv").css("height", "auto").end().printThis({
      debug: true,               // show the iframe for debugging
      importCSS: true,            // import parent page css
      importStyle: true,         // import style tags
      printContainer: true,       // print outer container/$.selector
      loadCSS: [],                // path to additional css file - use an array [] for multiple
      pageTitle: "",              // add title to print page
      removeInline: false,        // remove inline styles from print elements
      removeInlineSelector: "*",  // custom selectors to filter inline styles. removeInline must be true
      printDelay: 3000,            // variable print delay
      header: null,               // prefix to html
      footer: null,               // postfix to html
      base: false,                // preserve the BASE tag or accept a string for the URL
      formValues: true,           // preserve input/form values
      canvas: true,              // copy canvas content
      doctypeString: '...',       // enter a different doctype for older markup
      removeScripts: false,       // remove script tags from print content
      copyTagClasses: true,      // copy classes from the html & body tag
      beforePrintEvent: null,     // function for printEvent in iframe
      beforePrint: function () {
        // var x = 0;
        $("iframe[name='printIframe']").css('visibility', 'hidden');
      },          // function called before iframe is filled
      afterPrint: function () {
        // var y=0;
        $("iframe[name='printIframe']").remove();
      }
    });
  }

}
