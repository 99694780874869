import { CommonService } from './../common.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {

  constructor(public router:Router, public commonService:CommonService) { }
  activeRole:any;
  ngOnInit() {
    this.activeRole = this.commonService.ProjectLSData.userActiveRole;
  }

  navigateToCreateStudy(){
    this.commonService.changePlace("study/"+this.activeRole+"/create");
    this.router.navigate(["/dashboard/study/"+this.activeRole+"/create"]);
  }

}