<div class="clearfix studyMappingStructureMainDiv">
  <mat-card class="clearfix studyMappingStructureHeader">
    <form [formGroup]="studyMappingForm">
      <div class="float-left" style="width: 50%;">
        <div class="firstContentDiv">
          <div class="float-left" style="width: 49%;">
            <mat-form-field class="commonMatFormField" appearance="outline">
              <mat-label class="mappinlabel">Template Type<span class="mandatory">*</span></mat-label>
              <mat-select formControlName="templateType" #TemplateType ngDefaultControl>
                <mat-option *ngFor="let tempType of templateTypeArr" [value]="tempType.value">
                  {{tempType.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="float-left" style="width: 49%; margin-left: 2%;">
            <mat-form-field class="commonMatFormField" appearance="outline">
              <mat-label class="mappinlabel">File</mat-label>
              <!-- <ngx-mat-file-input id="customFile" formControlName="selectedFile" (focusin)="clearStudyMappingExcel($event)" (change)="uploadStudyMappingExcel($event)"
            [disabled]="selectedTemplateId" accept=".xlsx, .xls, .csv" #studyMappingStructureFileInput>
          </ngx-mat-file-input> -->
              <ngx-mat-file-input formControlName="selectedFile" [disabled]="selectedTemplateId" ngDefaultControl
                accept=".xlsx, .xls, .csv" #studyMappingStructureFileInput>
                <mat-icon class="attachIcon" ngxMatFileInputIcon>attach_file</mat-icon>
              </ngx-mat-file-input>
              <!-- <mat-icon matSuffix>attach_file</mat-icon> -->
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="commonMatFormField" appearance="outline" style="margin-top: 0.2vw;">
          <mat-label class="mappinlabel labelbg">Name<span class="mandatory">*</span></mat-label>
          <input matInput formControlName="fileName" [readonly]="selectedTemplateId" maxlength="250" #structureName ngDefaultControl>
        </mat-form-field>
      </div>
      <div class="float-right" style="width: 50%; padding-left: 1vw;">
        <mat-form-field class="commonMatFormField" appearance="outline">
          <mat-label class="mappinlabel labelbg">Description</mat-label>
          <textarea matInput rows="5" formControlName="fileDesc" maxlength="250" style="height: 4vw;" ngDefaultControl></textarea>
        </mat-form-field>
      </div>
    </form>
  </mat-card>

  <div class="studyMappingStructureDiv clearfix">
    <div class="studyFieldDiv" [class.selectedStudyTemplate]="selectedTemplateId">
      <mat-card>
        <div class="clearfix">
          <div class="clearfix studyFieldListTitle"
            [ngClass]="{'noLimsColumn': (selectedTemplateId && (uploadedLIMS_ColumnsArr.length == 0))}">
            <div class="cardTitle"><label>Study Field</label></div>
            <div class="float-right cardSearch">
              <input type="text" class="studymappingSearch search" placeholder="Search"
                (keyup)="onStudySearchValueChanged('.studyRow', $event)">

            </div>
          </div>
          <div class="clearfix studyFieldListTitle"
            *ngIf="(!selectedTemplateId) || (selectedTemplateId && (uploadedLIMS_ColumnsArr.length > 0))">
            <div class="cardTitle"><label>LIMS Columns</label></div>
            <div class="float-right cardSearch">
              <input type="text" class="studymappingSearch search" placeholder="Search"
                (keyup)="onStudySearchValueChanged('.LIMSColumnList', $event)">

            </div>
          </div>
          <div class="clearfix linkedLIMSColumnListTitle"
            [ngClass]="{'noLimsColumn': (selectedTemplateId && (uploadedLIMS_ColumnsArr.length == 0))}">
            <div class="cardTitle"><label>Output</label></div>
            <div class="float-right cardSearch">
              <input type="text" class="studymappingSearch search" placeholder="Search"
                (keyup)="onStudySearchValueChanged('.linkedFieldRow', $event, ' .linkedFieldText')">

            </div>
          </div>
        </div>
        <mat-card-content>
          <div class="studyFieldList float-left parentElem"
            [ngClass]="{'noLimsColumn': (selectedTemplateId && (uploadedLIMS_ColumnsArr.length == 0))}">
            <ng-container *ngFor="let studyField of studyFieldArr; let i = index;">
              <div *ngIf="!studyField.clientColumn" class="studyRow"
                [attr.data-fieldname]="studyField.fieldName.toLowerCase()">
                <div class="card" (click)="studyCardClicked($event, studyField, i)">
                  <div class="card-body">
                    <div class="matIconDiv" *ngIf="studyField.clientColumn">
                      <mat-icon>link</mat-icon>
                    </div>
                    <span class="studyFieldText">{{studyField.fieldName}}</span><span *ngIf="studyField.mandatory"
                      class="mandatory">*</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="noDataDisplay noSearchData" style="display: none;"><span>No search data for Study Field to
                display</span></div>
          </div>
          <div class="studyFieldList float-left parentElem"
            *ngIf="(!selectedTemplateId) || (selectedTemplateId && (uploadedLIMS_ColumnsArr.length > 0))">
            <ng-container *ngFor="let indvLIMSColumn of LIMSColumnsArr; let i = index;">
              <div *ngIf="!indvLIMSColumn.linkedTo" class="LIMSColumnList"
                [attr.data-fieldname]="indvLIMSColumn.fieldName.toLowerCase()">
                <div class="card" [matTooltip]="tooltipText" (mouseover)="updateToolTipText($event, indvLIMSColumn)"
                  [matTooltipDisabled]="hideTooltip" matTooltipClass="blackTooltip"
                  (click)="LIMSColumnCardClicked($event, indvLIMSColumn, i)">
                  <div class="card-body clearfix">
                    <div class="matIconDiv" *ngIf="indvLIMSColumn.linkedTo">
                      <mat-icon>settings_ethernet</mat-icon>
                    </div>
                    <span class="LIMSColumnText">{{indvLIMSColumn.fieldName}}</span>
                  </div>
                </div>
                <!-- <div class="removeLinking" (click)="LIMSColumnRemoveLinking($event, indvLIMSColumn, i)">
                  <mat-icon *ngIf="indvLIMSColumn.linkedTo">clear</mat-icon>
                </div> -->
              </div>
            </ng-container>
            <div class="noDataDisplay" *ngIf="LIMSColumnsArr.length == 0"><span>No LIMS Columns to display</span></div>
            <div class="noDataDisplay noSearchData" style="display: none;"><span>No search data for LIMS Columns to
                display</span></div>
          </div>
          <div class="float-left linkedLIMSColumnList parentElem"
            [ngClass]="{'noLimsColumn': (selectedTemplateId && (uploadedLIMS_ColumnsArr.length == 0))}">
            <ng-container *ngFor="let studyField of studyFieldArr; let i = index;">
              <div class="linkedFieldRow" *ngIf="studyField.clientColumn">
                <div [attr.data-studyField]="studyField.code">
                  <div class="card" [class.linkedLIMSColumn]="studyField.clientColumn">
                    <div class="card-body">
                      <i *ngIf="studyField.clientColumn" class="material-icons linkedIcon"
                        (click)="unlinkFieldsColumn(studyField, i)">link_off</i>
                      <span class="linkedToFieldName linkedFieldText" *ngIf="studyField.clientColumn"
                        [attr.data-fieldname]="studyField.fieldName?.toLowerCase()">{{studyField.fieldName}}</span>
                      <span class="linkedToText" *ngIf="studyField.clientColumn">Linked to</span>
                      <span class="linkedToClientColumn linkedFieldText" title="{{studyField.clientColumn}}"
                        [attr.data-fieldname]="studyField.clientColumn?.toLowerCase()">{{studyField.clientColumn}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="noDataDisplay" *ngIf="isNoLinkedDataMsgDisplay"><span>No Linked Fields to display</span></div>
            <div class="noDataDisplay noSearchData" style="display: none;"><span>No search data for Linked Field to
                display</span></div>
          </div>
          <!-- <div class="float-left">
            <div class="LIMSColumnList studyRow" [attr.data-fieldname]="indvLIMSColumn.fieldName.toLowerCase()"
              *ngFor="let indvLIMSColumn of LIMSColumnsArr; let i = index;">
              <div class="card" [matTooltip]="tooltipText" (mouseover)="updateToolTipText($event, indvLIMSColumn)"
                [matTooltipDisabled]="hideTooltip" matTooltipClass="blackTooltip"
                (click)="LIMEColumnCardClicked($event, indvLIMSColumn, i)">
                <div class="card-body">
                  <div class="matIconDiv">
                    <mat-icon *ngIf="indvLIMSColumn.linkedTo">settings_ethernet</mat-icon>
                  </div><span class="LIMSColumnText">{{indvLIMSColumn.fieldName}}</span>
                </div>
              </div>
            </div>
            <div class="noDataDisplay" *ngIf="LIMSColumnsArr.length == 0"><span>No data to display</span></div>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
    <!-- <div class="LIMSColumnDiv float-right dn" *ngIf="!selectedTemplateId">
      <mat-card>
        <div class="clearfix">
          <div class="cardTitle float-left"><label>LIMS Column</label></div>
          <div class="float-right">
            <input type="text" class="studymappingSearch search" placeholder="Search"
              (keyup)="onStudySearchValueChanged('.LIMSColumnList', $event)">

          </div>
        </div>
        <mat-card-content>
          <div class="LIMSColumnList" [attr.data-fieldname]="indvLIMSColumn.fieldName.toLowerCase()"
            *ngFor="let indvLIMSColumn of LIMSColumnsArr; let i = index;">
            <div class="card" [matTooltip]="tooltipText" (mouseover)="updateToolTipText($event, indvLIMSColumn)"
              [matTooltipDisabled]="hideTooltip" matTooltipClass="blackTooltip"
              (click)="LIMEColumnCardClicked($event, indvLIMSColumn, i)">
              <div class="card-body">
                <div class="matIconDiv">
                  <mat-icon *ngIf="indvLIMSColumn.linkedTo">settings_ethernet</mat-icon>
                </div><span class="LIMSColumnText">{{indvLIMSColumn.fieldName}}</span>
              </div>
            </div>
          </div>
          <div class="noDataDisplay" *ngIf="LIMSColumnsArr.length == 0"><span>No data to display</span></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div> -->

    <div class="studyMappingBtn clearfix">
      <button type="button" class="btn-default btn-light float-left" (click)="StudyMappingStructureConfirm('Delete')"
        *ngIf="selectedTemplateId">Delete</button>
      <button type="button" *ngIf="showResetBtn" class="btn-default btn-light float-left"
        (click)="StudyMappingStructureConfirm('Reset')">Reset</button>
      <button type="button"
        *ngIf="!isSaveStudyMappingStructureClicked && ((!selectedTemplateId) || (selectedTemplateId && (uploadedLIMS_ColumnsArr.length > 0)))"
        class="btn-default searchbtn float-right" (click)="saveStudyMappingStructure()">Save</button>
      <button *ngIf="isSaveStudyMappingStructureClicked" class="btn-default searchbtn float-right" type="button"
        disabled>
        <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
          role="status" aria-hidden="true"></span>
        <span>Saving...</span>
      </button>
      <button type="button" class="btn-default float-right" (click)="cancelStudyMappingStructure()">Cancel</button>
    </div>
  </div>
</div>