<header>
    <div class="appheader">
        <div class="leftHeader">
            <img [src]="'../../' + appConfig.config.appBaseName + 'assets/images/am_logo_2.svg'" alt="AIRA Matrix"
                aria-label="AIRA Matrix" class="headerLogo" />
        </div>
        <div class="rightHeader">
            <!-- <div class="nohoverli browserZoomed" *ngIf="isBroswerZoomed || systemZoomed">
                <mat-icon [title]="zoomWarningMsg">warning</mat-icon>
            </div> -->
            <mat-form-field class="selectrole">
                <mat-select [(ngModel)]="userRole" (selectionChange)="changeRole()">
                    <mat-option *ngFor="let userRole of roles" [value]="userRole.role">
                        {{userRole.roleDisplayText}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="circle " [ngStyle]="{'background-color': circleColor}">
                <!--[matMenuTriggerFor]="menu"-->
                <!-- <div class="circle float-right " [ngStyle]="{'background-color': circleColor}"> -->
                <div class="initials">{{initials}}</div>
            </div>
            <div class="headerAvatarText usernm_header"> Hello {{firstName | titlecase}} {{lastName | titlecase}} </div>
            <button mat-icon-button [matMenuTriggerFor]="beforeMenu">
                <img [src]="'../../' + appConfig.config.appBaseName + 'assets/images/viewer_icon/Logout_Icon-Primary.svg'"
                    alt="Logout" aria-label="Logout" class="logouticon " />
                <mat-menu #beforeMenu="matMenu" xPosition="before" class="logoutOptionPanel">
                    <button mat-menu-item (click)="showChangePasswordDialog()">Change Password</button>
                    <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </button>
            <!-- <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="showChangePasswordDialog()" class="changePasswordItem">
                    <mat-icon class="headerIcon">lock_open</mat-icon>
                    <span>Change Password</span>
                </button>
                <button mat-menu-item  (click)="logout()">
                    <mat-icon class="headerIcon">power_settings_new</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu> -->
        </div>


    </div>
</header>