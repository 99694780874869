<div class="dialog-header modal-header">
  <div class="headerTitle">
    <div class="dialog-title" *ngIf="data && data.userType=='Create'">Create User</div>
    <div class="dialog-title" *ngIf="data && data.userType=='Edit'">Edit User</div>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="userDatadiv">
  <form [formGroup]="userFrom">
    <div class="row">
      <div class="col-md-4">
        <label>First Name<span class="mandatory">*</span></label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width gselect userCreateFormControl">
          <input matInput formControlName="firstName" maxlength="25" (input)="isFormValueChanged= true" />
          <mat-error>{{f.firstName.errors | inputError:'firstName' | translate}}</mat-error>
        </mat-form-field>
        <!-- <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required">First Name is required</div>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label>Last Name<span class="mandatory"></span></label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width gselect userCreateFormControl">
          <input matInput formControlName="lastName" maxlength="25" (input)="isFormValueChanged= true" />
          <!-- <mat-error>{{f.lastName.errors | inputError:'lastName' | translate}}</mat-error> -->
        </mat-form-field>
        <!-- <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required">Last Name is required</div>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label>Email ID<span class="mandatory">*</span></label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width gselect userCreateFormControl">
          <input matInput formControlName="email" maxlength="50" (input)="isFormValueChanged= true" />
          <mat-error>{{f.email.errors | inputError:'email' | translate}}</mat-error>
        </mat-form-field>
        <!-- <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
          <div *ngIf="f.emailId.errors.required">Email is required</div>
          <div *ngIf="f.emailId.errors.email">Email must be a valid email address</div>
        </div> -->
      </div>
    </div>
    <ng-container *ngIf="config?.newUserPasswordRequired">
      <div class="row" *ngIf="data && data.userType=='Create'" style="margin-top: 10px;margin-bottom:1vh;">
        <div class="col-md-4">
          <label>Password<span class="mandatory">*</span></label>
        </div>
        <div class="col-md-8">
          <mat-form-field class="example-full-width gselect userCreateFormControl">
            <input [type]="hide ? 'password' : 'text'" matInput formControlName="password" maxlength="25" minlength="8"
              (keydown.space)="$event.preventDefault();" (paste)="$event.preventDefault();" />
            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>{{f.password.errors | inputError:'newPwd' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="data && data.userType=='Create'" style="margin-top: 10px;margin-bottom:1vh;">
        <div class="col-md-4">
          <label>Confirm Password<span class="mandatory">*</span></label>
        </div>
        <div class="col-md-8">
          <mat-form-field class="example-full-width gselect userCreateFormControl">
            <input [type]="hide1 ? 'password' : 'text'" matInput formControlName="confirmPassword" maxlength="25"
              minlength="8" (keydown.space)="$event.preventDefault();" (paste)="$event.preventDefault();" />
            <mat-icon matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide1">
              {{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>{{f.confirmPassword.errors | inputError:'confirmPwd' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="row" style="margin-top: 10px;">
      <div class="col-md-4">
        <label>Roles<span class="mandatory">*</span></label>
      </div>
      <!-- <div class="col-md-8">
        <div formArrayName="roles" *ngFor="let role of rolesArr; let i = index">
          <mat-checkbox type="checkbox" [disabled]="role.disabled" [checked]="role.disabled" [value]="role.roleid" (change)="onCheckChange($event)">{{role.role}}</mat-checkbox>
        </div>
      </div> -->
      <div class="col-md-8">
        <div formArrayName="roles" *ngFor="let role of rolesArr; let i = index">
          <mat-checkbox type="checkbox" [formControlName]="i" (change)="isFormValueChanged=true" color="primary">
            {{rolesDisplayed[i]}}</mat-checkbox>
        </div>
        <!-- <mat-error *ngIf="formSubmitted">{{f.roles.errors | inputError:'roles' | translate}}</mat-error> -->
      </div>
    </div>
    <!-- <div class="row" *ngIf="data && data.userType=='Create'" style="margin-top: 10px;margin-bottom:1vh;">
      <div class="col-md-4">
        <label>Create Password<span class="mandatory">*</span></label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width gselect userCreateFormControl">
          <input [type]="hide ? 'password' : 'text'" matInput formControlName="password" maxlength="25" minlength="8" (keydown.space)="$event.preventDefault();"
          (paste)="$event.preventDefault();"/>
          <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>{{f.password.errors | inputError:'newPwd' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div> -->
    <!-- <div class="row" *ngIf="data && data.userType!='Create'">
      <div class="col-md-4">
        <label>Change Password<span class="mandatory">*</span></label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width">
          <input type="password" matInput formControlName="password" />
          <mat-error >{{f.password.errors | inputError:'password' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div> -->
    <!-- <div class="" *ngIf="data && data.userType!='Create'">
      <mat-checkbox formControlName="inactiveUser" (change)="isFormValueChanged=true">Deactivate
        User</mat-checkbox>
    </div> -->
  </form>
  <div class="actions dialog-actions modal-footer">
    <button type="button" class="btn-default" (click)="closeDialog()">Cancel</button>
    <button *ngIf="savingStatus" class="btn-default searchbtn" type="button" disabled>
      <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
      <span>Saving</span>
    </button>
    <button *ngIf="!savingStatus" type="button" class="btn-default searchbtn" (click)="saveUserData()"
      [ngClass]="{ 'disablebtn_func' : ((data.userType=='Create' && userFrom.invalid && !userFrom['mustMatch'] ) || (data.userType=='Edit'&& (!isFormValueChanged || userFrom.invalid)))}">Save</button>
  </div>
</div>