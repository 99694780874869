import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '../login/login.service';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,
    private loginService: LoginService, private commonService: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.getAccessToken()) {
      this.router.navigate(['/dashboard']);
    }
    return !this.authService.getAccessToken();
  }
}
