import { GalleryDialogComponent } from './../gallery-dialog/gallery-dialog.component';
import { AppConfigService } from './../../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../../common.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
declare const screenfull;

@Component({
  selector: 'app-gallerypreview',
  templateUrl: './gallerypreview.component.html',
  styleUrls: ['./gallerypreview.component.css']
})
export class GallerypreviewComponent implements OnInit, OnDestroy {
  galleryList: any = '';
  selectedSlideForPreview: any = [];
  selectedSlideList = [];
  selectedGroupIndex: any = 0;
  noOfGroups: any = 0;
  selectedSlideIndex: any = 0;
  noOfSlideForSelectedGroup: any = 0;
  NextBtnStatus: boolean = false;
  prevBtnStatus: boolean = false;
  selectgroupSlideKey = String;
  messageDuration = 2000;
  // selectedSlides: any = [];
  slideLabelView: boolean = false;
  slideReviewData: any = [{ "userFk": null }];
  previewOrgan: any = '';
  thumbnailUrl: any;
  labelPreview: boolean;

  mapForm: FormGroup;
  studyListArr: any;
  cassetteArr: any;
  animalArr: any;
  stainArr: any;

  @Input() activeMode: String;
  @Input() dialogData: any;

  config: any;
  galleryFilter: any;
  studyObservable: any;
  groupSlideKeyObservable: any;
  unmapFlag: boolean = false;
  unmappingStatusP: boolean = false;
  discardingStatusP: boolean = false;
  mappingStatusP: boolean = false;
  intensityObj = {};
  frequencyObj = {};

  defaultMacroImagePath: any;
  defaultLabelImagePath



  constructor(private formbuilder: FormBuilder, private galleryService: GalleryService,
    private router: Router, private commonService: CommonService, public translate: TranslateService,
    private dialog: MatDialog, private configService: AppConfigService) {
    this.config = configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.defaultMacroImagePath = "../../../" + this.config?.appBaseName + "assets/images/viewer_icon/gallery_noimg.png";
    this.defaultLabelImagePath = "../../../" + this.config?.appBaseName + "assets/images/viewer_icon/gallery_noimg.png";
  }

  ngOnInit() {
    this.labelPreview = this.config.anonymisedLabel;
    this.groupSlideKeyObservable = this.galleryService.selectgroupSlideKey.subscribe((value) => {
      this.slideLabelView = false;
      if (!$.isEmptyObject(this.galleryList)) {
        let slide_grp = value.split('_');
        let groupIn = parseInt(slide_grp[0]);
        let slideIn = parseInt(slide_grp[1]);
        this.selectedGroupIndex = groupIn;
        this.noOfSlideForSelectedGroup = this.galleryList[Object.keys(this.galleryList)[groupIn]].slidesArr.length;
        this.selectedSlideList.push(this.galleryList[Object.keys(this.galleryList)[groupIn]].slidesArr[slideIn]);
        this.galleryService.getSelectedSlideDataForPreview(this.galleryList[Object.keys(this.galleryList)[groupIn]].slidesArr[slideIn].slidePk).subscribe((res: any) => {
          this.selectedSlideForPreview = res.data;
          if (this.activeMode == 'gallery' || this.activeMode == 'image-viewer') {
            this.previewOrgan = this.selectedSlideForPreview.organ[0];
            this.getSlideReview();
          }
          this.selectedSlideIndex = slideIn;
          if (this.noOfSlideForSelectedGroup == (this.selectedSlideIndex + 1)) {
            this.NextBtnStatus = true;
          } else {
            this.NextBtnStatus = false;
          }
          if (this.selectedSlideIndex == 0) {
            this.prevBtnStatus = true;
          } else {
            this.prevBtnStatus = false;
          }
        })
      }
    });
    this.thumbnailUrl = `${this.config.thumbnailUrl}`;

    this.galleryService.loadeDataArrLength.subscribe(val => {
      if (val == (this.selectedSlideIndex + 1)) {
        this.NextBtnStatus = true;
      } else {
        this.NextBtnStatus = false;
      }
    })

    this.galleryService.galleryList.subscribe(value => {
      if (!$.isEmptyObject(value) && this.activeMode != 'scanned') {
        this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
        this.galleryList = value;
        this.noOfGroups = Object.keys(this.galleryList).length;
        this.selectedGroupIndex = 0;
        if (this.galleryList[Object.keys(this.galleryList)[0]].slidesArr) {
          this.noOfSlideForSelectedGroup = this.galleryList[Object.keys(this.galleryList)[0]].slidesArr.length;
          this.selectedSlideForPreview = this.galleryList[Object.keys(this.galleryList)[0]].slidesArr[0];
        }
        this.selectedSlideIndex = 0;
        if (this.noOfSlideForSelectedGroup == (this.selectedSlideIndex + 1)) {
          this.NextBtnStatus = true;
        }
        if (this.selectedSlideIndex == 0) {
          this.prevBtnStatus = true;
        }
        // this.galleryService.changePreviewSlide('0_0');
      } else {
        this.galleryList = {};
        // console.log("no data error handling in galleery list");
      }
    });
    /* this.galleryService.selecteSlidesList.subscribe((slides) => {
      this.selectedSlides = slides;
    }); */

    this.mapForm = this.formbuilder.group({
      studyGuId: ['', Validators.required],
      cassetteId: ['', Validators.required],
      animalId: ['', Validators.required],
      stainId: ['', Validators.required],
      comment: ['']
    });

    this.studyObservable = this.galleryService.studyListST.subscribe((response: any) => {
      this.studyListArr = response;
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
      if (this.galleryFilter.studyName && !this.mapForm.value.studyGuId) {
        this.mapForm.controls['studyGuId'].setValue(this.galleryFilter.studyName);
        if (this.activeMode == 'uploaded') {
          this.studySelectionChanged({ value: this.galleryFilter.studyName });
        }
        //this.studyObservable = null;
      }
    });

    if (this.activeMode == 'mapped') {
      this.galleryService.unMapFlag.subscribe((response: any) => {
        this.unmapFlag = response;
      });
    }
  }
  get mapF() {
    return this.mapForm.controls;
  }

  studySelectionChanged(e) {
    this.galleryService.getCassetteData(e.value).subscribe((res: any) => {
      this.cassetteArr = res.data;
      this.mapForm.controls['cassetteId'].setValue('');
      this.mapForm.controls['stainId'].setValue('');
      this.mapForm.controls['animalId'].setValue('');
    });
  }
  cassetteSelectionChanged(e) {
    let cassettesData = this.cassetteArr.filter(element => {
      return element.id == e.value;
    });
    this.stainArr = cassettesData[0].casseteStains;
    this.mapForm.controls['stainId'].setValue('');
    this.mapForm.controls['animalId'].setValue('');
  }

  stainSelectionChanged(e) {
    this.galleryService.getUnmappedAnimalData(this.mapForm.controls.studyGuId.value, e.value).subscribe((res: any) => {
      this.animalArr = res.data;
      this.mapForm.controls['animalId'].setValue('');
    });
  }

  closePreview() {
    this.galleryService.changePreview(false);
  }

  next_slide() {
    this.prevBtnStatus = false;
    if ((this.noOfSlideForSelectedGroup - 1) > this.selectedSlideIndex) {
      this.selectedSlideIndex = this.selectedSlideIndex + 1;
      this.selectedSlideForPreview = this.galleryList[Object.keys(this.galleryList)[this.selectedGroupIndex]].slidesArr[this.selectedSlideIndex];
    }
    if (this.noOfSlideForSelectedGroup == (this.selectedSlideIndex + 1)) {
      this.NextBtnStatus = true;
    }
    this.galleryService.changePreviewSlide(this.selectedGroupIndex + '_' + this.selectedSlideIndex);
  }

  prev_slide() {
    this.NextBtnStatus = false;
    if (this.selectedSlideIndex > 0) {
      this.selectedSlideIndex = this.selectedSlideIndex - 1;
      this.selectedSlideForPreview = this.galleryList[Object.keys(this.galleryList)[this.selectedGroupIndex]].slidesArr[this.selectedSlideIndex];
    }
    if (this.selectedSlideIndex == 0) {
      this.prevBtnStatus = true;
    }
    this.galleryService.changePreviewSlide(this.selectedGroupIndex + '_' + this.selectedSlideIndex);
  }

  openViewer(slideId: any) {
    this.galleryService.getSlideDetailsArr([slideId]).subscribe((successData: any) => {
      let slideIds: any = [];
      for (let x = 0; x < successData.data.length; x++) {
        successData.data[x].id = successData?.data[x].slide.slidePk;
        successData.data[x].identifier = successData?.data[x].slide.identifier;
        successData.data[x].slidename = successData?.data[x]?.slide.slidename;
        slideIds.push(successData.data[x].id);

        this.galleryService.changeOpenStatus(successData.data[x].id, { "opened": true }).subscribe((res: any) => {
        });
      }
      this.commonService.setViewerOpenedFrom(this.activeMode);
      this.commonService.ProjectLSData['ViewerSlides'] = successData.data;

      // this.galleryFilter.selectedAccordion = selectedAccordionText;
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
      // this.router.navigate(['WebViewer/' + slideIds.join()]);
      this.router.navigate(['WebViewer']);

      // this.galleryService.setSelecteSlidesList([]);
    });
  }

  openViewer1(slideId: any) {
    if (this.activeMode == 'image-viewer') {
      this.dialog.closeAll();
    } else {
      this.commonService.setViewerOpenedFrom(this.activeMode);
    }
    /* var slides = this.galleryList[Object.keys(this.galleryList)[this.selectedGroupIndex]].slidesArr.map((value) => {
      return {
        'slidePk': value.slidePk,
        'identifier': value.identifier,
      }
    });
    this.commonService.setSelectedGroupSlides(JSON.stringify(slides)); */

    // this.router.navigate(['WebViewer/' + slideId]);
    this.router.navigate(['WebViewer']);
    // this.galleryService.setSelecteSlidesList([]);

    var tempThis = this;
    setTimeout(() => {
      if ((tempThis.activeMode == 'image-viewer') && (screenfull.isFullscreen)) {
        $("app-header, .sidebar").hide();
        $("app-image-viewer").closest(".contenttab").css("width", "100%");
        setTimeout(() => {
          /* let headerHeight = $("app-header").is(":visible") ? $("app-header").height() : 0;
          $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
          let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
          $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
        }, 75);
      }
    }, 75);
    delete this.commonService.ProjectLSData.slideIdDsData;
    this.commonService.setProjectLSData();
  }

  mapImage() {
    this.mappingStatusP = true;
    setTimeout(this.setTitleDivWidth.bind(this), 10);
    //this.galleryService.selecteSlidesList.subscribe((slides) => {
    let formValue = this.mapForm.value;
    let mapArr = [{
      "studySlideMappingModel": {
        "studyFk": formValue.studyGuId,
        "cstCodeFk": formValue.cassetteId,
        "slideFk": this.selectedSlideForPreview.slidePk,
        "animalFk": formValue.animalId,
        "cstStainFk": formValue.stainId
      },
      "slideComment": formValue.comment
    }];
    this.galleryService.mapImageToStudy(mapArr).subscribe((res: any) => {
      this.galleryService.getFilterWiseListing();
      this.mappingStatusP = false;
      this.commonService.showToastMsg(res.message);
      this.mapForm.reset();
      this.closePreview();
    });
  }

  openSplitView() {
    this.dialog.closeAll();
    // console.log(' selected slide for preview ' + JSON.stringify(this.selectedSlideForPreview));
    // console.log(' selected slide list for preview ' + JSON.stringify(this.selectedSlideList));
    let temp_str = '';
    let temp_arr = [];
    let stored_samples = this.commonService.ProjectLSData.samples;
    // temp_arr.push(this.dialogData.selectedImage);
    for (var i in stored_samples) {
      temp_arr.push(stored_samples[i]);
    }
    for (var i in this.selectedSlideList) {
      temp_arr.push(this.selectedSlideList[i].slidePk);
    }
    let uniqueArray = temp_arr.filter(function (item, pos) {
      return temp_arr.indexOf(item) == pos;
    });
    temp_arr = uniqueArray;
    for (var i in temp_arr) {
      temp_str = temp_str + '' + temp_arr[i] + ',';
    }
    temp_str = temp_str.replace(/,\s*$/, ""); //removes last comma and any space after it
    // this.commonService.changePlace('image-viewer/' + temp_str);
    // this.router.navigate(['/dashboard/image-viewer/' + temp_str]);
    this.commonService.ProjectLSData.samples = temp_arr;
    // this.galleryService.setSelecteSlidesList([]);
    delete this.commonService.ProjectLSData.slideIdDsData;
    this.commonService.setProjectLSData();
  }

  openDiscardDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "discardImage"
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      this.discardingStatusP = true;
      setTimeout(this.setTitleDivWidth.bind(this), 10);
      let discardObj = {
        "slides": [this.selectedSlideForPreview.slidePk],
        "comment": result
      }
      this.galleryService.discardImage(discardObj).subscribe((res: any) => {
        this.galleryService.getFilterWiseListing();
        this.discardingStatusP = false;
        this.commonService.showToastMsg(res.message);
        this.closePreview();
      });
    });
  }

  openUnMapDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "unMapImage",
      count: 1
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      //console.log(this.selectedSlideForPreview);
      this.unmappingStatusP = true;
      setTimeout(this.setTitleDivWidth.bind(this), 10);
      /*let unMapArr = [
        {
          "studySlideMappingModel": {
            "studyFk": null,
            "cstCodeFk": null,
            "slideFk": this.selectedSlideForPreview.slidePk,
            "animalFk": null,
            "cstStainFk": null
          },
          "slideComment": result
        }
      ];
       this.galleryService.unMapImage(unMapArr).subscribe((res: any) => {
        this.unmappingStatusP = false;
        this.commonService.showToastMsg(res.message);
        this.galleryService.getFilterWiseListing();
        this.closePreview();
      }); */
      let unMapObj = {
        "studySlideMappingModel": [{
          "studyFk": null,
          "cstCodeFk": null,
          "slideFk": this.selectedSlideForPreview.slidePk,
          "animalFk": null,
          "cstStainFk": null
        }],
        "slideComment": result
      };
      this.galleryService.bulkUnmapImages(unMapObj).subscribe((res: any) => {
        this.unmappingStatusP = false;
        this.commonService.showToastMsg(res.message);
        this.galleryService.getFilterWiseListing();
        this.closePreview();
      });
    });
  }

  openReMapDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "reMapImage"
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      let unMapArr = [
        {
          "studySlideMappingModel": {
            "studyFk": null,
            "cstCodeFk": null,
            "slideFk": this.selectedSlideForPreview.slidePk,
            "animalFk": null,
            "cstStainFk": null
          },
          "slideComment": result.comment
        }
      ];
      this.galleryService.unMapImage(unMapArr).subscribe((res: any) => {
        let mapArr = [{
          "studySlideMappingModel": {
            "studyFk": result.studyGuId,
            "cstCodeFk": result.cassetteId,
            "slideFk": this.selectedSlideForPreview.slidePk,
            "animalFk": result.animalId,
            "cstStainFk": result.stainId
          },
          "slideComment": result.comment
        }];
        this.galleryService.mapImageToStudy(mapArr).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.galleryService.getFilterWiseListing();
          this.closePreview();
        });
      });
    });
  }

  getSlideReview() {
    this.galleryService.getSlideReview(this.previewOrgan, this.selectedSlideForPreview.slidePk, this.commonService.ProjectLSData.userActiveRole).subscribe((res: any) => {
      this.slideReviewData = res.data;
    });
    if (this.galleryFilter.isTCRStudy) {
      this.galleryService.getAllIntensities().subscribe((response: any) => {
        response.data.forEach(element => {
          this.intensityObj[element.intensity] = element.description;
        });
      });

      this.galleryService.getAllFrequencies().subscribe((response: any) => {
        response.data.forEach(element => {
          this.frequencyObj[element.frequency] = element.description;
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.studyObservable) {
      this.studyObservable.unsubscribe();
    }
    if (this.groupSlideKeyObservable) {
      this.groupSlideKeyObservable.unsubscribe();
    }
  }

  openImageDialog() {
    // this.consultationService.setSelectImageInViewer(this.selectedSlideForPreview);
  }

  alreadyMarkedImage() {
    let markedObj = this.galleryService.getSelectedAddImage();
    if (markedObj[this.selectedSlideForPreview.slidePk]) {
      return true;
    } else {
      return false;
    }
  }

  markToAddImage() {
    let markedObj = this.galleryService.getSelectedAddImage();
    if (!markedObj[this.selectedSlideForPreview.slidePk]) {
      markedObj[this.selectedSlideForPreview.slidePk] = this.selectedSlideForPreview;
    }
    this.galleryService.setSelectedAddImage(markedObj);
  }

  setTitleDivWidth() {
    $(".prevTitleDiv").width($(".previewaction_btn").width() - ($(".previewaction_btn .leftBtn").width() + $(".previewaction_btn .rightBtn").width() + this.commonService.vwTOpx('2.2vw') + (2 * $(".previewimgdet .prev").width())));
  }
}

