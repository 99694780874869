import { CommonService } from '../../common.service';
import { ConfigurationService } from './../../configuration/configuration.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { StudyService } from '../study.service';

@Component({
  selector: 'app-location-mapping-dialog',
  templateUrl: './location-mapping-dialog.component.html',
  styleUrls: ['./location-mapping-dialog.component.css']
})
export class LocationMappingDialogComponent implements OnInit {
    onClose = new EventEmitter();
    folderLocationArr = [];
    folderLocationArr1 = [];
    mapForm: FormGroup;
    showLoader: boolean = true;

  constructor(private formbuilder: FormBuilder, @Optional() private dialogRef: MatDialogRef<LocationMappingDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private studyService: StudyService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.mapForm = this.formbuilder.group({
      folderLocation: new FormArray([], this.minSelectedCheckboxes(1))
    });
    this.studyService.getStudyMappedLocation(this.data.studyData.studyGuid).subscribe((res: any) => {
      this.data.studyData.imageFolderModel = res.data;
      this.configurationService.getAllFolderLocationNonEmpty().subscribe((res: any) => {
        this.showLoader = false;
          if(res.success) {
              this.folderLocationArr = this.commonService.sortByMultiKey(res.data, ['name']);
              this.folderLocationArr.forEach((o, i) => {
                const control = new FormControl();
                (this.mapForm.controls.folderLocation as FormArray).push(control);
              });
              this.folderLocationArr1 = [...this.folderLocationArr];
              if (this.data.studyData.imageFolderModel.length > 0) {
                this.data.studyData.imageFolderModel.forEach(element => {
                  var index = this.folderLocationArr.map(function (e) {
                    return e.id;
                  }).indexOf(element.id)
                  this.mapForm.get('folderLocation')['controls'][index].setValue(true);
                  this.mapForm.get('folderLocation')['controls'][index].disable();
                });
              }
              setTimeout(()=>{
                $('#filterSearch').on('keyup click', (e) => {
                  let searchVal = e.currentTarget["value"];

                  if (searchVal) {
                    $(".folderLocation_border").closest(".locationMainDiv").hide();
                    $(".folderLocation_border[data-locationname*='" + searchVal.toLowerCase() + "']").closest(".locationMainDiv").show();
                  } else {
                    $(".folderLocation_border").closest(".locationMainDiv").show();
                  }

                  // if (searchVal) {
                  //   this.folderLocationArr = this.folderLocationArr1.filter(elem => {
                  //     return elem.name.toLowerCase().includes(searchVal.toString().toLowerCase())
                  //   });
                  // } else {
                  //   this.folderLocationArr = [...this.folderLocationArr1];
                  // }
                });
              },1000);
          }
      });
    });
  }

  get mapF() {
    return this.mapForm.controls;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
 
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
 
    return validator;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  mapLocationFolder() {
    const selectedPreferences = this.mapForm.getRawValue().folderLocation
    .map((checked, index) => checked ? this.folderLocationArr[index] : null)
    .filter(value => value !== null);
    this.mapForm.value.folderLocation = selectedPreferences;
    let folderList = this.mapForm.value.folderLocation.map(function(value) {
      return value.id;
    });
    let data = {
      "studyGuid": this.data.studyData.studyGuid,
      "folderIdList" : folderList
   }
      this.onClose.emit(data);
      this.dialogRef.close();
  }

}
